import { useQuery } from "@tanstack/react-query";
import { LexZapService } from "@/services/lexZap";

export const lexZapDlqEventsQueryKey = ["lexZapDlqEvents"];

export const useLexZapDlqEvents = () => {
  return useQuery({
    queryKey: lexZapDlqEventsQueryKey,
    queryFn: LexZapService.getDlqEvents,
  });
};
