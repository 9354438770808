import React, { useEffect, useState } from "react";
import { PageLayout } from "@/components/PageLayout";
import { Box, Typography } from "@mui/material";
import { DataGrid, GridFilterModel, GridRowSelectionModel } from "@mui/x-data-grid";
import { NumberCard } from "./components/NumberCard";
import { ptBR } from "@mui/x-data-grid/locales";
import {
  CustomColumnHeaders,
  CustomColumnHeadersProps,
  CustomColumnMenu,
  CustomLoadingOverlay,
  CustomNoRowsOverlay,
  CustomToolbar,
} from "./components/DataGridComponents";
import { useAuthContext } from "@/contexts/AuthContext";
import { ImportOabSchemaData } from "./components/ImportOABModal";
import { useLegalProceedings } from "@/hooks/legalproceedings/useLegalProceedings";
import { legalProceedingColumns } from "./components/DataGridComponents/columns";
import { logger } from "@/core/logger";
import { WebToast } from "@//components/core/Toast";
import { LegalProceedingService } from "@/services/legalProceeding";
import { useNotificationsContext } from "@/contexts/NotificationsContext";

export const LegalProceedingPage = () => {
  return (
    <PageLayout
      sx={{
        ".page-layout-container": {
          backgroundColor: "common.white",
          px: 4,
        },
        ".app-bar-header-page-layout": {
          backgroundColor: "common.white",
        },
      }}
      contentSx={{
        width: "100%",
      }}
    >
      <LegalProceeding />
    </PageLayout>
  );
};

export const LegalProceeding = () => {
  const { user } = useAuthContext();
  const [selectedRows, setSelectedRows] = React.useState<GridRowSelectionModel>([]);
  const [isLoading, setLoading] = React.useState(false);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const [filters, setFilters] = useState<Record<string, string>>({});
  const { notifications } = useNotificationsContext();

  const {
    data: paginatedRecords,
    isLoading: isLoadingLegalProceedings,
    isFetching,
    refetch,
  } = useLegalProceedings({
    pagination: { ...paginationModel, page: paginationModel.page + 1 },
  });

  const noRows = !isFetching && (!paginatedRecords?.data || paginatedRecords?.data.length === 0);

  const onImportLegalProceedingFromOAB = async (params: ImportOabSchemaData) => {
    setLoading(true);
    try {
      logger.info("Atualizando oab do usuário", params);
      await LegalProceedingService.importLegalProceedingFromOAB({
        userId: user!.userId,
        oabs: params.oabs,
      });
      void refetch();
    } catch (error) {
      logger.error("Erro ao atualizar oab do usuário", error);
      WebToast.error("Ocorreu um erro ao atualizar sua OAB.");
    }
  };

  const onEnableTracking = async () => {
    await LegalProceedingService.transformLegalProceedingsToCase({ legalProceedingCode: selectedRows as string[] });
    void refetch();
  };

  useEffect(() => {
    if (!isFetching) {
      if (paginatedRecords?.status === "IMPORTING") return setLoading(true);
      setLoading(false);
    }
  }, [paginatedRecords, isFetching]);

  useEffect(() => {
    if (notifications[notifications.length - 1]?.type === "GET_LEGAL_PROCEEDINGS") {
      void refetch();
    }
  }, [notifications]);

  // TODO: Adicionar chamada com filtro pro backend
  useEffect(() => {}, [filters]);

  const onFilterChange = (model: GridFilterModel) => {
    const query: Record<string, string> = {};

    model.items.forEach((item) => {
      query[item.field] = item.value;
    });

    setFilters(query);
  };

  const onSearch = (text: string) => {
    setFilters({
      search: text,
    });

    // TODO: Adicionar chamada com filtro pro backend
  };

  return (
    <>
      <Typography variant="h5" pb={6}>
        Monitoramento de casos
      </Typography>
      <Box
        sx={{
          display: "flex",
          gap: 2,
          width: "100%",
        }}
      >
        <NumberCard title="Encontrados" actualValue={paginatedRecords?.totalResults ?? 0} />
        <NumberCard
          title="Monitoramento ativo"
          actualValue={paginatedRecords?.data?.filter((r) => r.status === "ACTIVE").length ?? 0}
          rangeValue={paginatedRecords?.totalResults ?? 0}
        />
      </Box>
      <Box sx={{ width: "100%", mt: 4, mb: 3, fontWeight: 400 }}>
        <DataGrid
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          filterMode="server"
          onFilterModelChange={onFilterChange}
          pageSizeOptions={[10]}
          rows={paginatedRecords?.status == "COMPLETED" ? paginatedRecords.data : []}
          rowCount={paginatedRecords?.totalResults || 0}
          onRowSelectionModelChange={(newSelection) => {
            setSelectedRows(newSelection);
          }}
          rowSelectionModel={selectedRows}
          checkboxSelection
          getRowId={(row) => row.code}
          columns={legalProceedingColumns}
          loading={isLoading || isLoadingLegalProceedings || isFetching}
          rowHeight={noRows || isFetching ? 150 : 50}
          disableRowSelectionOnClick
          hideFooter={noRows}
          disableColumnMenu={noRows || isFetching}
          localeText={{ ...ptBR.components.MuiDataGrid.defaultProps.localeText, toolbarFilters: "Filtrar" }}
          slots={{
            columnMenu: CustomColumnMenu,
            noRowsOverlay: (props) => (
              <CustomNoRowsOverlay {...props} onImportLegalProceedingFromOAB={onImportLegalProceedingFromOAB} />
            ),
            toolbar: () => <CustomToolbar disabled={noRows || isFetching} onSearch={onSearch} />,
            columnHeaders: React.forwardRef<HTMLDivElement, CustomColumnHeadersProps>(
              function CustomComponent(props, ref) {
                return (
                  <CustomColumnHeaders
                    ref={ref}
                    {...props}
                    hidden={!isLoading && noRows}
                    trackingToggle={{
                      show: selectedRows.length > 0,
                      enabled: selectedRows.every(
                        (row) => paginatedRecords?.data.find((r) => r.code === row)?.status === "ACTIVE"
                      ),
                    }}
                    onEnableTracking={onEnableTracking}
                  />
                );
              }
            ),
            loadingOverlay: () => <CustomLoadingOverlay />,
          }}
          autoHeight
        />
      </Box>
    </>
  );
};
