import { Settings as LuxonSettings } from "luxon";
import * as React from "react";
import { createRoot } from "react-dom/client";

import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import { datadogRum } from "@datadog/browser-rum";
import * as logger from "./core/logger";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "@fontsource-variable/lexend";

import { env } from "@/constants/environment";
import { IS_OLD_WEBVIEW } from "@/constants/isOldWebview";
import { defaultFlagValues } from "@/hooks/useFeatureFlags/defaultFlagValues";
import * as apm from "./core/apm";
import { RouterProvider } from "react-router-dom";
import { router } from "@/routes/router";

logger.init();
apm.init();

LuxonSettings.defaultZone = "America/Sao_Paulo";
LuxonSettings.defaultLocale = "pt-BR";

const render = async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: env.LAUNCH_DARKLY_CLIENT_SIDE_ID,
    flags: defaultFlagValues,
    options: {
      inspectors: [
        {
          type: "flag-used",
          name: "dd-inspector",
          method: (key, detail) => {
            datadogRum.addFeatureFlagEvaluation(key, detail.value);
          },
        },
      ],
    },
  });

  const containerElement = document.getElementById("container");

  if (!containerElement) {
    throw new Error("Container element not found");
  }

  const rootContainer = createRoot(containerElement);

  rootContainer.render(
    <LDProvider>
      <RouterProvider router={router} />
    </LDProvider>
  );
};

/* Render application after Office initializes */
Office.onReady(() => {
  if (IS_OLD_WEBVIEW) return;

  render();
});
