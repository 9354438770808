import { buildSkillStep } from "@/core/skillForms/builder";

export const settlementOfferSkillStep2 = buildSkillStep({
  title: "Envio de peça inaugural",
  description:
    "Para finalizar, envie a peça inaugural enviada pela parte contrária para que possamos analisar as informações completas do caso.",
  fields: [
    {
      name: "reference_piece",
      type: "file",
      ui: {
        label: "Envie a peça inaugural do caso",
      },
    },
  ],
});
