import { useMutation, useQueryClient } from "@tanstack/react-query";
import { LexZapService } from "@/services/lexZap";
import { lexZapDlqEventsQueryKey } from "../useLexZapDlqEvents";

export const useDeleteLexZapDlqEvent = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: LexZapService.deleteDlqEvent,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: lexZapDlqEventsQueryKey });
    },
  });
};
