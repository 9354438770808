import React from "react";

import { MessageProps } from "../../types";
import { MessageCard } from "../MessageCard";
import { alpha, Box, Typography } from "@mui/material";

import { formatChatMessage } from "format-chat-message";
import parseStringToHtml from "html-react-parser";

export const VideoMessage = ({ message }: MessageProps) => {
  const isStatusAboveImage = !message.body;

  return (
    <MessageCard
      message={message}
      sx={{
        padding: 0.5,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            position: "relative",
            borderRadius: "12px",
            overflow: "hidden",
            width: "100%",
            maxWidth: "400px",
            maxHeight: "380px",
          }}
        >
          <video
            style={{
              width: "100%",
              height: "auto",
              maxHeight: "380px",
              objectFit: "cover",
            }}
            controls
          >
            <source src={message.media?.url} type={message.media?.mimeType} />
            Your browser does not support HTML video.
          </video>

          {isStatusAboveImage && (
            <Box
              sx={{
                position: "absolute",
                bottom: 0,
                right: 0,
                left: 0,
                height: "32px",
                background: ({ palette }) =>
                  `linear-gradient(to top, ${alpha(palette.common.black, 0.5)}, transparent)`,
              }}
            />
          )}
        </Box>
        {message.body && (
          <Box
            sx={{
              display: "flex",
              paddingX: "8px",
              paddingBottom: "4px",
              width: "100%",
            }}
          >
            <Typography
              variant="body"
              sx={{
                whiteSpace: "break-spaces",
              }}
            >
              {parseStringToHtml(
                formatChatMessage({
                  message: message.body,
                })
              )}

              <Box
                sx={{
                  display: "inline-block",
                  width: message.fromLawyer ? "50px" : "32px",
                }}
              />
            </Typography>
          </Box>
        )}
      </Box>
    </MessageCard>
  );
};
