import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { ThreadContext } from "./context";
import {
  ConnectionState,
  OnThreadConnectParams,
  OnThreadErrorParams,
  SkillsPayload,
  ThreadErrorType,
  UserInputDTO,
  useSocket,
  WebsocketMessageType,
} from "@/contexts/WebSocketContext";
import { useNavigate } from "react-router-dom";
import { useAlertModal } from "@/hooks/useAlertModal";
import { useDebouncedCallback } from "@/hooks/useDebouncedCallback";
import { useGenerateThreadName } from "@/hooks/threads/useGenerateThreadName";
import { FlowMessageAction } from "@/contexts/MessagesContext";
import { logger } from "@/core/logger";
import { useCreateThread } from "@/hooks/threads/useCreateThread";
import { useThread } from "@/hooks/threads/useThread";

const DEFAULT_THREAD_NAME = "Nova Sessão";

interface ThreadContextProviderProps {
  children: ReactNode;
}

export const ThreadProvider = ({ children }: ThreadContextProviderProps) => {
  const navigate = useNavigate();
  const { openAlertModal } = useAlertModal();

  const { mutateAsync: generateThreadNameAsync, isPending: isGeneratingThreadName } = useGenerateThreadName();

  const { mutateAsync: createThreadAsync } = useCreateThread();

  const [currentThreadId, setCurrentThreadId] = useState<string>();
  const { data: currentThread } = useThread({ threadId: currentThreadId });
  const [threadConnectionState, setThreadConnectionState] = useState<ConnectionState>(ConnectionState.CLOSED);

  const handleThreadConnected = useCallback(({ threadId }: OnThreadConnectParams) => {
    setCurrentThreadId(threadId);
    setThreadConnectionState(ConnectionState.OPEN);
  }, []);

  const handleThreadError = useCallback(
    ({ threadId, error }: OnThreadErrorParams) => {
      switch (error) {
        case ThreadErrorType.THREAD_NOT_FOUND:
          openAlertModal({
            title: "Sessão de chat não encontrada",
            description: "Não conseguimos localizar a sessão de chat desejada. Gostaria de abrir uma nova?",
            primaryButton: {
              label: "Sim",
              onClick: () => createThread(),
            },
          });
          break;
        case ThreadErrorType.THREAD_ALREADY_CONNECTED:
          openAlertModal({
            title: "Sessão de chat já ativa",
            description:
              "Você já está com esta sessão de chat aberta em outra janela. Deseja continuar aqui e fechar a anterior?",
            primaryButton: {
              label: "Continuar",
              onClick: () => {
                openThread({
                  threadId,
                  closeOthers: true,
                });
              },
            },
            secondaryButton: {
              label: "Cancelar",
              onClick: () => {
                navigate("/");
              },
            },
          });
          break;
        case ThreadErrorType.THREAD_CONNECTED_IN_OTHER_INSTANCE:
          close();
          openAlertModal({
            title: "Sessão de chat desconectada",
            description: "Você foi desconectado desta sessão de chat porque entrou na mesma em outra janela.",
            primaryButton: {
              label: "Ok",
              onClick: () => navigate("/"),
            },
          });
          break;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentThreadId, openAlertModal, navigate]
  );

  const { send: sendSocketMessage, connectionState: socketConnectionState } = useSocket({
    onThreadConnected: handleThreadConnected,
    onThreadError: handleThreadError,
  });

  const openThread = useDebouncedCallback(({ threadId, closeOthers }: { threadId: string; closeOthers?: boolean }) => {
    closeCurrentThread();
    setThreadConnectionState(ConnectionState.CONNECTING);
    sendSocketMessage({
      type: WebsocketMessageType.THREAD_CONNECT,
      data: { threadId, closeOthers },
    });
  }, 1000);

  const createThread = useDebouncedCallback(async ({ threadName }: { threadName?: string } = {}) => {
    const thread = await createThreadAsync({ threadName });
    openThread({ threadId: thread.id });
  }, 1000);

  const closeCurrentThread = useCallback(() => {
    if (currentThreadId) {
      setCurrentThreadId(undefined);
      setThreadConnectionState(ConnectionState.CLOSED);
      sendSocketMessage({
        type: WebsocketMessageType.THREAD_DISCONNECT,
      });
    }
  }, [sendSocketMessage, currentThreadId]);

  const generateThreadName = useCallback(
    async ({ data }: { data: FlowMessageAction | SkillsPayload<UserInputDTO> }) => {
      if (!currentThreadId) return;
      if (currentThread?.name === DEFAULT_THREAD_NAME) return;
      try {
        await generateThreadNameAsync({ threadId: currentThreadId, data });
      } catch (e) {
        logger.error("Error generating thread name", e);
      }
    },
    [generateThreadNameAsync, currentThreadId, currentThread?.name]
  );

  // Reconnect to thread when socket closes
  useEffect(() => {
    if (socketConnectionState === ConnectionState.CONNECTING && currentThreadId) {
      setThreadConnectionState(ConnectionState.CONNECTING);
      sendSocketMessage({
        type: WebsocketMessageType.THREAD_CONNECT,
        data: { threadId: currentThreadId, closeOthers: true },
      });
    }
  }, [socketConnectionState, currentThreadId, sendSocketMessage]);

  return (
    <ThreadContext.Provider
      value={{
        currentThreadId,
        currentThread,
        threadConnectionState,
        openThread,
        createThread,
        closeCurrentThread,
        generateThreadName,
        isGeneratingThreadName,
      }}
    >
      {children}
    </ThreadContext.Provider>
  );
};
