export const defaultFlagValues = {
  /** Habilita e passa o link do vídeo de introdução */
  tutorialVideoUrl: "",
  /** Habilita o alerta de documento read only */
  warnReadOnlyDocument: false,
  /** Url do WhatsApp para iniciar uma conversa com CS */
  customerServiceWhatsApp: "",
  /** Habilita a funcionalidade de buscar jurisprudência */
  jurisprudenceSearch: false,
  /** Habilita o Indique e Ganhe  */
  membergetmemberEnabled: false,
  /** Habilita novo card da ação sugerida com valores (créditos) */
  newActionCardWithCreditAmountEnabled: false,
  /** Mostra o plano basic pre selecionado na tela de pagamento*/
  preSelectedBasicPlan: false,
  /** Habilita a edição de documentos com formatação (HTML) no editor */
  formattedEditionEnabled: false,
  /** Habilita o chat tawk para todos os usuários */
  tawkForAllUsers: false,
  /** Habilita o reenvio de convite*/
  resendReferralEnabled: false,
  /** Habilita nova tela do Assistente WhatsApp */
  whatsAppAssistantEnabled: false,
  /** Habilita o plano essencial */
  unlimitedPlanEnabled: false,
  /** Habilita a exibição do fluxo de onboarding para novos usuários */
  showOnboarding: false,
  /** Habilita a página de sucesso após criação de peça */
  successPageAfterCreationOfPiece: false,
  /** Habilita a ocultação de campos opcionais de skills */
  hideOptionalSkillFields: false,
  /** Habilita inside sales */
  insideSalesEnabled: false,
  /** Habilita apenas o plano essencial para compra */
  onlyUnlimitedPlanEnabled: true,
  /** Habilita o trial para o plano ilimitado */
  trialForUnlimitedPlanEnabled: false,
  /** Habilita os novos planos profissional e performance na tela de planos*/
  newPlansEnabled: false,
  /** Habilita os novos planos profissional e performance no checkout */
  newPlansInCheckoutEnabled: false,
  /** Habilita a funcionalidade de testes de novas skills */
  skillBetaTester: false,
  /** Links do inside sales */
  insideSalesLinks: {
    speakWithSpecialistNow: "https://meet.google.com/omb-mrzr-qtd",
    schedule: "https://calendly.com/carlos-vieira-lexter/30min",
    whatsapp: "https://wa.me/5582987123045",
  },
  /** Habilita a funcionalidade de busca de skills no skill group */
  skillGroupSearchEnabled: false,
  /** Cupom do plano essencial */
  essentialPlanCoupon: "30lexter",
  /** Habilita o numero de telefone no cadastro com google */
  phoneInSignUpWithGoogleEnabled: false,
  /** Habilita a funcionalidade de proximas etapas no caso */
  caseNextStepsEnabled: false,
  /** Petição Inicial (Sem arquivos de provas) */
  skillInitialPetitionV1Enabled: true,
  /** Petição Inicial (Com arquivos de provas) */
  skillInitialPetitionV2Enabled: false,
  /** Habilita a opção carf na busca de jurisprudência */
  searchPrecedentCarfEnabled: false,
  /** Habilita a página de processos legais */
  legalProceedingsPageEnabled: false,
};
