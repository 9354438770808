import React from "react";
import { InternalPageHeader } from "@//components/core/InternalPageStructure";
import { outsideOfficeClient } from "@/utils/outsideOfficeClient";
import { useNavigate } from "react-router-dom";
import { EXTERNAL_URLS, ROUTE_PATHS } from "@/routes/routePaths";
import { NewPlan, usePaymentContext } from "@/contexts/PaymentContext";
import { Box, Link, Typography, useTheme } from "@mui/material";
import { BackgroundPage } from "@/components/BackgroundPage";
import { PageLayout } from "@/components/PageLayout";
import { ArrowOutwardOutlined } from "@mui/icons-material";
import { NewPlanCard } from "./NewPlanCard";
import { useNewPlans } from "@/hooks/useNewPlans";
import { useFeatureFlags } from "@/hooks/useFeatureFlags";
import { useCredits } from "@/hooks/credits/useCredits";

export const PlansPageWithUnlimited = () => {
  if (outsideOfficeClient()) {
    return (
      <PageLayout
        contentSx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          height: "100%",
        }}
        sx={{
          ".page-layout-container": {
            pb: 0,
            pt: 3,
          },
        }}
      >
        <PlansComponent />
      </PageLayout>
    );
  }

  return (
    <BackgroundPage bgColor="common.black">
      <PlansComponent />
    </BackgroundPage>
  );
};

const PlansComponent = () => {
  const navigate = useNavigate();
  const { onPlanChange } = usePaymentContext();
  const plans = useNewPlans();
  const theme = useTheme();
  const { onlyUnlimitedPlanEnabled } = useFeatureFlags();
  const { data: credits } = useCredits();
  const actualUserPlan = credits?.companyPlan?.plan;

  const handleBuy = (plan: NewPlan) => {
    if (plan.buttonRedirectTo) {
      return navigate(plan.buttonRedirectTo);
    }

    onPlanChange(plan);
    navigate(`${ROUTE_PATHS.INTERNAL_PAYMENT}/?PLAN=${plan.id}`);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 3,
      }}
    >
      <InternalPageHeader hideOutsideOffice />
      <Typography variant="h5" color="common.black" align="center">
        Planos e Preços
      </Typography>
      <Typography variant="body3" color="common.black" align="center" maxWidth={580}>
        Escolha um de nossos planos disponíveis e concentre-se no que realmente é importante com a ajuda do Assistente
        Lexter.
      </Typography>
      {!onlyUnlimitedPlanEnabled && (
        <Typography variant="body3" color="common.black">
          <Link
            sx={{
              display: "flex",
              gap: 1,
              mb: 1,
            }}
            href={EXTERNAL_URLS.CREDITS_INFO}
            target="_blank"
          >
            Veja como os créditos funcionam <ArrowOutwardOutlined fontSize="small" />
          </Link>
        </Typography>
      )}

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          borderRadius: 2,
          [theme.breakpoints.down("lg")]: {
            gap: 1,
            "& > div": {
              border: "1px solid",
              borderColor: "common.lightShade",
              borderRadius: 2,
            },
          },
          [theme.breakpoints.up("lg")]: {
            gap: "1px",
            bgcolor: "common.lightShade",
            borderColor: "common.lightShade",
            boxShadow: "0px 1px 8px 0px #0000001F",
            "& > div:last-child": {
              borderRadius: "0px 8px 8px 0px",
            },
            "& > div:first-child": {
              borderRadius: "8px 0px 0px 8px",
            },
            "& > div:only-child ": {
              borderRadius: "8px",
            },
          },
        }}
      >
        {plans.map((plan, index) => (
          <NewPlanCard key={index} plan={plan} actualUserPlan={actualUserPlan} handleBuy={() => handleBuy(plan)} />
        ))}
      </Box>
    </Box>
  );
};
