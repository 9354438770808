import { SkillForm } from "@/core/skillForms/types";
import { createInitialPetitionSkillStep2 } from "./step2";
import { createInitialPetitionSkillStep1 } from "./step1";

export const createInitialPetitionSkillForm: SkillForm = {
  skillId: "create_initial_petition",
  title: "Escrever petição inicial",
  steps: [createInitialPetitionSkillStep1, createInitialPetitionSkillStep2],
  lastStepSubmitButtonText: "Criar Petição Inicial",
};
