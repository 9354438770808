import { Typography } from "@mui/material";
import React from "react";

import parseStringToHtml from "html-react-parser";

import { formatChatMessage } from "format-chat-message";
import { MessageCard } from "../MessageCard";
import { MessageProps } from "../../types";

export const TextMessage = ({ message }: MessageProps) => {
  return (
    <MessageCard message={message}>
      <Typography
        variant="body"
        sx={{
          whiteSpace: "normal",
        }}
      >
        {parseStringToHtml(formatChatMessage({ message: message?.body }))}
      </Typography>
    </MessageCard>
  );
};
