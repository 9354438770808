export function isWordWeb(): boolean {
  return Office.context.platform === Office.PlatformType.OfficeOnline;
}

export function isWordDesktop(): boolean {
  try {
    return Office.context.platform === Office.PlatformType.PC || Office.context.platform === Office.PlatformType.Mac;
  } catch (e) {
    return false;
  }
}

export function isWordDesktopOnMac(): boolean {
  try {
    return Office.context.platform === Office.PlatformType.Mac;
  } catch (e) {
    return false;
  }
}

export function isWordApiVersionSupported(version: "1.1" | "1.2" | "1.3" | "1.4" | "1.5"): boolean {
  try {
    return Office.context.requirements.isSetSupported("WordApi", version);
  } catch (e) {
    return false;
  }
}
