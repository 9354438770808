import { WebToast } from "@//components/core/Toast";
import { Loading } from "@/components/Loading";
import { ModalRight } from "@/components/Modal/ModalRight";
import { Phone } from "@/core/Phone";
import { useUpdateLexZapCompany } from "@/hooks/lexZap/admin/useUpdateLexZapCompany";
import { LexZapCompany } from "@/hooks/lexZap/types";
import { Box, Button } from "@mui/material";
import React, { ChangeEvent, useEffect, useMemo } from "react";
import { InputField } from "../InputField";
import { PhoneNumberField } from "../PhoneNumberField";

export const EditCompanyModal = ({
  isOpen,
  onClose,
  company,
}: {
  isOpen: boolean;
  onClose: () => void;
  company?: LexZapCompany;
}) => {
  const [form, setForm] = React.useState<
    Partial<{
      notificationNumber?: string;
      assistantName?: string;
      companyName?: string;
      apiKey?: string;
    }>
  >({});
  const { mutateAsync: updateCompany, isPending: isLoading } = useUpdateLexZapCompany();
  const [isValidNotificationNumber, setIsValidNotificationNumber] = React.useState<boolean>(true);

  useEffect(() => {
    if (company) {
      setForm({
        assistantName: company.assistantName,
        companyName: company.companyName,
        apiKey: "",
      });
    } else {
      setForm({});
    }
  }, [company]);

  const hasChangedFields = useMemo(() => {
    return (
      !!form.notificationNumber?.replace(/\D/g, "") ||
      form.assistantName !== company?.assistantName ||
      form.companyName !== company?.companyName ||
      !!form.apiKey?.trim()
    );
  }, [form, company]);

  const handleUpdateUser = async () => {
    if (!company) {
      return onClose();
    }

    try {
      let notificationNumber: string | undefined = undefined;
      if (form.notificationNumber) {
        const notificationPhone = Phone.new(form.notificationNumber);

        if (!notificationPhone.isSuccess) {
          WebToast.error("Número de notificação inválido");
          return;
        }
        notificationNumber = notificationPhone.getValue().toPersistence();
      }

      await updateCompany({
        companyId: company.companyId,
        email: company.email,
        updates: {
          ...form,
          notificationNumber,
        },
      });
      onClose();
      WebToast.success("Alteração feita com sucesso");
    } catch {
      WebToast.error("Falha ao alterar o usuário");
    }
  };

  const handleChangeForm = <K extends keyof typeof form>(prop: K) => {
    return (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (prop === "notificationNumber") {
        setForm((prevState) => ({
          ...prevState,
          [prop]: e.target.value.replace(/\D/g, ""),
        }));
      } else {
        setForm((prevState) => ({
          ...prevState,
          [prop]: e.target.value,
        }));
      }
    };
  };

  const canUpdate = useMemo(
    () => hasChangedFields && (!form.notificationNumber || Phone.new(form.notificationNumber ?? "").isSuccess),
    [form, hasChangedFields]
  );

  if (!company) return null;

  return (
    <ModalRight
      title={"Editar Company"}
      size={"SMALL"}
      onClose={onClose}
      footer={
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "auto auto",
            gap: "8px",
          }}
        >
          <Button variant="contained" disabled={isLoading || !canUpdate} onClick={handleUpdateUser} size="small">
            Atualizar
          </Button>
          <Button size="small" disabled={isLoading} onClick={onClose}>
            Cancelar
          </Button>
        </Box>
      }
      open={isOpen}
    >
      <Loading isLoading={isLoading}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "32px",
          }}
        >
          <InputField placeholder="Email" disabled={true} value={company.companyId} label="Company Id" />
          <InputField placeholder="Email" disabled={true} value={company.email} label="Email" />

          <PhoneNumberField
            label="Número do whatsapp"
            placeholder="Número do whatsapp"
            value={
              Phone.new(company.phoneNumber).isSuccess
                ? Phone.new(company.phoneNumber).getValue().toString()
                : company.phoneNumber
            }
            disabled={true}
          />

          <PhoneNumberField
            label="Número para notificações"
            placeholder="Número para notificações"
            onChange={(e) => {
              handleChangeForm("notificationNumber")(e);
              setIsValidNotificationNumber(
                e.target.value === "" ? true : Phone.new(e.target.value.replace(/[^\d]+/g, "")).isSuccess
              );
            }}
            value={
              Phone.new(form.notificationNumber || "").isSuccess
                ? Phone.new(form.notificationNumber || "")
                    .getValue()
                    .toString()
                : form.notificationNumber
            }
            error={!isValidNotificationNumber}
          />

          <InputField
            placeholder="Nome do assistente"
            onChange={handleChangeForm("assistantName")}
            type="text"
            value={form.assistantName}
            label="Nome do assistente"
          />

          <InputField
            placeholder="Nome do escritório"
            onChange={handleChangeForm("companyName")}
            type="text"
            value={form.companyName}
            label="Nome do escritório"
          />

          <InputField placeholder="Api Key" onChange={handleChangeForm("apiKey")} value={form.apiKey} label="Api Key" />
        </Box>
      </Loading>
    </ModalRight>
  );
};
