import { useMutation, useQueryClient } from "@tanstack/react-query";
import { getApplicantsQueryKey } from "../useApplicants";
import { ApplicantService } from "@/services/applicant";
import { Applicant, ApplicantPayload } from "@/services/applicant/types";

export const useCreateApplicant = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["createApplicant"],
    mutationFn: async ({ data }: { data: ApplicantPayload }) => await ApplicantService.createApplicant({ data }),
    onSuccess: async ({ id }, { data }) => {
      await queryClient.cancelQueries({ queryKey: getApplicantsQueryKey() });
      queryClient.setQueryData(getApplicantsQueryKey(), (old: Applicant[]) => [
        ...old,
        {
          ...data,
          id,
        },
      ]);
    },
  });
};
