import { buildSkillStep } from "@/core/skillForms/builder";

export const createStrategySkillStep2 = buildSkillStep({
  title: "Detalhes do caso",
  description:
    "Insira o detalhamento do caso com o maior número possível de informações para que seja possível entender melhor o caso em que está trabalhando.",
  fields: [
    {
      name: "case_breakdown",
      type: "string",
      ui: {
        label: "Faça um detalhamento do caso:",
        placeholder:
          "Exemplo: A influenciadora digital sofreu lesões graves após um procedimento estético mal realizado. A clínica não conseguiu tratar os ferimentos. O atendimento inadequado resultou em dor intensa e impacto psicológico, afetando o seu trabalho.",
        widget: "textarea",
      },
    },
  ],
});
