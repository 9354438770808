import { SkillForm } from "@/core/skillForms/types";
import { hearingsScriptSkillStep1 } from "./step1";
import { hearingsScriptSkillStep2 } from "./step2";
import { hearingsScriptSkillStep3 } from "./step3";

export const hearingsScriptSkillForm: SkillForm = {
  skillId: "hearings_script",
  title: "Roteiro para audiências judiciais",
  steps: [hearingsScriptSkillStep1, hearingsScriptSkillStep2, hearingsScriptSkillStep3],
};
