import { ActionId, LegalPieceMacroType, LegalPieceMicroType, UploadingFile } from "@/contexts/MessagesContext";
import { CreditPerSkill, Skill } from "@/core/skills/types";
import { base64ToFile } from "@/utils/base64ToFile";
import { fileToUploadingFile } from "@/utils/fileToUploadingFile";
import { Type } from "@sinclair/typebox";
import { DateTime } from "luxon";
import { v4 as uuidV4 } from "uuid";

const inputSchema = Type.Object({
  client: Type.String(),
  area: Type.String(),
  case_breakdown: Type.String(),
  theses: Type.Optional(Type.String()),
  evidence_text: Type.Optional(Type.String()),
  requests: Type.Optional(Type.String()),
  evidence_documents: Type.Optional(Type.Array(Type.String())),
});

const actionId = ActionId.CREATE_ONE_PIECE;

export const createInitialPetitionSkillV2 = {
  id: "create_initial_petition_v2",
  name: "Escrever petição inicial",
  flag: "skillInitialPetitionV2Enabled",
  credits: CreditPerSkill.ADVANCED_SKILL,
  inputSchema,
  tags: ["litigation"],
  actionId,
  type: "message",
  description: "Elabore petições iniciais de casos.",
  getMessage: (input) => {
    const evidence_documents: UploadingFile[] = [];

    input.evidence_documents?.forEach((evidenceDocument) => {
      if (evidenceDocument.startsWith("data:")) {
        evidence_documents.push(fileToUploadingFile(base64ToFile(evidenceDocument)));
      }
    });

    return {
      id: uuidV4(),
      type: "FLOW",
      direction: "SENT",
      author: "Current User",
      date: DateTime.now(),
      status: "READ",
      text: "Criar Peça Inaugural",
      actions: [
        {
          id: actionId,
          text: "Criar Peça Inaugural",
          client: input.client,
          legal_piece_macro_type: LegalPieceMacroType.INAUGURAL_DOCUMENT,
          legal_piece_micro_type: LegalPieceMicroType.INITIAL,
          area: input.area,
          case_breakdown: {
            source: "TEXT",
            text: input.case_breakdown,
          },
          theses: input.theses,
          evidence_text: input.evidence_text,
          requests: input.requests,
          evidence_documents: evidence_documents.map((evidenceDocument) => ({
            source: "FILE",
            file: evidenceDocument,
          })),
          reference_piece: null,
        },
      ],
      files: [...evidence_documents],
    };
  },
} satisfies Skill<typeof inputSchema>;
