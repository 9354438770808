import { ActionId, MessageDTO, ResponseMessage, UploadedFile } from "@/contexts/MessagesContext";
import { FormattedText } from "@/hooks/useEditor/types";
import { EventEmitter } from "@/utils/eventEmitter";
import { WhatsappUpdate } from "./whatsapp.types";

export interface WebSocketContextData {
  connectionState: ConnectionState;
  send: ({ type, data }: WebsocketSendParams) => void;
  messageReceivedEvent: EventEmitter<OnMessageReceivedParam>;
  threadConnectedEvent: EventEmitter<OnThreadConnectParams>;
  whatsAppUpdateEvent: EventEmitter<WhatsappUpdate>;
  threadErrorEvent: EventEmitter<OnThreadErrorParams>;
  notificationReceivedEvent: EventEmitter<OnNotificationReceivedParams>;
}

export enum ConnectionState {
  CLOSED = "CLOSED",
  CONNECTING = "CONNECTING",
  OPEN = "OPEN",
}

export enum WebsocketMessageType {
  MESSAGE = "MESSAGE",
  NOTIFICATION = "NOTIFICATION",
  THREAD_CONNECT = "THREAD_CONNECT",
  THREAD_DISCONNECT = "THREAD_DISCONNECT",
  SKILLS = "SKILLS",
  WHATSAPP_UPDATE = "WHATSAPP_UPDATE",
}

export enum PrecedentScore {
  ALTA = "Alta",
  MEDIA = "Média",
  BAIXA = "Baixa",
}

export interface Precedent {
  title: string;
  court_code: string;
  cnj_unique_number: string;
  headnote: string;
  full_text?: {
    location?: string;
    presigned_url_location?: string;
  };
  judgment_date?: string | null;
  publication_date?: string | null;
  release_date?: string | null;
  signature_date?: string | null;
  score: PrecedentScore;
  score_reason: string;
  url: string;
  url_court?: string;
}

export type WebsocketSendParams =
  | { type: WebsocketMessageType.MESSAGE; data: MessagePayload }
  | { type: WebsocketMessageType.SKILLS; data: SkillsPayload<UserInputDTO> }
  | { type: WebsocketMessageType.THREAD_DISCONNECT; data?: undefined }
  | { type: WebsocketMessageType.THREAD_CONNECT; data: { threadId: string; closeOthers?: boolean } };

interface MessagePayload {
  chat: {
    messages: MessageDTO[];
    content: string;
  };
  isOnboarding?: boolean;
}

interface PrecedentSearchPayload extends BaseSkillsPayload {
  skillId: ActionId.SEARCH_PRECEDENT;
  payload: {
    case_breakdown: string;
    court_code?: string[];
    min_date?: string;
  };
}

interface PrecedentSearchResponse extends BaseSkillsResponse {
  skillId: ActionId.SEARCH_PRECEDENT;
  payload: {
    precedents: Precedent[];
  };
}

interface EditDocumentPayload extends BaseSkillsPayload {
  skillId: ActionId.EDIT_DOCUMENT;
  payload: {
    to_edit_text: string;
    user_instructions: string;
    document_text: string;
  };
}
interface EditDocumentResponse extends BaseSkillsResponse {
  skillId: ActionId.EDIT_DOCUMENT;
  payload: {
    suggested_text: string;
    suggested_text_html: string;
  };
}

interface LegalQuestionPayload extends BaseSkillsPayload {
  skillId: ActionId.LEGAL_QUESTION;
  payload: {
    area: string;
    question: string;
  };
}
interface LegalQuestionResponse extends BaseSkillsResponse {
  skillId: ActionId.LEGAL_QUESTION;
  payload: {
    answer: string;
  };
}

interface HearingsScriptPayload<Input> extends BaseSkillsPayload {
  skillId: ActionId.HEARINGS_SCRIPT;
  payload: {
    reference_piece_complaint: Input;
    reference_piece_answer: Input;
    client: string;
    witness: string;
    case_breakdown: string;
  };
}
interface HearingsScriptResponse extends BaseSkillsResponse {
  skillId: ActionId.HEARINGS_SCRIPT;
}

interface SummarizeDocumentPayload<Input> extends BaseSkillsPayload {
  skillId: ActionId.SUMMARIZE_DOCUMENT;
  payload: {
    procedural_document: Input;
  };
}
interface SummarizeDocumentResponse extends BaseSkillsResponse {
  skillId: ActionId.SUMMARIZE_DOCUMENT;
}

interface CreateStrategyPayload extends BaseSkillsPayload {
  skillId: ActionId.CREATE_STRATEGY;
  payload: {
    evidences_list?: string;
    case_breakdown: string;
    client: string;
    area: string;
  };
}
interface CreateStrategyResponse extends BaseSkillsResponse {
  skillId: ActionId.CREATE_STRATEGY;
}

interface IntercurrentMotionPayload extends BaseSkillsPayload {
  skillId: ActionId.INTERCURRENT_MOTION;
  payload: {
    case_breakdown: string;
    client: string;
  };
}
interface IntercurrentMotionResponse extends BaseSkillsResponse {
  skillId: ActionId.INTERCURRENT_MOTION;
}

interface FeeAgreementPayload extends BaseSkillsPayload {
  skillId: ActionId.FEE_AGREEMENT;
  payload: {
    case_breakdown: string;
    client: string;
  };
}

interface AdaptDocumentPayload<Input> extends BaseSkillsPayload {
  skillId: ActionId.UPDATE_DOCUMENTS;
  payload: {
    document: Input;
    case_breakdown: string;
  };
}

interface AdaptDocumentResponse extends BaseSkillsResponse {
  skillId: ActionId.UPDATE_DOCUMENTS;
}

interface FeeAgreementResponse extends BaseSkillsResponse {
  skillId: ActionId.FEE_AGREEMENT;
}

interface LegalAdvicePayload extends BaseSkillsPayload {
  skillId: ActionId.LEGAL_ADVICE;
  payload: {
    case_breakdown: string;
    client: string;
  };
}
interface LegalAdviceResponse extends BaseSkillsResponse {
  skillId: ActionId.LEGAL_ADVICE;
}

interface SettlementOfferPayload<Input> extends BaseSkillsPayload {
  skillId: ActionId.SETTLEMENT_OFFER;
  payload: {
    reference_piece: Input;
    case_breakdown?: string;
    client: string;
    area: string;
  };
}
interface SettlementOfferResponse extends BaseSkillsResponse {
  skillId: ActionId.SETTLEMENT_OFFER;
}

interface BaseSkillsPayload {
  skillId: ActionId;
  requestId: string;
  messageToSave?: string;
  isOnboarding?: boolean;
}

interface BaseSkillsResponse {
  skillId: ActionId;
  requestId: string;
  success: boolean;
  document?: SkillDocumentResponse;
  messageToUser?: string;
  insufficientCredits?: boolean;
}

interface SkillDocumentResponse {
  id: number;
  name: string;
  formattedBody: FormattedText;
}

export enum UserInputSource {
  CONTENT = "CONTENT",
  FILE = "FILE",
  TEXT = "TEXT",
}

type UserInputContent = {
  source: UserInputSource.CONTENT;
};

type UserInputText = {
  source: UserInputSource.TEXT;
  text: string;
};

type UserInputFile = {
  source: UserInputSource.FILE;
  file: File;
};

export interface UserInputDTO {
  source: UserInputSource;
  text?: string;
  file?: UploadedFile;
}

export type UserInput = UserInputContent | UserInputText | UserInputFile;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isUserInput = (value: any): value is UserInput => {
  return Object.values(UserInputSource).includes(value?.source);
};

export type SkillsPayload<Input = UserInput> =
  | EditDocumentPayload
  | PrecedentSearchPayload
  | LegalQuestionPayload
  | CreateStrategyPayload
  | SummarizeDocumentPayload<Input>
  | LegalAdvicePayload
  | IntercurrentMotionPayload
  | FeeAgreementPayload
  | SettlementOfferPayload<Input>
  | HearingsScriptPayload<Input>
  | AdaptDocumentPayload<Input>;
export type SkillsResponse =
  | EditDocumentResponse
  | PrecedentSearchResponse
  | LegalQuestionResponse
  | CreateStrategyResponse
  | SummarizeDocumentResponse
  | LegalAdviceResponse
  | IntercurrentMotionResponse
  | FeeAgreementResponse
  | SettlementOfferResponse
  | HearingsScriptResponse
  | AdaptDocumentResponse;

export type OnMessageReceivedParam =
  | { type: WebsocketMessageType.MESSAGE; data: ResponseMessage }
  | { type: WebsocketMessageType.SKILLS; data: SkillsResponse };

interface MessageFile {
  id: string;
  cortexId: string;
  url: string;
  name: string;
}

export enum MessageStatus {
  COMPLETED = "COMPLETED",
  PROCESSING = "PROCESSING",
  CANCELED = "CANCELED",
  ERROR = "ERROR",
}

export type Evaluation = "THUMBS_DOWN" | "THUMBS_UP";

export enum ThreadMessageErrorType {
  INSUFFICIENT_CREDITS = "INSUFFICIENT_CREDITS",
  UNKNOWN = "UNKNOWN",
}

export interface ThreadMessage {
  id: string;
  threadId: string;
  author: string;
  text: string;
  context: string | null;
  createdAt: string;
  evaluation?: Evaluation;
  payload: unknown;
  canceledAt: string | null;
  status: MessageStatus;
  files: MessageFile[];
  errorType: ThreadMessageErrorType | null;
  actionId: ActionId | null;
  replyToMessageId: string | null;
}

export interface OnThreadConnectParams {
  threadId: string;
  threadName: string;
  messages: ThreadMessage[];
  openDocumentsIds: number[];
}

export interface OnNotificationReceivedParams {
  id: number;
}

export interface OnThreadErrorParams {
  threadId: string;
  error: ThreadErrorType;
}

export enum ThreadErrorType {
  USER_NOT_FOUND = "USER_NOT_FOUND",
  ERROR_GETTING_THREAD = "ERROR_GETTING_THREAD",
  THREAD_NOT_FOUND = "THREAD_NOT_FOUND",
  THREAD_ALREADY_CONNECTED = "THREAD_ALREADY_CONNECTED",
  THREAD_CONNECTED_IN_OTHER_INSTANCE = "THREAD_CONNECTED_IN_OTHER_INSTANCE",
  ERROR_GETTING_MESSAGES = "ERROR_GETTING_MESSAGES",
}
