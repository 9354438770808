import { ApplicantService } from "@/services/applicant";
import { useAuthStore } from "@/stores/useAuthStore";
import { useQuery } from "@tanstack/react-query";

export const getApplicantsQueryKey = () => ["applicants"];

export const useApplicants = () => {
  const { isLoggedIn } = useAuthStore();

  return useQuery({
    queryKey: getApplicantsQueryKey(),
    queryFn: async () => {
      return await ApplicantService.getApplicants();
    },
    enabled: isLoggedIn,
  });
};
