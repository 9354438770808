import { Skeleton, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { CaseMessage } from "@/hooks/lexZap/types";
import { useCompanyTeam } from "@/hooks/company/useCompanyTeam";

export const Sender = ({ message }: { message: CaseMessage }) => {
  const { data: companyUsers, isLoading: isLoadingCompanyUsers } = useCompanyTeam();

  const senderUser = useMemo(() => {
    if (!companyUsers) return null;

    return companyUsers.find((user) => user.userId === message.lawyerId);
  }, [companyUsers, message.lawyerId]);

  if (!message.fromLawyer) {
    return null;
  }

  if (isLoadingCompanyUsers) {
    return (
      <Skeleton
        variant="text"
        width={200}
        sx={{
          mb: 1,
        }}
      />
    );
  }

  if (message.fromAssistant) {
    return (
      <Typography
        variant="body2"
        sx={{
          color: "text.secondary",
          width: "fit-content",
        }}
        mb={1}
      >
        Enviado pelo assistente virtual
      </Typography>
    );
  }

  return (
    <Typography
      variant="body2"
      sx={{
        color: "text.secondary",
        width: "fit-content",
      }}
      mb={1}
    >
      Enviado por {senderUser?.name || "usuário desconhecido"}
    </Typography>
  );
};
