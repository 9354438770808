import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApi } from "@/hooks/useApi";
import { UpdateLexZapCompanyParams } from "@/hooks/useApi/types";
import { lexZapCompaniesQueryKey } from "../useLexZapCompanies";
import { LexZapCompany } from "../../types";

export const useUpdateLexZapCompany = () => {
  const { updateLexZapCompany } = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["updateLexZapCompany"],
    mutationFn: updateLexZapCompany,
    onMutate: async ({ companyId, updates }: UpdateLexZapCompanyParams) => {
      const previousCompanies = queryClient.getQueryData<LexZapCompany[]>(lexZapCompaniesQueryKey);

      queryClient.setQueryData<LexZapCompany[]>(lexZapCompaniesQueryKey, (old = []) =>
        old.map((company) => (company.companyId === companyId ? { ...company, ...updates } : company))
      );

      return { previousCompanies };
    },
    onError: (_error, _updatedCompany, context) => {
      if (context?.previousCompanies) {
        queryClient.setQueryData(lexZapCompaniesQueryKey, context.previousCompanies);
      }
    },
  });
};
