import { logger } from "@/core/logger";
import juice from "juice";
// @ts-expect-error No types available
import extHtmlToDocx from "@turbodocx/html-to-docx";
import { getFormattedDate } from "@/utils/formattedDate";
import { saveFileAs } from "@/utils/saveFileAs";
import { Result } from "@/core/Result";

interface DocxOptions {
  orientation: "portrait" | "landscape";
  creator: string;
  lang: string;
  font: string;
  fontSize: number;
}

export async function exportHtmlToDocx(html: string): Promise<Result<void>> {
  try {
    const htmlWithBase64Images = await transformAllHtmlImgToBase64(html);
    const htmlWithInlineContent = juice.inlineContent(htmlWithBase64Images, "p:has(strong) { font-weight: bold;}");

    const docx = await convertToDocx(htmlWithInlineContent);
    if (docx.isFailure) {
      throw new Error(docx.getError());
    }

    saveFileAs(docx.getValue(), `Documento_${getFormattedDate()}.docx`);

    return Result.ok();
  } catch (error) {
    logger.error("Failed to export document to DOCX", error);
    return Result.fail("Failed to export document to DOCX");
  }
}

async function transformAllHtmlImgToBase64(htmlContent: string): Promise<string> {
  const doc = new DOMParser().parseFromString(htmlContent, "text/html");
  const images = Array.from(doc.querySelectorAll("img"));

  await Promise.all(images.map(convertImageToBase64));

  return doc.body.innerHTML?.replace(/&nbsp;/g, "");
}

async function convertImageToBase64(image: HTMLImageElement): Promise<void> {
  try {
    const base64 = await fetchImageAsBase64(image.src);
    image.src = base64;
    image.srcset = base64;
  } catch (error) {
    logger.error("Failed to convert image to base64", error);
  }
}

async function fetchImageAsBase64(url: string): Promise<string> {
  const response = await fetch(url);
  const blob = await response.blob();

  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
}

async function convertToDocx(html: string): Promise<Result<string>> {
  const options: DocxOptions = {
    orientation: "portrait",
    creator: "Lexter",
    lang: "pt_BR",
    font: "Arial",
    fontSize: 24, // 12pt
  };

  // Try conversion twice due to potential crypto.randomFillSync issues
  const firstAttempt = await attemptDocxConversion(html, options);
  if (firstAttempt.isSuccess) {
    return firstAttempt;
  }

  return attemptDocxConversion(html, options);
}

async function attemptDocxConversion(html: string, options: DocxOptions): Promise<Result<string>> {
  try {
    const docx = await extHtmlToDocx(html, "", options);
    return Result.ok(docx);
  } catch (error) {
    const errorMessage = error instanceof Error ? error.message : String(error);
    return Result.fail(errorMessage);
  }
}
