import { CaseMessage } from "@/hooks/lexZap/types";
import { Box, SxProps } from "@mui/material";
import React, { ReactNode } from "react";

interface MessageCardProps {
  children: ReactNode;
  message: CaseMessage;
  bottomContent?: ReactNode;
  sx?: SxProps;
}

export const MessageCard = ({ children, message, bottomContent, sx }: MessageCardProps) => {
  return (
    <Box
      sx={{
        maxWidth: "65%",
      }}
    >
      <Box
        sx={{
          width: "100%",
          borderRadius: "16px",
          borderBottomRightRadius: message.fromLawyer ? 0 : "16px",
          backgroundColor: message.fromLawyer ? "common.lightWarm" : "common.white",
          border: message.fromLawyer ? undefined : "1px solid",
          borderColor: message.fromLawyer ? undefined : "common.lightWarm",
          paddingX: 3,
          paddingY: 2,
          ...sx,
        }}
      >
        {children}
      </Box>

      {bottomContent}
    </Box>
  );
};
