import React from "react";

import { useAuthContext } from "@/contexts/AuthContext";
import { useLocation } from "react-router-dom";
import { AUTHENTICATED_ROUTES_PREFIX } from "@/routes/routePaths";
import { PublicLayout } from "../PublicLayout";

const ConditionalLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { user } = useAuthContext();
  const location = useLocation();

  const isLoggedRoute = location.pathname.startsWith(AUTHENTICATED_ROUTES_PREFIX);

  if (user && isLoggedRoute) {
    return <> {children} </>;
  } else {
    return <PublicLayout>{children}</PublicLayout>;
  }
};

export default ConditionalLayout;
