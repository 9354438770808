import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApi } from "@/hooks/useApi";
import { getMessagesQueryKey } from "../useLexZapMessages";
import { Case, CaseMessage, CaseMessageType } from "../types";
import { v4 as uuidV4 } from "uuid";
import { getCasesQueryKey } from "../useLexZapCases";
import { SendLexZapMessageParams } from "@/hooks/useApi/types";
import { useAuthContext } from "@/contexts/AuthContext";

export const useSendLexZapMessage = () => {
  const queryClient = useQueryClient();
  const { sendLexZapMessage } = useApi();
  const { user } = useAuthContext();

  return useMutation({
    mutationKey: ["sendLexZapMessage"],
    mutationFn: async (params: SendLexZapMessageParams) => {
      return sendLexZapMessage(params);
    },
    onMutate: async ({ applicantPhoneNumber, message }) => {
      await queryClient.cancelQueries({ queryKey: getMessagesQueryKey({ applicantPhoneNumber }) });

      const previousMessages = queryClient.getQueryData<CaseMessage[]>(getMessagesQueryKey({ applicantPhoneNumber }));

      const tempId = uuidV4();

      const newMessage: CaseMessage = {
        chatId: "",
        from: "",
        fromAssistant: false,
        lawyerId: user?.userId,
        fromLawyer: true,
        id: tempId,
        to: "",
        body: message.body,
        media: message.media,
        type: message.type as CaseMessageType,
        isForwarded: false,
        timestamp: Date.now(),
        isSending: true,
        statusUpdateTimestamp: {},
        applicantPhoneNumber,
      };

      queryClient.setQueryData<CaseMessage[]>(getMessagesQueryKey({ applicantPhoneNumber }), (oldMessages = []) => {
        return [...oldMessages, newMessage];
      });

      queryClient.setQueryData(getCasesQueryKey(), (oldCases: Case[]) =>
        oldCases.map((caseItem) =>
          caseItem.applicantPhoneNumber === applicantPhoneNumber ? { ...caseItem, lastMessage: newMessage } : caseItem
        )
      );

      return { previousMessages, newMessage };
    },
    onError: (_error, { applicantPhoneNumber }, context) => {
      if (context?.previousMessages) {
        queryClient.setQueryData(getMessagesQueryKey({ applicantPhoneNumber }), context.previousMessages);

        const previousLastMessage = context.previousMessages[context.previousMessages.length - 1];

        queryClient.setQueryData(getCasesQueryKey(), (oldCases: Case[]) =>
          oldCases.map((caseItem) =>
            caseItem.applicantPhoneNumber === applicantPhoneNumber
              ? { ...caseItem, lastMessage: previousLastMessage }
              : caseItem
          )
        );
      }
    },
    onSuccess: async (data, { applicantPhoneNumber }, context) => {
      if (!data) return;

      queryClient.setQueryData<CaseMessage[]>(getMessagesQueryKey({ applicantPhoneNumber }), (oldMessages = []) =>
        oldMessages.map((message) =>
          message.id === context.newMessage.id ? { ...message, id: data.message.id, isSending: false } : message
        )
      );

      queryClient.setQueryData(getCasesQueryKey(), (oldCases: Case[]) =>
        oldCases.map((caseItem) =>
          caseItem.applicantPhoneNumber === applicantPhoneNumber
            ? { ...caseItem, lastMessage: { ...context.newMessage, id: data.message.id, isSending: false } }
            : caseItem
        )
      );
    },
  });
};
