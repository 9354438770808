import { Box, Skeleton } from "@mui/material";
import React from "react";

export const CaseStepSkeleton = () => {
  return (
    <Box
      sx={{
        display: "flex",
        borderBottomColor: "common.lightShade",
        borderBottomWidth: 1,
        borderBottomStyle: "solid",
        paddingY: 1.5,
        justifyContent: "space-between",
        gap: 0.5,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2,
        }}
      >
        <Skeleton variant="rectangular" sx={{ width: 24, height: 24 }} />
        <Skeleton variant="text" sx={{ fontSize: "1rem", width: 256 }} />
      </Box>
    </Box>
  );
};
