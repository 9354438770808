import { createTheme } from "@mui/material/styles";
import { palette } from "./palette";
import { typography } from "./typography";
import { LinkProps } from "@mui/material";
import { LinkBehavior } from "@/theme/linkBehavior";

import type {} from "@mui/x-data-grid/themeAugmentation";

export const theme = createTheme({
  palette,
  typography,
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          textTransform: "none",
          minHeight: "40px",
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: "0px 1px 3px 0px #0000001F",
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontWeight: 400,
          fontSize: "16px",
          color: "#1E1818E5",
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          fontWeight: 400,
          fontSize: "16px",
          color: "#1E1818E5",
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          fontWeight: 400,
          fontSize: "16px",
          color: "#1E1818E5",
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(22, 34, 44, 0.6)",
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          body3: "p",
        },
      },
    },
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
      styleOverrides: {
        root: {
          fontWeight: "400",
          color: "inherit",
          textDecoration: "underline",
          textDecorationColor: "inherit",
          cursor: "pointer",
          ":hover": {
            fontWeight: "700",
          },
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: 0,
        },
        main: {
          overflow: "auto",
        },
        row: {
          backgroundColor: "#fff",
        },
        cell: {
          outline: "0 !important",
          fontSize: "14px",
          color: "#1E1818",
        },
        columnHeaders: {
          position: "sticky",
          top: "-16px",
          zIndex: 2,
          backgroundColor: "#fff",
        },
        columnHeaderTitle: {
          fontWeight: "500",
          fontSize: "14px",
          color: "#1E1818",
        },
        menuList: {
          padding: 4,
        },
        menuIconButton: {
          padding: 4,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          padding: 16,
          color: "#1E1818E5",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            fontWeight: "700",
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        InputLabelProps: { shrink: true },
      },
    },
    MuiChip: {
      styleOverrides: {
        label: {
          fontSize: "12px",
          fontWeight: 400,
          lineHeight: "14px",
          letterSpacing: "0.03em",
        },
      },
    },
  },
});
