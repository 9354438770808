import { SkillGroup } from "@/core/skillGroups/types";
import { CreditPerSkill } from "@/core/skills/types";

export const createNewDocumentSkillGroup: SkillGroup = {
  id: "create_new_document",
  name: "Escrever peça judicial",
  subtitle: "Selecione o tipo de peça:",
  credits: CreditPerSkill.ADVANCED_SKILL,
  skillShortcuts: [
    { label: "Petição inicial", skillId: "create_initial_petition", flag: "skillInitialPetitionV1Enabled" },
    { label: "Petição inicial", skillId: "create_initial_petition_v2", flag: "skillInitialPetitionV2Enabled" },
    { label: "Reclamação trabalhista", skillId: "create_labor_complaint" },
    { label: "Contestação", skillId: "create_contestation" },
    { label: "Réplica", skillId: "create_reply" },
    { label: "Petição intercorrente", skillId: "intercurrent_motion" },
    { label: "Apelação", skillId: "create_appeal" },
    { label: "Recurso ordinário trabalhista", skillId: "create_ordinary_appeal" },
    { label: "Outro tipo de peça", skillId: "create_other_type_of_piece" },
  ],
  tags: ["recommend", "litigation"],
  onboarding: {
    enabledSkills: ["create_initial_petition", "create_contestation", "create_appeal"],
  },
};
