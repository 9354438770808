import {
  Autocomplete,
  Box,
  Button,
  Chip,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { Loading } from "@/components/Loading";
import { ModalRight } from "@/components/Modal/ModalRight";
import { useAuthContext } from "@/contexts/AuthContext";
import { useApi } from "@/hooks/useApi";
import { UserProfile } from "@/hooks/useApi/types";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "@/routes/routePaths";
import { WebToast } from "@//components/core/Toast";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { MyDataInfoSchema, myDataInfoSchema, MyDataInfoSchemaWhatsapp, myDataInfoSchemaWhatsapp } from "./types";
import { useLexZapUser } from "@/hooks/lexZap/useLexZapUser";
import { Department } from "@/hooks/company/types";
import { CloseOutlined } from "@mui/icons-material";
import { activityAreas } from "../MyTeam/defaultValues";
import { useUpdateLexZapUser } from "@/hooks/lexZap/useUpdateLexZapUser";
import { Phone } from "@/core/Phone";
import { applyPhoneMask } from "@/utils/applyInputMask";

export function ClientConfigMyDataModal() {
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const { updateUserProfile } = useApi();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { isDirty, isValid, errors },
  } = useForm<MyDataInfoSchema>({
    defaultValues: {
      name: user?.userName || "",
      email: user?.userEmail || "",
    },
    mode: "onChange",
    resolver: zodResolver(myDataInfoSchema),
  });

  const getUserData = React.useCallback(() => {
    setIsLoading(true);

    if (!user || !user.userEmail) {
      return;
    }
    setIsLoading(false);
  }, [user]);

  useEffect(() => {
    getUserData();
    if (user) {
      reset({
        name: user.userName,
        email: user.userEmail,
      });
    }
  }, [getUserData, user, reset]);

  const updateUser = async (userProfile: UserProfile) => {
    setIsLoading(true);
    try {
      await updateUserProfile(userProfile);

      setIsLoading(false);

      goBackToSettings();
      return WebToast.success("Alteração feita com sucesso");
    } catch (err) {
      return WebToast.error("Falha ao alterar o perfil do usuário");
    }
  };

  const goBackToSettings = () => {
    navigate(ROUTE_PATHS.CLIENT_CONFIG, { replace: true });
  };

  const handleUpdateUserProfile = handleSubmit(async (data) => {
    try {
      if (!user || !user.userEmail) {
        return;
      }

      await updateUser({
        userId: user.userId,
        name: data.name,
        email: data.email,
        companies: [],
        reviewConfig: {
          includeDefaultText: true,
        },
      });
    } catch (e) {
      WebToast.error("Não foi possível alterar as informações do usuário");
    }
  });

  const saveButtonDisabled = !isDirty || !isValid || isLoading;

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "min-content min-content",
        gridTemplateRows: "minmax(0, 1fr)",
        position: "fixed",
        top: 0,
        bottom: 0,
        right: 0,
        bgcolor: "background.paper",
        boxShadow: 24,
        zIndex: "modal",
      }}
    >
      <ModalRight
        title={"Meus Dados"}
        size={"SMALL"}
        onClose={goBackToSettings}
        footer={
          user &&
          !isLoading && (
            <ModalFooter
              confirmLabel={"Salvar alterações"}
              onConfirm={handleUpdateUserProfile}
              cancelLabel="Cancelar"
              onCancel={goBackToSettings}
              saveButtonDisabled={saveButtonDisabled}
            />
          )
        }
        open={true}
      >
        <Loading isLoading={isLoading}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "32px",
              }}
            >
              {user && (
                <>
                  <TextField
                    placeholder="Nome Completo"
                    {...register("name", { required: true })}
                    label="Nome"
                    error={!!errors.name}
                    helperText={errors.name?.message}
                  />
                  <TextField
                    placeholder="E-mail de trabalho"
                    {...register("email")}
                    label="E-mail"
                    error={!!errors.email}
                    helperText={errors.email?.message}
                    disabled={true}
                  />
                </>
              )}
            </Box>
          </Box>
        </Loading>
      </ModalRight>
    </Box>
  );
}

export function ClientConfigMyDataModalWhatsapp() {
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const { updateUserProfile } = useApi();
  const [isLoadingUpdate, setIsLoadingUpdate] = React.useState<boolean>(false);
  const [isValidPhoneNumber, setIsValidPhoneNumber] = React.useState<boolean>(true);
  const { data: lexZapUser, isLoading: isLoadingLexZapUser } = useLexZapUser();
  const isLoading = isLoadingUpdate || isLoadingLexZapUser;
  const { mutateAsync: updateLexZapUser } = useUpdateLexZapUser();

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { isDirty, isValid, errors },
  } = useForm<MyDataInfoSchemaWhatsapp>({
    defaultValues: {
      name: user?.userName || "",
      email: user?.userEmail || "",
      department: lexZapUser?.department,
      activityAreas: lexZapUser?.activityAreas,
      notificationNumber: lexZapUser?.notificationNumber?.substring(2),
    },
    mode: "onChange",
    resolver: zodResolver(myDataInfoSchemaWhatsapp),
  });

  useEffect(() => {
    reset({
      name: user?.userName,
      email: user?.userEmail,
      department: lexZapUser?.department,
      activityAreas: lexZapUser?.activityAreas,
      notificationNumber: lexZapUser?.notificationNumber?.substring(2),
    });
  }, [user, lexZapUser, reset]);

  const updateUser = async (userProfile: UserProfile) => {
    setIsLoadingUpdate(true);
    try {
      await updateUserProfile(userProfile);

      setIsLoadingUpdate(false);

      goBackToSettings();
      return WebToast.success("Alteração feita com sucesso");
    } catch (err) {
      return WebToast.error("Falha ao alterar o perfil do usuário");
    }
  };

  const goBackToSettings = () => {
    navigate(ROUTE_PATHS.CLIENT_CONFIG, { replace: true });
  };

  const handleUpdateUserProfile = handleSubmit(async (data) => {
    try {
      if (!user || !user.userEmail) {
        return;
      }

      await updateUser({
        userId: user.userId,
        name: data.name,
        email: data.email,
        companies: [],
        reviewConfig: {
          includeDefaultText: true,
        },
      });

      const phoneNumberWithCountryCode = Phone.new(data.notificationNumber).getValue().toPersistence();

      await updateLexZapUser({
        department: data.department,
        activityAreas: data.activityAreas,
        notificationNumber: phoneNumberWithCountryCode,
      });
    } catch (e) {
      WebToast.error("Não foi possível alterar as informações do usuário");
    }
  });

  const selectedDepartment = watch("department");
  const selectedActivityAreas = watch("activityAreas");

  const saveButtonDisabled =
    isDirty ||
    !isValid ||
    isLoading ||
    !isValidPhoneNumber ||
    (selectedDepartment === Department.LEGAL && (!selectedActivityAreas || selectedActivityAreas.length === 0));

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "min-content min-content",
        gridTemplateRows: "minmax(0, 1fr)",
        position: "fixed",
        top: 0,
        bottom: 0,
        right: 0,
        bgcolor: "background.paper",
        boxShadow: 24,
        zIndex: "modal",
      }}
    >
      <ModalRight
        title={"Meus Dados"}
        size={"SMALL"}
        onClose={goBackToSettings}
        footer={
          user &&
          !isLoading && (
            <ModalFooter
              confirmLabel={"Salvar alterações"}
              onConfirm={handleUpdateUserProfile}
              cancelLabel="Cancelar"
              onCancel={goBackToSettings}
              saveButtonDisabled={saveButtonDisabled}
            />
          )
        }
        open={true}
      >
        <Loading isLoading={isLoading}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "32px",
              }}
            >
              {user && (
                <>
                  <TextField
                    placeholder="Nome Completo"
                    {...register("name", { required: true })}
                    label="Nome"
                    error={!!errors.name}
                    helperText={errors.name?.message}
                  />
                  <TextField
                    placeholder="E-mail de trabalho"
                    {...register("email")}
                    label="E-mail"
                    error={!!errors.email}
                    helperText={errors.email?.message}
                    disabled={true}
                  />
                </>
              )}
              <>
                <TextField
                  placeholder="Telefone"
                  {...register("notificationNumber", { required: true })}
                  onChange={(e) => {
                    setIsValidPhoneNumber(
                      e.target.value === "" ? true : Phone.new(e.target.value.replace(/[^\d]+/g, "")).isSuccess
                    );
                  }}
                  inputProps={{
                    maxLength: 15,
                  }}
                  InputProps={{
                    onKeyUp: applyPhoneMask,
                  }}
                  label="Telefone"
                  error={!isValidPhoneNumber}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                  }}
                >
                  <Box>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "16px",
                      }}
                    >
                      Setor
                    </Typography>
                    <Typography variant="body2">
                      Os setores influenciam especialmente no redirecionamento de um atendimento para um colaborador
                    </Typography>
                  </Box>
                  <FormControl>
                    <RadioGroup
                      onChange={(e) => {
                        reset((formValues) => ({
                          ...formValues,
                          department: e.target.value as Department,
                        }));
                      }}
                      value={selectedDepartment || null}
                    >
                      <FormControlLabel value={Department.ADMINISTRATIVE} control={<Radio />} label="Administrativo" />
                      <FormControlLabel value={Department.FINANCIAL} control={<Radio />} label="Financeiro" />
                      <FormControlLabel value={Department.LEGAL} control={<Radio />} label="Jurídico" />
                    </RadioGroup>
                  </FormControl>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                  }}
                >
                  {selectedDepartment === Department.LEGAL && (
                    <>
                      <Box>
                        <Typography
                          variant="body1"
                          sx={{
                            fontSize: "16px",
                          }}
                        >
                          Área de atuação
                        </Typography>
                        <Typography variant="body2">
                          A área de atuação impacta no redirecionamento ou não de um atendimento para você
                        </Typography>
                      </Box>
                      <Box>
                        <Autocomplete
                          disablePortal
                          options={activityAreas}
                          renderInput={(params) => <TextField {...params} label="Comece a digitar para buscar" />}
                          multiple
                          value={selectedActivityAreas || []}
                          noOptionsText="Nenhuma opção encontrada"
                          sx={{
                            "& .MuiChip-root": {
                              visibility: "hidden",
                              display: "none",
                            },
                            "& .MuiChip-label": {
                              display: "none",
                              visibility: "hidden",
                            },
                          }}
                          onChange={(_, value) => {
                            reset((formValues) => ({
                              ...formValues,
                              activityAreas: value,
                            }));
                          }}
                        />
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          gap: 1,
                          flexWrap: "wrap",
                        }}
                      >
                        {selectedActivityAreas?.map((area) => (
                          <Chip
                            key={area}
                            label={area}
                            onDelete={() => {
                              reset((formValues) => ({
                                ...formValues,
                                activityAreas: selectedActivityAreas?.filter((item) => item !== area),
                              }));
                            }}
                            sx={{
                              backgroundColor: "primary.main",
                              color: "common.white",
                            }}
                            deleteIcon={<CloseOutlined />}
                          />
                        ))}
                      </Box>
                    </>
                  )}
                </Box>
              </>
            </Box>
          </Box>
        </Loading>
      </ModalRight>
    </Box>
  );
}

const ModalFooter = ({
  confirmLabel,
  cancelLabel,
  onConfirm,
  onCancel,
  saveButtonDisabled,
}: {
  confirmLabel: string;
  cancelLabel: string;
  onConfirm: () => void;
  onCancel: () => void;
  saveButtonDisabled: boolean;
}) => {
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "auto auto",
        gap: "8px",
        margin: "0 auto",
      }}
    >
      <Button variant="contained" disabled={saveButtonDisabled} onClick={onConfirm} size="small">
        {confirmLabel}
      </Button>
      <Button size="small" onClick={onCancel}>
        {cancelLabel}
      </Button>
    </Box>
  );
};
