import { Search as SearchIcon } from "@mui/icons-material";
import { TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React, { useEffect, useMemo, useState } from "react";

import { Button } from "@/components/Button";
import { Court, isCourtGroup } from "../types";
import { CourtCheckbox } from "./CourtCheckbox";
import { availableCourts, carf } from "./courts";
import { useFeatureFlags } from "@/hooks/useFeatureFlags";

interface CourtsFilterProps {
  onChange: (courts: Court[]) => void;
}

export const CourtsFilter = ({ onChange }: CourtsFilterProps) => {
  const [searchCourtInputText, setSearchCourtInputText] = useState("");
  const { searchPrecedentCarfEnabled } = useFeatureFlags();

  const courtsToShow = useMemo(() => {
    return (searchPrecedentCarfEnabled ? [carf, ...availableCourts] : availableCourts).filter((court) => {
      const filterBySearch = (court: Court) => court.code.toLowerCase().includes(searchCourtInputText.toLowerCase());

      if (isCourtGroup(court)) {
        return court.courts.some((c) => filterBySearch(c));
      }

      return filterBySearch(court);
    });
  }, [searchCourtInputText, searchPrecedentCarfEnabled]);
  const [selectedCourts, setSelectedCourts] = useState<Court[]>([]);

  const handleCourtChange = (court: Court, checked: boolean) => {
    if (checked) {
      setSelectedCourts((prev) => [...prev, court]);
    } else {
      setSelectedCourts((prev) => prev.filter((c) => c.code !== court.code));
    }
  };

  useEffect(() => {
    onChange(selectedCourts);
  }, [onChange, selectedCourts]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 3,
      }}
    >
      <Typography variant="preTitle">Tribunal</Typography>

      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 2,
        }}
      >
        <TextField
          onChange={(e) => setSearchCourtInputText(e.target.value)}
          placeholder="Buscar por tribunal"
          sx={{
            flex: 1,
            minWidth: "180px",
          }}
          InputProps={{
            startAdornment: (
              <SearchIcon
                fontSize="medium"
                sx={{
                  mr: 1,
                }}
              />
            ),
          }}
        />
        <Button
          onClick={() => setSelectedCourts([])}
          variant="text"
          sx={{
            textTransform: "none",
            textDecoration: "underline",
            whiteSpace: "nowrap",
            minWidth: "min-content",
            color: "text.primary",
          }}
        >
          Desmarcar Todos
        </Button>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        {courtsToShow.map((item, index) => (
          <CourtCheckbox key={index} data={item} onChange={handleCourtChange} selectedCourts={selectedCourts} />
        ))}
      </Box>
    </Box>
  );
};
