import { PageLayout } from "@/components/PageLayout";
import React from "react";

import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useLexZapCase } from "@/hooks/lexZap/useLexZapCase";
import { useDeleteCases } from "@/hooks/lexZap/useDeleteCases";
import { ROUTE_PATHS } from "@/routes/routePaths";
import { useArchiveCases } from "@/hooks/lexZap/useArchiveCases";
import { useUnarchiveCases } from "@/hooks/lexZap/useUnarchiveCases";
import { CaseDetails } from "@/pages/WhatsappAssistantCasePage/components/CaseDetails";
import { DeleteCasesModal } from "@/pages/WhatsappAssistantCases/components/CardsView/components/DeleteCasesModal";
import { ArchiveCasesModal } from "@/pages/WhatsappAssistantCases/components/CardsView/components/ArchiveCasesModal";
import { UnarchiveCasesModal } from "@/pages/WhatsappAssistantCases/components/CardsView/components/UnarchiveCasesModal";
import { useFeatureFlags } from "@/hooks/useFeatureFlags";

export const WhatsappAssistantCasePage = () => {
  const { caseNextStepsEnabled } = useFeatureFlags();

  const { caseId } = useParams<{ caseId: string }>();
  const { caseData, isLoading: isLoadingCase } = useLexZapCase({ caseId });
  const { mutateAsync: deleteCases, isPending: isDeletingCase } = useDeleteCases();
  const { mutateAsync: archiveCases, isPending: isArchivingCase } = useArchiveCases();
  const { mutateAsync: unarchiveCases, isPending: isUnarchivingCase } = useUnarchiveCases();

  const [isOpenDeleteCase, setIsOpenDeleteCase] = React.useState(false);
  const [isOpenArchiveCase, setIsOpenArchiveCase] = React.useState(false);
  const [isOpenUnarchiveCase, setIsOpenUnarchiveCase] = React.useState(false);

  const navigate = useNavigate();

  const handleOpenDeleteCase = () => {
    if (!caseId) return;
    setIsOpenDeleteCase(true);
  };

  const handleCloseDeleteCase = () => {
    setIsOpenDeleteCase(false);
  };

  const handleDeleteCase = async () => {
    if (!caseId) {
      handleCloseDeleteCase();
      return;
    }
    await deleteCases({ casesIds: [caseId] });
    handleCloseDeleteCase();
    navigate(ROUTE_PATHS.WHATSAPP_ASSISTANT);
  };

  const handleOpenArchiveCase = () => {
    if (!caseId) return;
    setIsOpenArchiveCase(true);
  };

  const handleCloseArchiveCase = () => {
    setIsOpenArchiveCase(false);
  };

  const handleArchiveCase = async () => {
    if (!caseId) {
      handleCloseArchiveCase();
      return;
    }
    await archiveCases({ casesIds: [caseId] });
    handleCloseArchiveCase();
  };

  const handleOpenUnarchiveCase = () => {
    if (!caseId) return;

    setIsOpenUnarchiveCase(true);
  };

  const handleCloseUnarchiveCase = () => {
    setIsOpenUnarchiveCase(false);
  };

  const handleUnarchiveCase = async () => {
    if (!caseId) {
      handleCloseUnarchiveCase();
      return;
    }
    await unarchiveCases({ casesIds: [caseId] });
    handleCloseUnarchiveCase();
  };

  if (!caseId) return null;

  return (
    <PageLayout
      contentProps={{
        disablePadding: true,
        fullWidth: true,
      }}
      contentSx={{
        height: "100%",
      }}
    >
      <CaseDetails
        caseId={caseId}
        loadingCase={isLoadingCase}
        caseData={caseData}
        onDeleteCase={handleOpenDeleteCase}
        onArchiveCase={handleOpenArchiveCase}
        onUnarchiveCase={handleOpenUnarchiveCase}
        showNextSteps={caseNextStepsEnabled}
      />

      <DeleteCasesModal
        isOpen={isOpenDeleteCase}
        onConfirm={handleDeleteCase}
        onCancel={handleCloseDeleteCase}
        isLoading={isDeletingCase}
        amount={1}
      />

      <ArchiveCasesModal
        isOpen={isOpenArchiveCase}
        onConfirm={handleArchiveCase}
        onCancel={handleCloseArchiveCase}
        isLoading={isArchivingCase}
        amount={1}
      />

      <UnarchiveCasesModal
        isOpen={isOpenUnarchiveCase}
        onConfirm={handleUnarchiveCase}
        onCancel={handleCloseUnarchiveCase}
        isLoading={isUnarchivingCase}
        amount={1}
      />

      <Outlet />
    </PageLayout>
  );
};
