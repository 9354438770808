import React from "react";
import { Box } from "@mui/material";
import { usePlatform } from "@/hooks/usePlatform";
import { CarouselContent } from "@/components/Login/components/CarouselContent";

export const PublicLayout = ({ children }: { children: React.ReactNode }) => {
  const platform = usePlatform();

  if (platform !== "DESKTOP") {
    return <>{children}</>;
  }
  return (
    <Box sx={{ display: "flex", height: "100vh", width: "100vw" }}>
      <Box sx={{ flex: "0 0 50%", overflowY: "auto" }}>{children}</Box>
      <Box
        sx={{
          flex: "0 0 50%",
          borderLeft: undefined,
          backgroundColor: "orange.light.5",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          p: 3,
          overflowY: "auto",
        }}
      >
        <CarouselContent />
      </Box>
    </Box>
  );
};
