import { z } from "zod";

export const hubspotFormSchema = z.object({
  email: z
    .string()
    .min(1, { message: "Campo obrigatório." })
    .max(200, { message: "Máximo de 200 caracteres." })
    .email({ message: "E-mail inválido." }),
  hs_whatsapp_phone_number: z
    .string()
    .min(1, { message: "Campo obrigatório." })
    .regex(/^\(?[1-9]{2}\)? ?(?:[2-8]|9[0-9])[0-9]{3}-?[0-9]{4}$/, {
      message: "WhatsApp inválido",
    }),
  quantos_casos_novos_voce_recebe_por_mes_: z.string().min(1, { message: "Campo obrigatório." }),
  numemployees: z.string().min(1, { message: "Campo obrigatório." }),
  areas_de_atuacao: z.string().min(1, { message: "Campo obrigatório." }),
  tem_facebook: z.string().min(1, { message: "Campo obrigatório." }),
  ddd_de_preferencia: z.string().min(1, { message: "Campo obrigatório." }),
});

export type HubspotFormData = z.infer<typeof hubspotFormSchema>;
