import { Court, CourtGroup } from "../types";

const courts: Array<Court | CourtGroup> = [
  {
    code: "STF",
  },
  {
    code: "STJ",
  },
  {
    code: "TJAC",
  },
  {
    code: "TJAL",
  },
  {
    code: "TJAM",
  },
  {
    code: "TJBA",
  },
  {
    code: "TJCE",
  },
  {
    code: "TJDFT",
  },
  {
    code: "TJGO",
  },
  {
    code: "TJMG",
  },
  {
    code: "TJMS",
  },
  {
    code: "TJMT",
  },
  {
    code: "TJPA",
  },
  {
    code: "TJPE",
  },
  {
    code: "TJPR",
  },
  {
    code: "TJRJ",
  },
  {
    code: "TJRS",
  },
  {
    code: "TJSC",
  },
  {
    code: "TJSP",
  },
  {
    name: "TRFs",
    courts: [{ code: "TRF1" }, { code: "TRF3" }, { code: "TRF4" }, { code: "TRF5" }],
  },
  {
    name: "TRTs",
    courts: [
      { code: "TRT1" },
      { code: "TRT2" },
      { code: "TRT3" },
      { code: "TRT4" },
      { code: "TRT5" },
      { code: "TRT6" },
      { code: "TRT7" },
      { code: "TRT8" },
      { code: "TRT9" },
      { code: "TRT10" },
      { code: "TRT12" },
      { code: "TRT13" },
      { code: "TRT14" },
      { code: "TRT15" },
      { code: "TRT16" },
      { code: "TRT17" },
      { code: "TRT18" },
      { code: "TRT19" },
      { code: "TRT20" },
      { code: "TRT21" },
      { code: "TRT22" },
      { code: "TRT23" },
      { code: "TRT24" },
    ],
  },
  {
    code: "TST",
  },
];

export const carf: Court = {
  code: "CARF",
};

export const availableCourts = courts;
