import React, { useMemo } from "react";
import { Done, DoneAll, Schedule, Error as ErrorIcon } from "@mui/icons-material";
import { CaseMessage } from "@/hooks/lexZap/types";
import { blue, red } from "@mui/material/colors";

export const MessageStatus = ({ message }: { message: CaseMessage }) => {
  const StatusIcon = useMemo(() => {
    if (!message.statusUpdateTimestamp) {
      return Done;
    }

    if (message.statusUpdateTimestamp.failed) {
      return ErrorIcon;
    }
    if (message.statusUpdateTimestamp.read) {
      return DoneAll;
    }
    if (message.statusUpdateTimestamp.delivered) {
      return DoneAll;
    }
    if (message.statusUpdateTimestamp.sent) {
      return Done;
    }

    return Schedule;
  }, [message]);

  const statusColor = useMemo(() => {
    if (message.statusUpdateTimestamp?.failed) {
      return red[500];
    }
    if (message.statusUpdateTimestamp?.read) {
      return blue[200];
    }

    return "grey.600";
  }, [message]);

  if (!message.fromLawyer) {
    return null;
  }

  return (
    <StatusIcon
      sx={{
        color: statusColor,
        height: "16px",
        width: "16px",
      }}
    />
  );
};
