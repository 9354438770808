import { buildSkillStep } from "@/core/skillForms/builder";

const caseBreakdownMinLength = 140;

export const createContractSkillStep3 = buildSkillStep({
  title: "Detalhamento do contrato",
  description: `Insira o detalhamento do objeto do contrato e informações importantes, como valores de honorários, forma de pagamento, multas, datas de vigência, etc..`,
  fields: [
    {
      name: "case_breakdown",
      type: "string",
      minLength: caseBreakdownMinLength,
      ui: {
        label: "Faça um detalhamento dos serviços:",
        placeholder:
          "Exemplo: Contrato de aluguel de casa entre João da Silva e Maria de Souza. O aluguel terá o valor de R$ 2.000,00 mensais, com duração de 30 meses, iniciando no dia 1 de junho de 2024.",
        widget: "textarea",
      },
    },
  ],
  submitButtonText: "Gerar contrato",
});
