import { Box, Typography } from "@mui/material";
import React from "react";
import { Card } from "../Card";
import { CaseStep } from "./components/CaseStep";
import { CaseNextStep } from "@/hooks/lexZap/types";
import { CaseStepSkeleton } from "@/pages/WhatsappAssistantCasePage/components/CaseDetails/components/CaseNextSteps/components/CaseStepSkeleton";
import { useCaseNextSteps } from "@/hooks/lexZap/useCaseNextSteps";

type CaseNextStepsProps = {
  caseId: string;
  onStepClick: (step: CaseNextStep) => void;
};

export const CaseNextSteps = ({ caseId, onStepClick }: CaseNextStepsProps) => {
  const { data: nextSteps, isLoading: isLoadingNextSteps } = useCaseNextSteps({ caseId });

  const completedSteps = nextSteps?.filter((item) => item.completed) ?? [];
  const todoSteps = nextSteps?.filter((item) => !item.completed) ?? [];

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: 1.5,
          alignItems: "flex-end",
        }}
      >
        <Typography variant="subtitle2" fontWeight={700} fontSize="22px" color="text.primary">
          Sugestões do assistente
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1.5,
          paddingY: 2,
        }}
      >
        {isLoadingNextSteps
          ? new Array(3).fill(0).map((_, index) => <CaseStepSkeleton key={`l-${index}`} />)
          : todoSteps.map((step) => (
              <CaseStep
                key={step.id}
                checked={step.completed}
                onClick={step.skillId || step.fileId ? () => onStepClick(step) : undefined}
                description={step.description}
              />
            ))}
      </Box>

      {Boolean(completedSteps.length) && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1.5,
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: 1.5,
              alignItems: "flex-end",
              borderBottomColor: "common.lightShade",
              borderBottomWidth: 1,
              borderBottomStyle: "solid",
              paddingBottom: 1,
            }}
          >
            <Typography variant="body1" fontWeight={700} color="text.primary">
              Finalizados
            </Typography>
            <Typography variant="body2" color="text.primary">
              {completedSteps.length}/{nextSteps?.length}
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1.5,
            }}
          >
            {completedSteps.map((step) => (
              <CaseStep
                key={step.id}
                checked={step.completed}
                description={step.description}
                onClick={step.skillId || step.fileId ? () => onStepClick(step) : undefined}
              />
            ))}
          </Box>
        </Box>
      )}
    </Card>
  );
};
