import { buildSkillStep } from "@/core/skillForms/builder";

export const createNoticeSkillStep1 = buildSkillStep({
  title: "Detalhes",
  description:
    "Para elaborar uma notificação extrajudicial, insira o nome da parte autora e faça um detalhamento do caso com o maior número de informações possíveis.",
  fields: [
    {
      name: "client",
      type: "string",
      ui: {
        label: "Qual é o nome do cliente que enviará a notificação?",
        placeholder: `Exemplo: João da Silva. \nSe tiver mais de um nome, separe com vírgulas.`,
      },
    },
    {
      name: "case_breakdown",
      type: "string",
      ui: {
        label: "Faça um detalhamento do caso:",
        placeholder:
          "Exemplo: João Silva e sua empresa, 'TechSolution Ltda.', fizeram um contrato com a 'StartupInova S.A.' para desenvolver um software por R$ 100.000,00 e um prazo de seis meses. A StartupInova pediu mudanças que aumentaram o trabalho, e João sugeriu um aditivo com novos custos e prazo, mas a StartupInova não aceitou.",
      },
    },
  ],
});
