import { outsideOfficeClient } from "@/utils/outsideOfficeClient";
import { useMediaQuery } from "@mui/material";
export const usePlatform = () => {
  const isOutsideOfficeClient = outsideOfficeClient();
  const isMobile = useMediaQuery("(max-width: 721px)");
  if (isOutsideOfficeClient) {
    return isMobile ? "MOBILE" : "DESKTOP";
  }
  return "OFFICE";
};
