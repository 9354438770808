import { Box, IconButton, TextField, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import {
  SendRounded as SendRoundedIcon,
  PauseCircleOutline as PauseCircleOutlineIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import { useSendLexZapMessage } from "@/hooks/lexZap/useSendLexZapMessage";
import { useLexZapChatInfo } from "@/hooks/lexZap/useLexZapChatInfo";
import { MessageTemplateCharacterCounter } from "@/components/LexZap/MessageTemplateCharacterCounter";
import ChatMicIcon from "@/assets/svgs/chat-mic.svg?react";
import AddFilesIcon from "@/assets/svgs/add-files.svg?react";
import { WebToast } from "@//components/core/Toast";
import { DateTime } from "luxon";
import { useApi } from "@/hooks/useApi";
import { uploadFileToS3 } from "@/contexts/MessagesContext/utils";
import { logger } from "@/core/logger";
import { FFmpeg } from "@ffmpeg/ffmpeg";
import { fetchFile, toBlobURL } from "@ffmpeg/util";
import ffmpegCoreUrl from "@ffmpeg/core?url";
import ffmpegWasmUrl from "@ffmpeg/core/wasm?url";
import { FilesSelector } from "../FilesSelector";
import { limitedTextInput } from "@/utils/lexZap/limitedTextInput";
import { LimitedTextInputTooltip } from "@/components/LexZap/LimitedTextInputTooltip";
interface ChatInputsProps {
  applicantPhoneNumber?: string;
  openDocumentSelector: () => void;
  openImageVideoSelector: () => void;
}

export const ChatInputs = ({ applicantPhoneNumber, openDocumentSelector, openImageVideoSelector }: ChatInputsProps) => {
  const { shouldUseTemplateMessage } = useLexZapChatInfo({ applicantPhoneNumber });
  const [inputValue, setInputValue] = useState("");
  const formRef = React.useRef<HTMLFormElement>(null);
  const api = useApi();

  const [isRecording, setIsRecording] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null);
  const [audioChunks, setAudioChunks] = useState<Blob[]>([]);
  const { mutate: sendMessage, isPending: isSendingMessage } = useSendLexZapMessage();
  const [timeInterval, setTimeInterval] = useState<NodeJS.Timeout | null>(null);
  const [counter, setCounter] = useState<number>(0);
  const ffmpegRef = useRef(new FFmpeg());

  const [filesMenuAnchorEl, setFilesMenuAnchorEl] = React.useState<null | SVGSVGElement>(null);

  const openFilesMenu = (event: React.MouseEvent<SVGSVGElement>) => {
    setFilesMenuAnchorEl(event.currentTarget);
  };

  const closeFilesMenu = () => {
    setFilesMenuAnchorEl(null);
  };

  const loadFFmpeg = async () => {
    const ffmpeg = ffmpegRef.current;

    await ffmpeg.load({
      coreURL: await toBlobURL(ffmpegCoreUrl, "text/javascript"),
      wasmURL: await toBlobURL(ffmpegWasmUrl, "application/wasm"),
    });
  };

  useEffect(() => {
    if (!ffmpegRef.current.loaded) {
      loadFFmpeg().catch((err) => {
        logger.error(`[loadFFmpeg] error`, err);
      });
    }
  }, []);

  const convertWebmToOgg = async (blob: Blob, fileName: string) => {
    const ffmpeg = ffmpegRef.current;
    const fileUrl = URL.createObjectURL(blob);
    try {
      await ffmpeg.writeFile("input.webm", await fetchFile(fileUrl));
      await ffmpeg.exec(["-i", "input.webm", "-c:a", "libopus", fileName]);
      const data = await ffmpeg.readFile(fileName);
      return new Blob([data], { type: "audio/ogg; codecs=opus" });
    } catch (err) {
      logger.error(`[convertWebmToOgg] error`, err);
      return null;
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (inputValue.trim() && applicantPhoneNumber) {
      sendMessage({
        applicantPhoneNumber,
        message: {
          type: "text",
          body: inputValue,
        },
      });
      setInputValue("");
    }
  };

  const handleSendRecording = async (audio: Blob) => {
    try {
      await sendRecording(audio);
    } catch (err) {
      WebToast.error("Não foi possível enviar o áudio");
      logger.error(`[sendRecording] error`, err);
    }
  };

  const sendRecording = async (audioBlob: Blob) => {
    if (!audioBlob || !applicantPhoneNumber) {
      throw new Error("audioBlob e applicantPhoneNumber são obrigatórios");
    }

    const response = await handleUploadRecording(audioBlob);

    if (!response) {
      throw new Error("Erro ao enviar arquivo para o S3");
    }

    const { key, filename, newBlob } = response;

    const localUrl = URL.createObjectURL(newBlob);

    sendMessage({
      applicantPhoneNumber,
      message: {
        type: "audio",
        body: "",
        media: {
          filename: filename,
          url: localUrl,
          mimeType: "audio/ogg",
          key: key,
        },
      },
    });
  };

  const handleUploadRecording = async (audioBlob: Blob) => {
    if (!audioBlob || !applicantPhoneNumber) return;

    const filename = `${Date.now()}.ogg`;

    try {
      const { signedUrl, key } = await api.signChatBucketUrl({
        applicantPhoneNumber: applicantPhoneNumber,
        fileName: filename,
      });

      if (!ffmpegRef.current.loaded) {
        await loadFFmpeg();
      }

      const newBlob = await convertWebmToOgg(audioBlob, filename);

      if (!newBlob) {
        throw new Error("Erro ao converter arquivo para ogg");
      }

      await uploadFileToS3(signedUrl, newBlob);

      return { key, filename, newBlob };
    } catch (err) {
      logger.error(`[handleUploadMedia] error`, err);
      return null;
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      formRef.current?.requestSubmit();
    }
  };

  const getMicrophonePermission = async () => {
    if ("MediaRecorder" in window) {
      try {
        return await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
      } catch (err) {
        WebToast.error("É necessário permitir acesso ao microfone");
        return null;
      }
    } else {
      WebToast.error("O navegador não suporta gravação de áudio");
      return null;
    }
  };

  const startRecording = async () => {
    const stream = await getMicrophonePermission();
    if (!stream || !stream.active || isSendingMessage) return;

    setIsRecording(true);

    const recorder = new MediaRecorder(stream);

    const chunks: Blob[] = [];

    recorder.onstart = () => {
      setCounter(0);
    };

    recorder.ondataavailable = (event) => {
      chunks.push(event.data);
    };

    setAudioChunks(chunks);

    recorder.start();
    setMediaRecorder(recorder);
  };

  const stopRecording = () => {
    if (mediaRecorder && mediaRecorder.state !== "inactive") {
      mediaRecorder.stream.getTracks().forEach((track) => track.stop());
      setIsRecording(false);
      setIsPaused(false);
      setCounter(0);
      clearInterval(timeInterval!);
      mediaRecorder.stop();
    }
  };

  const stopRecordingAndSubmit = async () => {
    if (mediaRecorder && mediaRecorder.state !== "inactive") {
      mediaRecorder.stream.getTracks().forEach((track) => track.stop());
      setIsRecording(false);
      setIsPaused(false);
      setCounter(0);
      clearInterval(timeInterval!);
      mediaRecorder.stop();
      mediaRecorder.onstop = async () => {
        const newAudioBlob = new Blob(audioChunks, { type: "audio/webm" });
        await handleSendRecording(newAudioBlob);
      };
    }
  };

  const pauseRecording = () => {
    if (mediaRecorder && mediaRecorder.state === "recording") {
      mediaRecorder.pause();
      setIsPaused(true);
    }
  };

  const resumeRecording = () => {
    if (mediaRecorder && mediaRecorder.state === "paused") {
      mediaRecorder.resume();
      setIsPaused(false);
      setIsRecording(true);
    }
  };

  const deleteAudio = () => {
    stopRecording();
  };

  return (
    <Box
      component="form"
      ref={formRef}
      onSubmit={handleSubmit}
      sx={{
        backgroundColor: "grey.100",
        paddingX: 3,
        paddingY: 2,
        display: "flex",
        alignItems: "center",
        gap: 3,
        paddingRight: 11,
      }}
    >
      {/* <IconButton>
        <EmojiIcon fontSize="medium" />
      </IconButton> */}
      <Tooltip
        disableHoverListener={!shouldUseTemplateMessage}
        title="O envio de arquivos será habilitado novamente após uma resposta do seu cliente"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <FilesSelector
            open={!!filesMenuAnchorEl}
            anchorEl={filesMenuAnchorEl!}
            onClose={closeFilesMenu}
            openDocumentSelector={() => {
              openDocumentSelector();
              closeFilesMenu();
            }}
            openImageVideoSelector={() => {
              openImageVideoSelector();
              closeFilesMenu();
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            sx={{ top: "-28px !important" }}
          />
          <AddFilesIcon
            onClick={shouldUseTemplateMessage ? undefined : openFilesMenu}
            style={{
              flexShrink: 0,
              width: "24px",
              cursor: shouldUseTemplateMessage ? "default" : "pointer",
            }}
          />
        </Box>
      </Tooltip>

      {isRecording ? (
        <AudioInput
          deleteAudio={deleteAudio}
          isRecording={isRecording}
          isPaused={isPaused}
          counter={counter}
          setCounter={setCounter}
          timeInterval={timeInterval}
          setTimeInterval={setTimeInterval}
        />
      ) : (
        <TextInput
          inputValue={inputValue}
          setInputValue={setInputValue}
          handleKeyDown={handleKeyDown}
          shouldUseTemplateMessage={shouldUseTemplateMessage}
        />
      )}

      {inputValue || shouldUseTemplateMessage ? (
        <IconButton sx={{ m: -1 }} type="submit">
          <SendRoundedIcon
            sx={{
              width: "24px",
              color: "orange.light.90",
            }}
          />
        </IconButton>
      ) : (
        <AudioButtons
          isRecording={isRecording}
          isPaused={isPaused}
          startRecording={startRecording}
          resumeRecording={resumeRecording}
          pauseRecording={pauseRecording}
          deleteAudio={deleteAudio}
          sendRecording={stopRecordingAndSubmit}
        />
      )}
    </Box>
  );
};

const TextInput = ({
  inputValue,
  setInputValue,
  handleKeyDown,
  shouldUseTemplateMessage,
}: {
  inputValue: string;
  setInputValue: (value: string) => void;
  handleKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  shouldUseTemplateMessage: boolean;
}) => {
  const handleLimitedInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setInputValue(limitedTextInput(e));
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "6px",
        flex: 1,
      }}
    >
      <LimitedTextInputTooltip disabled={!shouldUseTemplateMessage}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            bgcolor: "common.white",
            borderRadius: "24px",
            paddingY: "8.5px",
            paddingX: "14px",
          }}
        >
          {shouldUseTemplateMessage && (
            <Typography variant="multiLineBody" color="text.primary" mb={0.5}>
              Não nos falamos faz algum tempo. Segue continuação da nossa conversa:
            </Typography>
          )}
          <TextField
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": {
                borderRadius: "24px",
                padding: 0,
                "& fieldset": {
                  border: "none",
                },
              },
            }}
            autoComplete="off"
            hiddenLabel
            variant="outlined"
            placeholder="Escreva algo"
            size="small"
            multiline
            value={inputValue}
            onChange={(e) => {
              if (shouldUseTemplateMessage) {
                return handleLimitedInput(e);
              }
              setInputValue(e.target.value);
            }}
            onKeyDown={handleKeyDown}
          />
        </Box>
      </LimitedTextInputTooltip>

      {shouldUseTemplateMessage && <MessageTemplateCharacterCounter inputLength={inputValue.length} />}
    </Box>
  );
};

const AudioInput = ({
  deleteAudio,
  isRecording,
  isPaused,
  counter,
  setCounter,
  timeInterval,
  setTimeInterval,
}: {
  deleteAudio: () => void;
  isRecording: boolean;
  isPaused: boolean;
  counter: number;
  setCounter: React.Dispatch<React.SetStateAction<number>>;
  timeInterval: NodeJS.Timeout | null;
  setTimeInterval: React.Dispatch<React.SetStateAction<NodeJS.Timeout | null>>;
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: "6px",
        flex: 1,
      }}
    >
      <IconButton onClick={deleteAudio}>
        <DeleteIcon
          style={{
            flexShrink: 0,
            width: "24px",
            height: "24px",
            cursor: "pointer",
          }}
        />
      </IconButton>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          bgcolor: "common.white",
          borderRadius: "24px",
          justifyContent: "center",
          justifyItems: "center",
          paddingY: "8.5px",
          paddingX: "14px",
        }}
      >
        {isRecording ? (
          <Timer
            timeInterval={timeInterval}
            setTimeInterval={setTimeInterval}
            isPaused={isPaused}
            counter={counter}
            setCounter={setCounter}
          />
        ) : (
          <Box />
        )}
        <div
          style={{
            width: "100%",
            height: "24px",
          }}
        />
      </Box>
    </Box>
  );
};

const AudioButtons = ({
  isRecording,
  isPaused,
  resumeRecording,
  pauseRecording,
  startRecording,
  sendRecording,
}: {
  isRecording: boolean;
  isPaused: boolean;
  resumeRecording: () => void;
  pauseRecording: () => void;
  startRecording: () => void;
  deleteAudio: () => void;
  sendRecording: () => void;
}) => {
  if (isRecording) {
    return (
      <>
        {isPaused ? (
          <IconButton sx={{ m: -1 }} onClick={resumeRecording}>
            <ChatMicIcon
              style={{
                flexShrink: 0,
                width: "24px",
                height: "24px",
                cursor: "pointer",
              }}
            />
          </IconButton>
        ) : (
          <IconButton sx={{ m: -1 }} onClick={pauseRecording}>
            <PauseCircleOutlineIcon
              style={{
                color: "#ff3b30",
                flexShrink: 0,
                width: "24px",
                height: "24px",
                cursor: "pointer",
              }}
            />
          </IconButton>
        )}

        <IconButton sx={{ m: -1 }} onClick={sendRecording}>
          <SendRoundedIcon
            sx={{
              width: "24px",
              color: "orange.light.90",
            }}
          />
        </IconButton>
      </>
    );
  } else {
    return (
      <IconButton sx={{ m: -1 }} onClick={startRecording}>
        <ChatMicIcon
          style={{
            flexShrink: 0,
            width: "24px",
            height: "24px",
            cursor: "pointer",
          }}
        />
      </IconButton>
    );
  }
};

const Timer = ({
  counter,
  setCounter,
  isPaused,
  timeInterval,
  setTimeInterval,
}: {
  counter: number;
  setCounter: React.Dispatch<React.SetStateAction<number>>;
  isPaused: boolean;
  timeInterval: NodeJS.Timeout | null;
  setTimeInterval: React.Dispatch<React.SetStateAction<NodeJS.Timeout | null>>;
}) => {
  useEffect(() => {
    if (isPaused && timeInterval) {
      clearInterval(timeInterval);
    }
  }, [isPaused, timeInterval]);

  useEffect(() => {
    if (!isPaused) {
      setTimeInterval(
        setInterval(() => {
          setCounter((prev) => prev + 1);
        }, 1000)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPaused]);

  return (
    <Box>
      <Typography variant="multiLineBody" color="text.primary">
        {DateTime.fromSeconds(counter).toFormat("mm:ss")}
      </Typography>
    </Box>
  );
};
