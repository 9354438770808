import React, { ReactElement } from "react";
import { tooltipClasses } from "@mui/material/Tooltip";
import { Tooltip } from "@mui/material";

export const LimitedTextInputTooltip = ({ disabled, children }: { disabled: boolean; children: ReactElement }) => {
  if (disabled) return children;
  return (
    <Tooltip
      title="Depois de 24h sem contato do cliente não são permitidos quebra de linha nem o uso de largos espaços."
      disableInteractive={disabled}
      disableHoverListener={disabled}
      disableTouchListener={disabled}
      disableFocusListener={disabled}
      arrow
      PopperProps={{
        sx: {
          [`& .${tooltipClasses.tooltip}`]: {
            maxWidth: 500,
            padding: 2,
            boxShadow: (theme) => theme.shadows[3],
            fontSize: "14px",
          },
        },
      }}
      sx={{
        flexDirection: "column",
        gap: "6px",
        alignItems: "start",
        justifyContent: "start",
      }}
    >
      {children}
    </Tooltip>
  );
};
