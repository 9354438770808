import { PaginationPayload } from "@/core/api/types";
import { LegalProceedingService } from "@/services/legalProceeding";
import { useQuery } from "@tanstack/react-query";

export const legalProceedingsQueryKey = ["legalProceedings"];

export const useLegalProceedings = ({ pagination }: { pagination: PaginationPayload }) => {
  return useQuery({
    queryKey: [...legalProceedingsQueryKey, { pagination }],
    queryFn: async () => LegalProceedingService.getLegalProceedings({ pagination }),
  });
};
