import { buildSkillStep } from "@/core/skillForms/builder";

export const createInitialPetitionSkillStep2 = buildSkillStep({
  title: "Detalhes do caso",
  description: "Preencha a lista abaixo para fornecer o contexto do seu caso à IA:",
  onboarding: {
    templateButton: "details",
  },
  fields: [
    {
      name: "client",
      type: "string",
      ui: {
        label: "Nome do cliente",
        description: "Insira o nome completo do cliente, conforme registrado nos documentos oficiais do processo:",
        placeholder:
          "Luiz Henrique Alves Costa, nome completo conforme consta no RG nº 123456789 e no CPF 123.456.789-00.",
      },
    },
    {
      name: "case_breakdown",
      type: "string",
      ui: {
        label: "Descrição dos fatos",
        description:
          "Conte a história detalhando os fatos essenciais, cronologicamente inclua: datas, locais e eventos:",
        placeholder:
          "Em 15/08/2022, no bairro Jardim Paulista, SP, houve o descumprimento contratual pela parte ré, que não entregou o imóvel na data acordada, causando prejuízos financeiros.",
      },
    },
    {
      name: "theses",
      type: "string",
      required: false,
      ui: {
        label: "Argumentos jurídicos",
        description: "Insira os argumentos jurídicos que sustentam sua petição:",
        placeholder:
          "A parte ré violou com sua responsabilidade civil de cumprir a obrigação contratual, configurando direito à rescisão contratual pela parte autora e indenização.",
      },
    },
    {
      name: "evidence_text",
      type: "string",
      required: false,
      ui: {
        label: "Adicionar provas",
        description:
          "Descreva provas que sustentem os fatos, como: documentos, imagens ou evidências relacionadas ao caso:",
        placeholder:
          "O autor dispõe de fotos e laudo técnico de vistoria datado de 05/09/2022, comprovando a não conformidade do imóvel entregue.",
      },
    },
    {
      name: "requests",
      type: "string",
      required: false,
      ui: {
        label: "Solicitar pedidos",
        description:
          "Liste os pedidos ao juiz, detalhando de forma clara as ações ou reparações que você busca obter no processo:",
        placeholder:
          "Requer a rescisão contratual, com a restituição de R$ 10.000,00 pagos, e indenização por danos materiais e morais, conforme previsto na legislação.",
      },
    },
  ],
});
