import React from "react";

import { MessageProps } from "../../types";
import { MessageCard } from "../MessageCard";

import { Typography } from "@mui/material";

export const MediaErrorMessage = ({ message }: MessageProps) => {
  return (
    <MessageCard message={message}>
      <Typography
        variant="body"
        sx={{
          whiteSpace: "normal",
        }}
      >
        Falha ao carregar arquivo{" "}
        <a href={message.media?.url} target="_blank" rel="noreferrer">
          {message.media?.filename}
        </a>
        .
      </Typography>
    </MessageCard>
  );
};
