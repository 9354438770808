import { authenticatedCopilotServer } from "@/core/api";
import { Applicant, ApplicantPayload } from "@/services/applicant/types";

export const ApplicantService = {
  async getApplicants() {
    const response = await authenticatedCopilotServer.get<Applicant[]>(`/applicants`);
    return response.data;
  },

  async createApplicant({ data }: { data: ApplicantPayload }) {
    const response = await authenticatedCopilotServer.post<{ id: string }>(`/applicants`, data);
    return response.data;
  },

  async updateApplicant({ applicantId, data }: { applicantId: string; data: ApplicantPayload }) {
    const response = await authenticatedCopilotServer.post<{ id: string }>(`/applicants/${applicantId}`, data);
    return response.data;
  },
};
