import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  TextField,
  TextFieldProps,
  Typography,
} from "@mui/material";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, useFieldArray } from "react-hook-form";
import { Add, DeleteOutlined } from "@mui/icons-material";

const oabItemSchema = z.object({
  oab: z
    .string()
    .min(6, { message: "O número de inscrição deve ter no mínimo 6 dígitos" })
    .max(7, { message: "O número de inscrição deve ter no máximo 7 dígitos" })
    .regex(/^\d+$/, { message: "O número de inscrição deve conter apenas números" }),
  uf: z.string().length(2),
});

const importOabSchema = z.object({
  oabs: z
    .array(oabItemSchema)
    .min(1, { message: "Adicione pelo menos uma OAB" })
    .max(5, { message: "Você pode adicionar no máximo 5 OABs" })
    .refine(
      (oabs) => {
        const ufs = oabs.map((oab) => oab.uf.toLowerCase());
        return new Set(ufs).size === ufs.length;
      },
      { message: "Não é permitido adicionar mais de uma OAB do mesmo estado" }
    ),
});

export type ImportOabSchemaData = z.infer<typeof importOabSchema>;

type ImportOABModalProps = {
  open: boolean;
  onClose: () => void;
  onImportLegalProceedingFromOAB: (params: ImportOabSchemaData) => void;
};

export const ImportOABModal = (props: ImportOABModalProps) => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
    trigger,
  } = useForm<ImportOabSchemaData>({
    resolver: zodResolver(importOabSchema),
    defaultValues: {
      oabs: [{ oab: "", uf: "sp" }],
    },
    mode: "onChange",
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "oabs",
  });

  const onAddOABField = async () => {
    append({ oab: "", uf: "sp" });
  };

  const onRemoveField = async (index: number) => {
    remove(index);
  };

  const onClose = () => {
    reset();
    props.onClose();
  };

  const onSubmit = (data: ImportOabSchemaData) => {
    props.onImportLegalProceedingFromOAB(data);
    onClose();
  };

  return (
    <Dialog maxWidth="xs" open={props.open} onClose={onClose}>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>
          <Typography variant="h6" component="span">
            Adicionar inscrição OAB
          </Typography>
          <Typography variant="subtitle2" component="div">
            Digite sua inscrição e escolha a sua seccional para começar a importação:
          </Typography>
        </DialogTitle>
        <DialogContent>
          {fields.map((field, index) => (
            <Box key={field.id} sx={{ display: "flex", gap: 1, mb: 2, alignItems: "baseline" }}>
              <TextField
                sx={{ mt: 2, width: "100%" }}
                label="Inscrição OAB"
                {...register(`oabs.${index}.oab`)}
                error={!!errors.oabs?.[index]?.oab}
                helperText={errors.oabs?.[index]?.oab?.message}
                FormHelperTextProps={{
                  sx: {
                    fontSize: 11,
                  },
                }}
              />
              <SelectUF
                inputProps={{
                  ...register(`oabs.${index}.uf`, {
                    onChange: () => trigger("oabs"),
                  }),
                }}
              />
              {fields.length > 1 && (
                <IconButton onClick={() => onRemoveField(index)} sx={{ p: 0 }}>
                  <DeleteOutlined sx={{ fontSize: 24 }} />
                </IconButton>
              )}
            </Box>
          ))}
          {errors.oabs && (
            <Typography variant="body2" color="error" align="center" sx={{ mt: 1 }}>
              {errors.oabs.message}
            </Typography>
          )}
          {fields.length < 5 && (
            <Button
              startIcon={<Add />}
              onClick={onAddOABField}
              sx={{ mt: 1 }}
              fullWidth
              variant="contained"
              color="secondary"
            >
              Adicionar outra inscrição OAB
            </Button>
          )}
        </DialogContent>
        <DialogActions
          sx={{
            p: 3,
          }}
        >
          <Button type="button" onClick={onClose}>
            Cancelar
          </Button>
          <Button type="submit" variant="contained" disabled={!isValid}>
            Importar
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

const ufs = [
  { value: "ac", label: "AC" },
  { value: "al", label: "AL" },
  { value: "ap", label: "AP" },
  { value: "am", label: "AM" },
  { value: "ba", label: "BA" },
  { value: "ce", label: "CE" },
  { value: "df", label: "DF" },
  { value: "es", label: "ES" },
  { value: "go", label: "GO" },
  { value: "ma", label: "MA" },
  { value: "mt", label: "MT" },
  { value: "ms", label: "MS" },
  { value: "mg", label: "MG" },
  { value: "pa", label: "PA" },
  { value: "pb", label: "PB" },
  { value: "pr", label: "PR" },
  { value: "pe", label: "PE" },
  { value: "pi", label: "PI" },
  { value: "rj", label: "RJ" },
  { value: "rn", label: "RN" },
  { value: "rs", label: "RS" },
  { value: "ro", label: "RO" },
  { value: "rr", label: "RR" },
  { value: "sc", label: "SC" },
  { value: "sp", label: "SP" },
  { value: "se", label: "SE" },
  { value: "to", label: "TO" },
];

const SelectUF = (props: TextFieldProps) => {
  return (
    <TextField sx={{ mt: 2 }} fullWidth select label="Seccional (UF)" defaultValue="sp" {...props}>
      {ufs.map((uf) => (
        <MenuItem key={uf.value} value={uf.value}>
          {uf.label}
        </MenuItem>
      ))}
    </TextField>
  );
};
