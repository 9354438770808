import { FlowMessageAction } from "@/contexts/MessagesContext";
import { SkillsPayload, UserInputDTO } from "@/contexts/WebSocketContext";
import { Paginated } from "@/core/api/types";
import { THREADS_QUERY_KEY } from "@/hooks/threads/usePaginatedThreads";
import { getThreadQueryKey } from "@/hooks/threads/useThread";
import { useApi } from "@/hooks/useApi";
import { Thread } from "@/services/thread/types";

import { InfiniteData, useMutation, useQueryClient } from "@tanstack/react-query";

export const useGenerateThreadName = () => {
  const queryClient = useQueryClient();
  const api = useApi();
  return useMutation({
    mutationKey: ["generateThreadName"],
    mutationFn: async ({
      threadId,
      data,
    }: {
      threadId: string;
      data: FlowMessageAction | SkillsPayload<UserInputDTO>;
    }) => {
      return await api.generateThreadName({ threadId, data });
    },
    onSuccess: async ({ name }, { threadId }) => {
      await queryClient.cancelQueries({ queryKey: THREADS_QUERY_KEY });
      await queryClient.cancelQueries({ queryKey: getThreadQueryKey({ threadId }) });

      queryClient.setQueryData<Thread>(getThreadQueryKey({ threadId }), (old) =>
        old
          ? {
              ...old,
              name,
            }
          : undefined
      );

      const previousThreadsTuple = queryClient.getQueriesData({ queryKey: THREADS_QUERY_KEY });

      for (const [queryKey] of previousThreadsTuple) {
        queryClient.setQueryData(queryKey, (_old?: InfiniteData<Paginated<Thread>>) => {
          const old = _old || { pageParams: [], pages: [] };

          return {
            pages: old.pages.map((page) => ({
              ...page,
              data: page.data.map((r) => {
                if (r.id === threadId) {
                  return {
                    ...r,
                    name,
                  };
                }
                return r;
              }),
            })),
            pageParams: old.pageParams,
          };
        });
      }
    },
  });
};
