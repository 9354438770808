import { env } from "@/constants/environment";
import { SkillGroupId } from "@/core/skillGroups/types";
import { SkillId } from "@/core/skills/types";
import { removeUndefinedValues } from "@/utils/removeUndefinedValues";
import { createSearchParams, generatePath } from "react-router-dom";

export const ASSISTANT_URL = env.IS_PRODUCTION
  ? "https://assistente.lexter.ai"
  : "https://assistente-staging.lexter.ai";

export const AUTHENTICATED_ROUTES_PREFIX = "/app";

export const EDITOR_ROUTES_PREFIX = `${AUTHENTICATED_ROUTES_PREFIX}/editor`;

export const ROUTE_PATHS = {
  LOGIN: "/login",
  SIGNUP: "/signup",
  SIGNUP_FAILURE: "/signup/failure",
  ACTIVATE_ACCOUNT: "/signup/activate",
  ADD_PHONE: "/app/add-phone",

  CLIENT_CONFIG: `${AUTHENTICATED_ROUTES_PREFIX}/client-config`,
  CLIENT_CONFIG_MY_DATA: `${AUTHENTICATED_ROUTES_PREFIX}/client-config/mydata`,
  CLIENT_CONFIG_MY_DATA_WHATSAPP: `${AUTHENTICATED_ROUTES_PREFIX}/client-config/mydata/whatsapp`,
  CLIENT_CONFIG_CHANGE_PASSWORD: `${AUTHENTICATED_ROUTES_PREFIX}/client-config/changepassword`,
  CLIENT_CONFIG_MY_TEAM: `${AUTHENTICATED_ROUTES_PREFIX}/myteam`,

  THREAD_HISTORY: `${EDITOR_ROUTES_PREFIX}/history`,
  CHAT: `${EDITOR_ROUTES_PREFIX}/chat`,
  THREAD: `${EDITOR_ROUTES_PREFIX}/chat/:threadId`,
  FEEDBACK: `${EDITOR_ROUTES_PREFIX}/feedback`,
  WHATSAPP_ASSISTANT: `${AUTHENTICATED_ROUTES_PREFIX}/whatsapp-assistant`,
  WHATSAPP_ASSISTANT_CHAT: `${AUTHENTICATED_ROUTES_PREFIX}/whatsapp-assistant/chat`,
  WHATSAPP_ASSISTANT_ACTIVE_CASES: `${AUTHENTICATED_ROUTES_PREFIX}/whatsapp-assistant/cases`,
  WHATSAPP_ASSISTANT_ARCHIVED_CASES: `${AUTHENTICATED_ROUTES_PREFIX}/whatsapp-assistant/cases/archived`,

  CASE_DETAILS: `${AUTHENTICATED_ROUTES_PREFIX}/cases/:caseId`,
  CASE_DOCUMENT_DETAILS: `${AUTHENTICATED_ROUTES_PREFIX}/cases/:caseId/documents/:documentId`,

  SESSION_SETTINGS: `${AUTHENTICATED_ROUTES_PREFIX}/session-settings`,
  CREDITS_HISTORY: `${AUTHENTICATED_ROUTES_PREFIX}/credits-history`,
  TERMS_OF_USE: `${AUTHENTICATED_ROUTES_PREFIX}/terms-of-use`,

  PLANS: `${AUTHENTICATED_ROUTES_PREFIX}/plans`,
  INTERNAL_PAYMENT: `${AUTHENTICATED_ROUTES_PREFIX}/payment`,
  HOME: `${AUTHENTICATED_ROUTES_PREFIX}/home`,
  REFERRAL: `${AUTHENTICATED_ROUTES_PREFIX}/referral`,
  WELCOME: `${AUTHENTICATED_ROUTES_PREFIX}/welcome`,
  ONBOARDING_QUESTIONS: `${AUTHENTICATED_ROUTES_PREFIX}/onboarding-questions`,
  CALL_WITH_SPECIALIST: `${AUTHENTICATED_ROUTES_PREFIX}/call-with-specialist`,
  LEGAL_PROCEEDINGS: `${AUTHENTICATED_ROUTES_PREFIX}/legal-proceedings`,

  WHATSAPP_USERS: `${AUTHENTICATED_ROUTES_PREFIX}/admin/whatsapp-users`,
  WHATSAPP_DLQ_EVENTS: `${AUTHENTICATED_ROUTES_PREFIX}/admin/whatsapp-dlq-events`,
  LEGAL_PROCEEDING: `${AUTHENTICATED_ROUTES_PREFIX}/legal-proceeding`,
} as const;

export const EXTERNAL_URLS = {
  PRIVACY_POLICY: "https://lexter.ai/politicadeprivacidade",
  TERMS_OF_USE: "https://lexter.ai/termosdeuso",
  HELP_CENTER_URL: "https://ajuda.lexter.ai/",
  CONTACT: "https://lexter.ai/contato",
  CREDITS_INFO: "https://ajuda.lexter.ai/o-que-sao-e-como-funcionam-os-creditos",
  WHATSAPP_URL: "https://wa.me/5511914150170",
} as const;

export const getCaseDocumentPath = ({ documentId, caseId }: { documentId: string; caseId: string }) => {
  return generatePath(ROUTE_PATHS.CASE_DOCUMENT_DETAILS, {
    documentId: documentId.toString(),
    caseId,
  });
};

export const getCasePath = ({ caseId }: { caseId: string }) => {
  return generatePath(ROUTE_PATHS.CASE_DETAILS, { caseId });
};

export const getSkillPath = ({
  skillId,
  threadId,
  customTitle,
  isOnboarding,
}: {
  skillId: SkillId;
  threadId?: string;
  customTitle?: string;
  isOnboarding?: boolean;
}) => {
  const query = createSearchParams(removeUndefinedValues({ skill: skillId, skillTitle: customTitle, isOnboarding }));
  return threadId ? `${generatePath(ROUTE_PATHS.THREAD, { threadId })}?${query}` : `${ROUTE_PATHS.CHAT}?${query}`;
};

export const getSkillGroupPath = ({ skillGroupId, threadId }: { skillGroupId: SkillGroupId; threadId?: string }) => {
  return threadId
    ? `${generatePath(ROUTE_PATHS.THREAD, { threadId })}?skillGroup=${skillGroupId}`
    : `${ROUTE_PATHS.CHAT}?skillGroup=${skillGroupId}`;
};
