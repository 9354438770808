import { DialogModal } from "@/components/DialogModal";
import { ActionId, useMessagesContext } from "@/contexts/MessagesContext";
import { useSocket, WebsocketMessageType } from "@/contexts/WebSocketContext";
import { useEditor } from "@/hooks/useEditor";
import { Toast, WebToast } from "@//components/core/Toast";
import { ContentPasteOutlined } from "@mui/icons-material";
import { Box, Button, CardContent, CircularProgress, IconButton, TextField, Tooltip, Typography } from "@mui/material";
import * as React from "react";
import * as logger from "../../core/logger";
import { useFeatureFlags } from "@/hooks/useFeatureFlags";
import { CustomSkillFormProps } from "@//components/Chat/Chat";
import { InternalPageStructure } from "@/components/core/InternalPageStructure";

export const EditDocumentSection = ({ skill, goBack, startSkillProcess }: CustomSkillFormProps) => {
  const { inputContext, inputContextFormatted, clearContext, cancelOngoingExecution } = useMessagesContext();
  const [error, setError] = React.useState<string | null>("");
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [editInstructions, setEditInstructions] = React.useState<string>("");
  const [editedResponse, setEditedResponse] = React.useState<string>("");
  const [editedResponseFormatted, setEditedResponseFormatted] = React.useState<string>("");
  const [finalInputContext, setFinalInputContext] = React.useState<string>("");
  const [requestId, setRequestId] = React.useState<string>("");
  const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);
  const [openNewEditionDialog, setOpenNewEditionDialog] = React.useState(false);
  const { editor } = useEditor();
  const { formattedEditionEnabled } = useFeatureFlags();

  const MAX_CHARACTERS = 10000;
  const ERROR_MESSAGE = "O trecho selecionado é muito longo. Selecione no máximo 10 mil caracteres.";

  const isTextLengthValid = (text: string) => text.length <= MAX_CHARACTERS;

  const startNewEdition = () => {
    setIsLoading(false);
    setOpenNewEditionDialog(false);
    clearContext();
    setFinalInputContext("");
    setEditInstructions("");
    setEditedResponse("");
    setEditedResponseFormatted("");
    setRequestId("");
  };

  useSocket({
    onMessageReceived: ({ type, data }) => {
      if (
        type === WebsocketMessageType.SKILLS &&
        data.skillId == ActionId.EDIT_DOCUMENT &&
        data.requestId &&
        data.requestId === requestId
      ) {
        setIsLoading(false);
        if (!data.success) {
          WebToast.error("A edição falhou. Tente novamente.");
        } else {
          setEditedResponse(data.payload.suggested_text);
          if (data.payload.suggested_text_html) {
            setEditedResponseFormatted(data.payload.suggested_text_html);
          }
        }
      }
    },
  });

  const onSubmit = React.useCallback(async () => {
    if (!editInstructions.length) {
      setError("As instruções de edição não podem estar vazias.");
      return;
    }

    if (!isTextLengthValid(inputContext)) {
      setError(ERROR_MESSAGE);
      return;
    }

    setFinalInputContext(inputContext);
    logger.info("Submitting edit document message");
    setIsLoading(true);

    try {
      const documentBody = editor ? await editor.getBody() : "";

      if ("getPayload" in skill && skill.id === "edit_document") {
        const payload = skill.getPayload({
          to_edit_text: formattedEditionEnabled ? inputContextFormatted : inputContext,
          user_instructions: editInstructions,
          document_text: documentBody,
        });
        startSkillProcess(payload);
        setRequestId(payload.requestId);
      } else {
        throw new Error("Skill inválida");
      }
    } catch (e) {
      WebToast.error("A edição falhou");
      setIsLoading(false);
      setRequestId("");
      setFinalInputContext("");
    }
  }, [
    editInstructions,
    inputContext,
    inputContextFormatted,
    editor,
    skill,
    formattedEditionEnabled,
    startSkillProcess,
  ]);

  const handleCopy = async () => {
    const successMessage = "Texto copiado para a área de transferência.";
    const errorMessage = "Falha ao copiar texto. Por favor, tente copiar manualmente.";

    if (navigator.clipboard && navigator.clipboard.writeText) {
      try {
        await navigator.clipboard.writeText(editedResponse);
        Toast.success(successMessage);
        return;
      } catch (err) {
        if (err instanceof Error) {
          logger.error(errorMessage, err);
        }
      }
    }
  };

  const handleGoBack = () => {
    if (isLoading) {
      setOpenConfirmDialog(true);
    } else {
      goBack();
    }
  };

  const handleConfirmLeave = () => {
    if (isLoading) {
      handleCancelOngoingSkillExecution();
    }
    setOpenConfirmDialog(false);
    setOpenNewEditionDialog(false);
    goBack();
  };

  const handleCancelOngoingSkillExecution = async () => {
    setIsLoading(true);
    await cancelOngoingExecution(requestId);
  };

  const handleInsertEditedResponse = async () => {
    if (!editedResponse) {
      return;
    }
    const editedResponseText = editedResponseFormatted ?? editedResponse;
    const response = await editor?.replaceHtmlSelection(editedResponseText);
    if (response && response.errorMessage) {
      Toast.error(response.errorMessage);
    }
    Toast.success("Texto inserido com sucesso.");
  };

  const handleCompleteEdition = async () => {
    setOpenNewEditionDialog(true);
  };

  const modalTitle = isLoading ? "Deseja cancelar a ação de edição?" : "Deseja sair da ação de edição?";

  const modalDescription = isLoading
    ? "A geração do novo texto será cancelada e seus créditos serão retornados."
    : "Caso ainda não tenha inserido o novo texto em seu documento, ele será perdido.";

  const modalConfirmButtonText = isLoading ? "Cancelar" : "Sair";

  const isSubmittingDisabled =
    isLoading || !inputContext.length || !isTextLengthValid(inputContext) || !editInstructions.length;

  return (
    <>
      <InternalPageStructure
        goBack={handleGoBack}
        goBackButtonText="Voltar"
        onSubmit={onSubmit}
        error={error}
        dismissError={() => setError(null)}
        submitButtonText="Gerar nova versão"
        submitButtonDisabled={isSubmittingDisabled}
        showSubmitButton={false}
      >
        <Box>
          <Typography variant="preTitle" color={"common.black"} sx={{ textWrap: "wrap" }}>
            Editar texto com inteligência artificial
          </Typography>
        </Box>
        <Box sx={{ mt: 4 }}>
          <Typography variant="preTitle" sx={{ textWrap: "wrap" }}>
            Selecione, no corpo do texto, o trecho a ser editado
          </Typography>
          <Box
            sx={{
              backgroundColor: "common.lightShade",
              mt: 3,
              p: 1,
              borderRadius: 1,
              minHeight: "38px",
              maxHeight: "150px",
              flexGrow: 1,
              overflowY: "auto",
              px: 2,
            }}
          >
            <Typography
              variant="multiLineBody"
              color={!isTextLengthValid(inputContext) ? "error.main" : "text.secondary"}
            >
              {finalInputContext.length
                ? finalInputContext
                : !isTextLengthValid(inputContext)
                  ? ERROR_MESSAGE
                  : inputContext}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ mt: 4 }}>
          <Typography variant="preTitle" sx={{ textWrap: "wrap" }}>
            Digite as instruções desejadas para a edição do trecho:
          </Typography>
          <Box sx={{ mt: 3 }}>
            <TextField
              sx={{ width: "100%" }}
              variant="outlined"
              value={editInstructions}
              onChange={(e) => setEditInstructions(e.target.value)}
              placeholder="Solicite alterações, como inserir novas informações ou reescrever o trecho selecionado."
              multiline
              disabled={isLoading}
            />
          </Box>
        </Box>
        {editedResponse && (
          <Box sx={{ mt: 4 }}>
            <Typography variant="preTitle" sx={{ textWrap: "wrap" }}>
              Nova versão:
            </Typography>
            <Box
              sx={{
                mt: 3,
                border: "1px solid",
                borderColor: "common.mediumShade",
                borderRadius: 1,
              }}
            >
              <CardContent>
                <Typography variant="multiLineBody" color={"text.secondary"}>
                  {editedResponse}
                </Typography>
                <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 1 }}>
                  <Tooltip title="Copiar conteúdo">
                    <IconButton onClick={handleCopy} sx={{ color: "common.mediumShade" }} size="small">
                      <ContentPasteOutlined fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Box>
              </CardContent>
            </Box>
          </Box>
        )}
        <Box sx={{ display: "flex", justifyContent: "center", mt: 6 }}>
          {editedResponse ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                gap: 2,
                width: "100%",
                flexWrap: "wrap-reverse",
              }}
            >
              <Button
                variant="outlined"
                onClick={handleCompleteEdition}
                sx={{
                  flex: 1,
                  minWidth: "212px",
                }}
              >
                Concluir Edição
              </Button>
              <Button
                variant="contained"
                onClick={handleInsertEditedResponse}
                sx={{
                  flex: 1,
                  minWidth: "212px",
                }}
              >
                Inserir novo trecho
              </Button>
            </Box>
          ) : (
            <Button
              variant="contained"
              onClick={onSubmit}
              disabled={isSubmittingDisabled}
              sx={{ width: "50%", minWidth: "242px" }}
            >
              Gerar nova versão
              {isLoading && <CircularProgress size={16} sx={{ ml: 2, color: "common.shade" }} />}
            </Button>
          )}
        </Box>
      </InternalPageStructure>
      <DialogModal
        open={openConfirmDialog}
        title={modalTitle}
        description={modalDescription}
        descriptionTextAlign="left"
        buttons={[
          {
            label: modalConfirmButtonText,
            onClick: handleConfirmLeave,
          },
          {
            label: "Continuar",
            onClick: () => setOpenConfirmDialog(false),
            variant: "text",
            color: "primary",
            sx: {
              color: "text.primary",
              textDecoration: "underline",
            },
          },
        ]}
        variant="error"
      />
      <DialogModal
        open={openNewEditionDialog}
        title="Gostaria de editar outro trecho?"
        description="Gostaria de começar a edição de um novo trecho de texto?"
        descriptionTextAlign="left"
        buttons={[
          {
            label: "Sim",
            onClick: startNewEdition,
          },
          {
            label: "Não",
            onClick: handleConfirmLeave,
            variant: "text",
            color: "primary",
            sx: {
              color: "text.primary",
              textDecoration: "underline",
            },
          },
        ]}
        variant="primary"
      />
    </>
  );
};
