import { useCompanyTeam } from "../useCompanyTeam";
import { CompanyMember } from "../types";

interface UseCompanyMemberProps {
  memberId?: string;
}

interface UseLexZapCaseReturn {
  data?: CompanyMember;
  isLoading: boolean;
  isError: boolean;
}

export const useCompanyMember = ({ memberId }: UseCompanyMemberProps): UseLexZapCaseReturn => {
  const { data: companyTeam, isLoading, isError } = useCompanyTeam();

  return {
    data: companyTeam?.find((item) => item.userId === memberId),
    isLoading,
    isError,
  };
};
