import React from "react";
import { Add, FilterList } from "@mui/icons-material";
import { Box, CircularProgress, LinearProgress, Typography } from "@mui/material";
import {
  GridToolbarQuickFilter,
  GridToolbarFilterButton,
  GridColumnMenuProps,
  GridColumnMenu,
  GridColumnHeaders,
  GridColumnHeadersProps,
} from "@mui/x-data-grid";
import { Button } from "@/components/Button";
import WorkCollaborationSVG from "@/assets/svgs/work-collaboration.svg?react";
import { ImportOABModal, ImportOabSchemaData } from "../ImportOABModal";
import { WebToast } from "@//components/core/Toast";
import { logger } from "@/core/logger";

export const CustomToolbar = ({ disabled, onSearch }: { disabled: boolean; onSearch: (text: string) => void }) => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        py: 1,
      }}
    >
      <GridToolbarQuickFilter
        sx={{
          flexGrow: 2,
          maxWidth: "500px",
        }}
        placeholder="Pesquisar por número, tribunal, juizado"
        fullWidth
        variant="outlined"
        label="Pesquisar"
        disabled={disabled}
        quickFilterParser={(searchText: string) => {
          onSearch(searchText);
          return [searchText];
        }}
        debounceMs={500}
      />
      <GridToolbarFilterButton
        slotProps={{
          button: {
            startIcon: null,
            endIcon: <FilterList />,
            size: "small",
            disabled: disabled,
          },
        }}
      />
    </Box>
  );
};

export type CustomColumnHeadersProps = {
  trackingToggle?: {
    enabled: boolean;
    show: boolean;
  };
  onEnableTracking?: () => Promise<void>;
} & GridColumnHeadersProps;

export const CustomColumnHeaders = React.forwardRef<HTMLDivElement, CustomColumnHeadersProps>(
  function CustomColumnHeaders(props, ref) {
    const [trackingEnabled, setTrackingEnabled] = React.useState(props.trackingToggle?.enabled ?? false);

    if (props.hidden) {
      return null;
    }

    const onEnableTracking = async () => {
      try {
        setTrackingEnabled(true);
        await props.onEnableTracking?.();
      } catch (err) {
        logger.error("onEnableTracking", err);
        WebToast.error("Erro ao ativar monitoramento. Tente novamente.");
        setTrackingEnabled(false);
      }
    };
    return (
      <>
        <GridColumnHeaders ref={ref} {...props} />
        {props.trackingToggle?.show && (
          <Box
            sx={{
              display: "flex",
              gap: 2,
              alignItems: "center",
              bgcolor: "grey.50",
              justifyContent: "end",
              p: 1,
            }}
          >
            <Button disabled={trackingEnabled} onClick={onEnableTracking}>
              Ativar monitoramento
            </Button>
            <Button disabled>Descartar da importação</Button>
          </Box>
        )}
      </>
    );
  }
);

export const CustomColumnMenu = (props: GridColumnMenuProps) => {
  return (
    <GridColumnMenu
      {...props}
      slots={{
        columnMenuColumnsItem: null,
      }}
    />
  );
};

export type CustomNoRowsOverlayProps = {
  onImportLegalProceedingFromOAB: (params: ImportOabSchemaData) => void;
};

export const CustomNoRowsOverlay = (props: CustomNoRowsOverlayProps) => {
  const [openImportDialog, setOpenImportDialog] = React.useState(false);

  const handleOpenImportDialog = () => {
    setOpenImportDialog(true);
  };

  const handleCloseImportDialog = () => {
    setOpenImportDialog(false);
  };

  const onImportLegalProceedingFromOAB = (params: ImportOabSchemaData) => {
    props.onImportLegalProceedingFromOAB(params);
    handleCloseImportDialog();
  };

  return (
    <>
      <ImportOABModal
        open={openImportDialog}
        onClose={handleCloseImportDialog}
        onImportLegalProceedingFromOAB={onImportLegalProceedingFromOAB}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 3,
          mt: 6,
        }}
      >
        <WorkCollaborationSVG />
        <Typography variant="body1">Você ainda não tem casos sendo monitorados</Typography>
        <Button
          sx={{
            fontWeight: 600,
          }}
          startIcon={Add}
          onClick={handleOpenImportDialog}
        >
          Importar casos via OAB
        </Button>
      </Box>
    </>
  );
};

export const CustomLoadingOverlay = () => {
  return (
    <Box height="100%" bgcolor="white">
      <LinearProgress />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 2,
          height: "100%",
        }}
      >
        <CircularProgress size="16px" color="primary" />
        <Typography variant="body1" component="span">
          Procurando processos vinculados as suas inscrições OAB
        </Typography>
      </Box>
    </Box>
  );
};
