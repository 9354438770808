import { Box, IconButton, Typography, useTheme } from "@mui/material";
import React, { useRef, useState } from "react";

import { Pause as PauseIcon, PlayArrowRounded as PlayIcon } from "@mui/icons-material";

import { useWavesurfer } from "@wavesurfer/react";
import { MessageProps } from "../../types";
import { MessageCard } from "../MessageCard";
import { AudioTranscription } from "@/components/AudioTranscription";

export const AudioMessage = ({ message }: MessageProps) => {
  const audioContainerRef = useRef(null);
  const theme = useTheme();
  const { wavesurfer, isPlaying } = useWavesurfer({
    container: audioContainerRef,
    height: "auto",
    waveColor: theme.palette.common.lightWarm,
    progressColor: theme.palette.orange?.light[90],
    url: message.media?.url,
    barWidth: 3,
    barGap: 3,
    barRadius: 2,
  });

  const [transcriptionOpen, setTranscriptionOpen] = useState<string | null>(null);

  return (
    <MessageCard
      message={message}
      bottomContent={
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            paddingTop: 0.5,
          }}
        >
          <Typography
            variant="bold"
            sx={{
              color: "orange.main",
              fontSize: "12px",
              cursor: "pointer",
            }}
            onClick={() => {
              setTranscriptionOpen(message.id);
            }}
          >
            Acessar transcrição
          </Typography>
          {message?.transcription && (
            <AudioTranscription
              transcription={message?.transcription}
              isOpen={transcriptionOpen === message.id}
              onClose={() => {
                setTranscriptionOpen(null);
              }}
            />
          )}
        </Box>
      }
    >
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "min-content minmax(0, 1fr)",
          alignItems: "center",
          gap: 2,
        }}
      >
        <IconButton
          sx={{
            m: -1,
          }}
          onClick={() => wavesurfer?.playPause()}
        >
          {isPlaying ? (
            <PauseIcon fontSize="medium" sx={{ color: "orange.light.90" }} />
          ) : (
            <PlayIcon fontSize="medium" sx={{ color: "orange.light.90" }} />
          )}
        </IconButton>

        <Box
          sx={{
            width: "360px",
            maxWidth: "100%",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "32px",
            }}
            ref={audioContainerRef}
          />
        </Box>
      </Box>
    </MessageCard>
  );
};
