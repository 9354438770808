export enum SignUpSteps {
  BASIC_INFO,
  PASSWORD,
}

export interface SignUpData {
  name?: string;
  email?: string;
  phone?: string;
  password?: string;
}

export interface SignUpContextData {
  steps: SignUpSteps[];
  currentStep: number;
  nextStep: () => void;
  previousStep: () => void;
  changeStep: (step: number) => void;
  data: SignUpData;
  setData: (data: SignUpData) => void;
  submitData: (data: SignUpData) => void;
  isLoading: boolean;
  invitationEmail?: string;
}
