import { useApi } from "@/hooks/useApi";
import { useQuery } from "@tanstack/react-query";

export const lexZapCompaniesQueryKey = ["lexZapUsers"];

export const useLexZapCompanies = () => {
  const { getLexZapCompanies } = useApi();

  return useQuery({
    queryKey: lexZapCompaniesQueryKey,
    queryFn: getLexZapCompanies,
  });
};
