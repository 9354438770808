import { buildSkillStep } from "@/core/skillForms/builder";

export const hearingsScriptSkillStep1 = buildSkillStep({
  title: "Dados do caso",
  description:
    "O Assistente Lexter pode te ajudar a elaborar questões para preparar seu cliente ou suas testemunhas durante a audiência judicial. Para começar, complete com os dados do caso.",
  fields: [
    {
      name: "client",
      type: "string",
      ui: {
        label: "Qual é o nome do cliente?",
        placeholder: "Exemplo: João da Silva. Se tiver mais de um nome, separe com vírgulas.",
      },
    },
    {
      name: "witness",
      type: "string",
      ui: {
        label: "Qual o nome das testemunhas envolvidas?",
        placeholder: "Exemplo: Maria de Souza. Se tiver mais de um nome, separe com vírgulas.",
        widget: "textarea",
      },
    },
  ],
});
