import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApi } from "@/hooks/useApi";
import { lexZapCompaniesQueryKey } from "../useLexZapCompanies";

export const useSetupLexZapCompany = () => {
  const { setupLexZapCompany } = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: setupLexZapCompany,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: lexZapCompaniesQueryKey });
    },
  });
};
