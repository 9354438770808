import { buildSkillStep } from "@/core/skillForms/builder";

export const createContractSkillStep2 = buildSkillStep({
  title: "Identificação das partes",
  description: "Defina as partes envolvidas no contrato inserindo seus nomes, documentos, endereço, etc.",
  fields: [
    {
      name: "party_1",
      type: "string",
      ui: {
        label: "Qual é a parte contratante?",
        placeholder: "Exemplo: João da Silva. Se tiver mais de um nome, separe com vírgulas.",
      },
    },
    {
      name: "party_2",
      type: "string",
      ui: {
        label: "Qual é a parte contratada?",
        placeholder: "Exemplo: Maria de Souza. Se tiver mais de um nome, separe com vírgulas.",
      },
    },
  ],
});
