import { Box, Skeleton, SxProps, Typography } from "@mui/material";
import React, { ReactNode, useMemo } from "react";
import { DateTime } from "luxon";
import { CaseMessage } from "@/hooks/lexZap/types";
import { MessageStatus } from "@/components/LexZap/MessageStatus";
import { Sender } from "./Sender";

interface MessageCardProps {
  children: ReactNode;
  message: CaseMessage;
  sx?: SxProps;
  statusSx?: SxProps;
}

export const MessageCard = ({ children, message, sx, statusSx }: MessageCardProps) => {
  return (
    <Box
      sx={{
        maxWidth: "65%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Sender message={message} />

      <Box
        sx={{
          borderRadius: "16px",
          borderBottomRightRadius: message.fromLawyer ? 0 : "16px",
          borderBottomLeftRadius: message.fromLawyer ? "16px" : 0,
          backgroundColor: message.fromLawyer ? "#E3FBE3" : "common.white",
          width: "100%",
          paddingX: 3,
          paddingY: 2,
          alignItems: "start",
          position: "relative",
          marginBottom: !!message.reactions?.length ? 2 : 0,
          ...sx,
        }}
      >
        {children}

        <Box
          sx={{
            display: "flex",
            gap: 0.5,
            alignItems: "center",
            color: "grey.600",
            position: "absolute",
            bottom: "4px",
            right: "10px",
            ...statusSx,
          }}
        >
          <Typography
            variant="body"
            sx={{
              whiteSpace: "pre-line",
            }}
          >
            {message.timestamp && DateTime.fromMillis(message.timestamp).toFormat("HH:mm")}
          </Typography>

          <MessageStatus message={message} />
        </Box>

        {!!message.reactions?.length && (
          <Box
            sx={{
              display: "flex",
              zIndex: 2,
              alignItems: "center",
              position: "absolute",
              bottom: "8px",
              transform: "translateY(100%)",
              left: !message.fromLawyer ? "10px" : undefined,
              right: message.fromLawyer ? "10px" : undefined,
              padding: 0.5,
              borderRadius: "50%",
              backgroundColor: "common.white",
            }}
          >
            <Typography variant="body">{message.reactions[0].emoji}</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

interface MessageCardSkeletonProps {
  fromMe: boolean;
}
export const MessageCardSkeleton = ({ fromMe }: MessageCardSkeletonProps) => {
  const textWidth = useMemo(() => Math.floor(200 + Math.random() * 200), []);

  return (
    <Box
      sx={{
        borderRadius: "16px",
        borderBottomRightRadius: fromMe ? 0 : "16px",
        borderBottomLeftRadius: fromMe ? "16px" : 0,
        backgroundColor: fromMe ? "#E3FBE3" : "#FFFFFF",
        maxWidth: "65%",
        paddingX: 3,
        paddingY: 2,
        display: "grid",
        alignItems: "center",
        gridTemplateColumns: "minmax(0, 1fr) min-content",
      }}
    >
      <Skeleton
        variant="text"
        width={textWidth}
        sx={{
          maxWidth: "100%",
        }}
      />

      <Typography
        variant="body"
        sx={{
          whiteSpace: "normal",
          marginLeft: 2,
          color: "#756D6A",
        }}
      >
        <Skeleton variant="text" width={40} />
      </Typography>
    </Box>
  );
};
