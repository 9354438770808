import React from "react";
import { Card, CardContent, Typography } from "@mui/material";

interface NumberCardProps {
  title: string;
  actualValue: number;
  rangeValue?: number;
}

export function NumberCard({ title, actualValue, rangeValue }: NumberCardProps) {
  return (
    <Card
      sx={{
        bgcolor: "common.dorian",
        height: 120,
        width: "100%",
        boxShadow: "none",
        borderRadius: "8px",
        py: 0.5,
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          pb: "8px!important",
          height: "100%",
        }}
      >
        <Typography variant="body2">{title}</Typography>
        <Typography variant="h5">
          {actualValue}{" "}
          {rangeValue !== undefined && (
            <Typography variant="body1" component="span">
              de {rangeValue}
            </Typography>
          )}
        </Typography>
      </CardContent>
    </Card>
  );
}
