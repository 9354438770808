import * as React from "react";
import Box from "@mui/material/Box";

import { ROUTE_PATHS } from "@/routes/routePaths";
import { useNavigate, useSearchParams, generatePath } from "react-router-dom";
import { removeUndefinedValues } from "@/utils/removeUndefinedValues";
import { useCreateThread } from "@/hooks/threads/useCreateThread";
import { LoadingChat } from "@//components/Chat/LoadingChat";

export function CreateChat() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { mutateAsync: createThreadAsync } = useCreateThread();

  const navigateToThread = React.useCallback(
    ({ threadId }: { threadId: string }) => {
      const url = generatePath(ROUTE_PATHS.THREAD, { threadId });
      const skillId = searchParams.get("skill");
      const skillGroupId = searchParams.get("skillGroup");
      const isOnboarding = searchParams.get("isOnboarding");

      const params = new URLSearchParams(
        removeUndefinedValues({
          skill: skillId,
          skillGroup: skillGroupId,
          isOnboarding: isOnboarding,
        })
      );

      const query = params.toString();

      navigate(`${url}?${query}`, {
        replace: true,
      });
    },
    [searchParams, navigate]
  );

  React.useEffect(() => {
    void createThreadAsync({}).then(({ id }) => navigateToThread({ threadId: id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <LoadingChat />
    </Box>
  );
}
