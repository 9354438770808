import { buildSkillStep } from "../../builder";

export const createNoticeSkillStep2 = buildSkillStep({
  title: "Informações adicionais",
  description: "Caso possua documentos para incluir em sua notificação, faça o upload abaixo",
  fields: [
    {
      name: "reference_piece",
      type: "file",
      required: false,
      ui: {
        label: "Insira informações adicionais: (opcional)",
        placeholder: `Exemplo: Cite contratos ou outros documentos que possam auxiliar em uma melhor estruturação de sua notificação extrajudicial.`,
      },
    },
  ],
});
