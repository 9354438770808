import React from "react";

/**
 * Removes new lines, tabs and multiple spaces.
 */

export const limitedTextInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): string => {
  let formattedInput = e.target.value;
  if (/(\r\n|\n|\r)/gm.test(formattedInput)) {
    while (/(\r\n|\n|\r)/gm.test(formattedInput)) {
      formattedInput = formattedInput.replace(/(\r\n|\n|\r)/gm, " ");
    }
  }
  if (/(\t)/gm.test(formattedInput)) {
    while (/(\t)/gm.test(formattedInput)) {
      formattedInput = formattedInput.replace(/(\t)/gm, " ");
    }
  }
  if (/(\s){2}/gm.test(formattedInput)) {
    while (/(\s){2}/gm.test(formattedInput)) {
      formattedInput = formattedInput.replace(/(\s){2}/gm, " ");
    }
  }
  return formattedInput;
};
