import { logger } from "@/core/logger";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";

export interface HubspotFormData {
  email: string;
  hs_whatsapp_phone_number: string;
  quantos_casos_novos_voce_recebe_por_mes_: string;
  numemployees: string;
  areas_de_atuacao: string;
  tem_facebook: string;
  ddd_de_preferencia: string;
}

const HUBSPOT_API_URL = `https://api.hsforms.com/submissions/v3/integration/submit/8374664/dc236a24-b2b1-4ed9-9a45-e83c9d77f11c`;

const submitToHubspot = async (formData: HubspotFormData) => {
  const response = await axios.post(HUBSPOT_API_URL, {
    fields: [
      {
        name: "email",
        value: formData.email,
      },
      {
        name: "hs_whatsapp_phone_number",
        value: formData.hs_whatsapp_phone_number,
      },
      {
        name: "quantos_casos_novos_voce_recebe_por_mes_",
        value: formData.quantos_casos_novos_voce_recebe_por_mes_,
      },
      {
        name: "numemployees",
        value: formData.numemployees,
      },
      {
        name: "areas_de_atuacao",
        value: formData.areas_de_atuacao.toString(),
      },
      {
        name: "tem_facebook",
        value: formData.tem_facebook,
      },
      {
        name: "ddd_de_preferencia",
        value: formData.ddd_de_preferencia,
      },
    ],
    context: {
      pageUri: window.location.href,
      pageName: document.title,
    },
  });

  if (response.status !== 200) {
    throw new Error("Erro ao submeter o formulário.");
  }

  return response.data;
};

export const useHubspot = () => {
  const mutation = useMutation({
    mutationFn: submitToHubspot,
    onError: (error) => {
      logger.error("Erro ao enviar formulário:", error);
    },
  });

  return {
    submitHubspotForm: mutation.mutate,
    loading: mutation.isPending,
    error: mutation.error?.message || null,
    success: mutation.isSuccess,
    reset: mutation.reset,
  };
};
