import { SkillStepsForm } from "@/components/SkillStepsForm";
import { Message } from "@/contexts/MessagesContext";
import { usePaywallContext } from "@/contexts/PaywallContext";
import { SkillsPayload } from "@/contexts/WebSocketContext";
import { logger } from "@/core/logger";
import { useCheckCredits } from "@/hooks/credits/useCheckCredit";
import { useSkill } from "@/hooks/skills/useSkill";
import { useSkillForm } from "@/hooks/skills/useSkillForm";
import { ClarityService } from "@/services/clarity";
import { HeapService } from "@/services/heap";
import { Box, Typography } from "@mui/material";
import React from "react";
import { useLocation, useSearchParams } from "react-router-dom";

export const SkillForm = ({
  skillId,
  goBack,
  goToChat,
  sendMessage,
  startSkillProcess,
}: {
  skillId: string;
  goBack: () => void;
  goToChat: () => void;
  sendMessage: (message: Message) => void;
  startSkillProcess: (payload: SkillsPayload) => void;
}) => {
  const { state } = useLocation();
  const { data: skill } = useSkill(skillId);
  const { data: skillForm } = useSkillForm({ skillId: skill?.id });
  const { mutateAsync: checkCredit } = useCheckCredits();
  const { openPaywall } = usePaywallContext();

  const [searchParams] = useSearchParams();
  const skillTitle = searchParams.get("skillTitle");

  const onSubmit = async (data: Record<string, string>) => {
    if (!skill) return;

    try {
      const { hasCredits } = await checkCredit(skill.actionId);

      if (!hasCredits) {
        openPaywall();
        return;
      }
    } catch (error) {
      logger.error("checkCreditAvailability", error);
    }

    try {
      HeapService.track("Skill Executada", { skillId });
      ClarityService.track("skill_executed", skillId);
    } catch (e) {
      logger.debug(`Tracking error: ${JSON.stringify(e)}`);
    }
    if (skill.type === "message") {
      const message = skill.getMessage(data);
      sendMessage(message);
    } else {
      const payload = skill.getPayload(data);
      startSkillProcess(payload);
    }
    goToChat();
  };

  return skill ? (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        overflowY: "auto",
        position: "relative",
      }}
    >
      <SkillStepsForm
        skill={skill}
        skillForm={skillForm}
        skillTitle={skillTitle}
        initialData={state}
        onSubmit={onSubmit}
        goBack={goBack}
      />
    </Box>
  ) : (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
        gap: 1,
      }}
    >
      <Typography variant="subtitle">Skill não encontrada</Typography>
    </Box>
  );
};
