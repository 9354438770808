import { SkillId } from "@/core/skills/types";
import { RecordType } from "../records/types";
import { Department } from "../company/types";

export interface LexZapUser {
  companyId: string;
  userId: string;
  createdAt: number;
  notificationNumber?: string;
  department: Department;
  activityAreas?: string[];
}

export interface LexZapCompany {
  companyId: string;
  email: string;
  phoneNumber: string;
  apiKey: string;
  createdAt: number;
  isActive?: boolean;
  assistantName?: string;
  companyName?: string;
}

export enum CaseStep {
  CASE_STARTED = "CASE_STARTED",
  SUMMARY_GENERATED = "SUMMARY_GENERATED",
  AWAITING_APPLICANT_APPROVAL = "AWAITING_APPLICANT_APPROVAL",
  CASE_FAILED = "CASE_FAILED",
  LAWYER_INVOKED_BY_APPLICANT = "LAWYER_INVOKED_BY_APPLICANT",
  EXCEEDED_PROGRESS_LIMIT = "EXCEEDED_PROGRESS_LIMIT",
}

export type CaseNextStep = {
  id: string;
  completed: boolean;
  description: string;
  skillId?: SkillId;
  fileId?: number;
};

export interface Case {
  id: string;
  summary: string;
  step: CaseStep;
  status: "ACTIVE" | "ARCHIVED";
  userId: string;
  companyId: string;
  chatId?: string;
  threadId: string;
  name: string;
  lastMessage?: CaseMessage;
  applicantName: string;
  applicantPictureUrl?: string;
  applicantPhoneNumber?: string;
  partyIdentification?: string;
  facts?: string;
  legalQuestions?: string;
  recommendations?: string;
  documentationAndEvidence?: string;
  legalArea?: string;
  conclusion?: string;
  timeline: CaseTimeline[];
  applicantId: string;
  lastModifiedAt?: string;
  createdAt: string;

  // campos para os mocks
  description?: string;
  nextSteps?: Array<CaseNextStep>;
  caseFiles?: Array<{
    createdAt: number;
    name: string;
  }>;
}

export interface CaseWithChat extends Case {
  lastMessage: CaseMessage;
  chatId: string;
}

export interface CaseTimeline {
  id: number;
  createdAt: string;
  status:
    | "CASE_STARTED"
    | "SUMMARY_GENERATED"
    | "AWAITING_APPLICANT_APPROVAL"
    | "CASE_FAILED"
    | "LAWYER_INVOKED_BY_APPLICANT"
    | "MANUALLY_CREATED"
    | "EXCEEDED_PROGRESS_LIMIT";
}

export enum CaseMessageType {
  TEXT = "text",
  AUDIO = "audio",
  IMAGE = "image",
  DOCUMENT = "document",
  STICKER = "sticker",
  VIDEO = "video",
  CONTACTS = "contacts",
  LOCATION = "location",
  TEMPLATE = "template",
}

export interface CaseMessage {
  chatId: string;
  from: string;
  lawyerId?: string;
  fromLawyer: boolean;
  fromAssistant: boolean;
  id: string;
  to: string;
  applicantPhoneNumber: string;
  body: string;
  type: CaseMessageType;
  isForwarded: boolean;
  timestamp: number;
  media?: {
    filename: string;
    mimeType: string;
    url: string;
    error?: boolean;
  };
  transcription?: string;
  applicantName?: string;
  statusUpdateTimestamp?: Partial<Record<MessageStatus, number>>;
  contacts?: Array<{
    phones: Array<{
      wa_id?: string;
      phone: string;
      type?: string;
    }>;
    name: string;
  }>;
  location?: {
    latitude: number;
    longitude: number;
  };
  reactions?: Array<{
    id: string;
    from: string;
    timestamp: number;
    emoji: string;
  }>;

  isSending?: boolean;
}

export enum MessageStatus {
  Sent = "sent",
  Delivered = "delivered",
  Read = "read",
  Failed = "failed",
}

export interface CaseMessageWithMedia extends CaseMessage {
  media: {
    filename: string;
    mimeType: string;
    url: string;
    error?: boolean;
  };
}

export interface CaseMessageToSend {
  body: string;
  type: string;
  media?: {
    filename: string;
    url: string;
    mimeType: string;
    key: string;
  };
}

export enum ConnectionStatus {
  CONNECTING = "connecting",
  CONNECTED = "connected",
  DISCONNECTED = "disconnected",
}

export type CaseFileType =
  | {
      type: "RECORD";
      name: string;
      createdAt: number;
      recordType: RecordType;
      id: number;
      threadId: string;
    }
  | {
      type: "MEDIA";
      name: string;
      url: string;
      mimeType: string;
      createdAt: number;
      id: string;
    };
