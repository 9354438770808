import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CompanyService } from "@/services/company";
import { getCompanyTeamQueryKey } from "../useCompanyTeam";
import { CompanyMember } from "../types";

export const useCreateCompanyMember = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["createTeamMember"],
    mutationFn: async (data: { email: string; department: string; activityAreas?: string[] }) => {
      const { id } = await CompanyService.createTeamMember(data);
      return { id, ...data };
    },
    onSuccess: async (data) => {
      await queryClient.cancelQueries({ queryKey: getCompanyTeamQueryKey() });

      queryClient.setQueryData(getCompanyTeamQueryKey(), (old: CompanyMember[]) => [
        ...old,
        {
          userId: data.id,
          email: data.email,
          name: "Convite Pendente",
          department: data.department,
          activityAreas: data.activityAreas,
        },
      ]);
    },
  });
};
