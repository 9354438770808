import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "@/hooks/useApi";
import axios from "axios";
import { Case, CaseMessage } from "../types";
import { useSocket } from "@/contexts/WebSocketContext";
import { WhatsappUpdateType } from "@/contexts/WebSocketContext/types/whatsapp.types";
import { getCasesQueryKey } from "../useLexZapCases";
import { useImpersonation } from "@/hooks/useImpersonation";
import { AdminLexZapService } from "@/services/lexZap";

export const getMessagesQueryKey = ({ applicantPhoneNumber }: { applicantPhoneNumber?: string }) => [
  "lexZapMessages",
  applicantPhoneNumber,
];

interface UseLexZapMessagesProps {
  applicantPhoneNumber?: string;
}

export const useLexZapMessages = ({ applicantPhoneNumber }: UseLexZapMessagesProps) => {
  const queryClient = useQueryClient();
  const { getLexZapMessages } = useApi();
  const { impersonatedUser } = useImpersonation();
  useSocket({
    onWhatsAppUpdate: (update) => {
      if (impersonatedUser) {
        return;
      }

      if (update.type === WhatsappUpdateType.MESSAGE_RECEIVED || update.type === WhatsappUpdateType.MESSAGE_CHANGED) {
        const messageApplicantNumber = update.message.applicantPhoneNumber;

        const mergeNewMessage = (oldMessage: CaseMessage, newMessage: CaseMessage): CaseMessage => {
          return {
            ...oldMessage,
            ...newMessage,
            statusUpdateTimestamp: {
              ...oldMessage.statusUpdateTimestamp,
              ...newMessage.statusUpdateTimestamp,
            },
          };
        };

        queryClient.setQueryData<CaseMessage[]>(
          getMessagesQueryKey({ applicantPhoneNumber: messageApplicantNumber }),
          (oldMessages = []) => {
            const messageExists = oldMessages.some((msg) => msg.id === update.message.id);
            if (messageExists) {
              return oldMessages.map((oldMessage) =>
                oldMessage.id === update.message.id ? mergeNewMessage(oldMessage, update.message) : oldMessage
              );
            } else if (update.type === WhatsappUpdateType.MESSAGE_CHANGED) {
              return oldMessages;
            }
            return [...oldMessages, update.message].sort((a, b) => a.timestamp - b.timestamp);
          }
        );

        queryClient.setQueryData<Case[]>(getCasesQueryKey(), (oldCases = []) =>
          oldCases.map((caseItem) => {
            if (caseItem.applicantPhoneNumber === messageApplicantNumber) {
              if (update.type === WhatsappUpdateType.MESSAGE_RECEIVED) {
                return { ...caseItem, lastMessage: update.message };
              } else {
                const isLastMessage = caseItem.lastMessage?.id === update.message.id;
                if (isLastMessage) {
                  return { ...caseItem, lastMessage: mergeNewMessage(caseItem.lastMessage!, update.message) };
                }
              }
            }

            return caseItem;
          })
        );
      }
    },
  });

  return useQuery({
    queryKey: getMessagesQueryKey({ applicantPhoneNumber }),
    queryFn: async () => {
      if (!applicantPhoneNumber) {
        return Promise.resolve([]);
      }

      let messagesResult: CaseMessage[];
      if (impersonatedUser) {
        messagesResult = (
          await AdminLexZapService.getLexZapMessages({ applicantPhoneNumber, companyId: impersonatedUser.companyId })
        ).messages;
      } else {
        messagesResult = (await getLexZapMessages({ applicantPhoneNumber })).messages;
      }

      const messages = await Promise.all(
        messagesResult.map(async (message) => {
          if (!message?.media || !message.media?.url) {
            return message;
          }

          try {
            const { data } = await axios.get(message.media?.url, {
              responseType: "arraybuffer",
              headers: {
                "Content-Type": message.media.mimeType,
              },
            });

            const blob = new Blob([data], {
              type: message.media.mimeType,
            });

            const url = URL.createObjectURL(blob);

            return {
              ...message,
              media: {
                ...message.media,
                url,
              },
            };
          } catch {
            return {
              ...message,
              media: {
                ...message.media,
                error: true,
              },
            };
          }
        })
      );

      const lastMessage = messages[messages.length - 1];
      if (lastMessage) {
        queryClient.setQueryData<Case[]>(getCasesQueryKey(), (oldCases = []) =>
          oldCases.map((caseItem) =>
            caseItem.applicantPhoneNumber === applicantPhoneNumber ? { ...caseItem, lastMessage } : caseItem
          )
        );
      } else {
        queryClient.setQueryData<Case[]>(getCasesQueryKey(), (oldCases = []) =>
          oldCases.filter((caseItem) => caseItem.applicantPhoneNumber !== applicantPhoneNumber)
        );
      }

      return messages;
    },
    enabled: !!applicantPhoneNumber,
    staleTime: Infinity,
  });
};
