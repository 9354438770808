import { z } from "zod";

export const myDataInfoSchema = z.object({
  name: z
    .string()
    .min(1, { message: "Campo com preenchimento obrigatório." })
    .max(200, { message: "Máximo de 200 caracteres." }),
  email: z
    .string()
    .min(1, { message: "Campo com preenchimento obrigatório." })
    .max(200, { message: "Máximo de 200 caracteres." })
    .email({ message: "E-mail inválido." }),
});

export const myDataInfoSchemaWhatsapp = z.object({
  name: z
    .string()
    .min(1, { message: "Campo com preenchimento obrigatório." })
    .max(200, { message: "Máximo de 200 caracteres." }),
  email: z
    .string()
    .min(1, { message: "Campo com preenchimento obrigatório." })
    .max(200, { message: "Máximo de 200 caracteres." })
    .email({ message: "E-mail inválido." }),
  department: z.enum(["ADMINISTRATIVE", "LEGAL", "FINANCIAL"]),
  activityAreas: z.optional(z.array(z.string())),
  notificationNumber: z.string().min(1, { message: "Campo com preenchimento obrigatório." }),
});

export type MyDataInfoSchema = z.infer<typeof myDataInfoSchema>;
export type MyDataInfoSchemaWhatsapp = z.infer<typeof myDataInfoSchemaWhatsapp>;
