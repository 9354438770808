import { buildSkillStep } from "@/core/skillForms/builder";
import { outsideOfficeClient } from "@/utils/outsideOfficeClient";

export const summarizeDocumentSkillStep1 = buildSkillStep({
  description: outsideOfficeClient()
    ? "Resuma peças e decisões judiciais com poucos cliques e tenha as informações necessárias em minutos."
    : "Resuma peças e decisões judiciais. Ao finalizar o resumo, você receberá uma mensagem com o botão para abri-lo como um novo documento.",
  fields: [
    {
      name: "procedural_document_file",
      overrideField: "procedural_document",
      type: "file",
      required: true,
      ui: {
        label: "Selecione o arquivo a ser resumido",
      },
    },
  ],
  submitButtonText: "Resumir Documento",
});
