import { useLexZapCases } from "../useLexZapCases";
import { Case } from "../types";

interface UseLexZapCaseProps {
  caseId?: string;
}

interface UseLexZapCaseReturn {
  caseData?: Case;
  isLoading: boolean;
  isError: boolean;
}

export const useLexZapCase = ({ caseId }: UseLexZapCaseProps): UseLexZapCaseReturn => {
  const { data: cases, isLoading, isError } = useLexZapCases();

  return {
    caseData: cases?.find((caseItem) => caseItem.id === caseId),
    isLoading,
    isError,
  };
};
