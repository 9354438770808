import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApi } from "@/hooks/useApi";
import { lexZapCompaniesQueryKey } from "../useLexZapCompanies";

export const useDeleteLexZapCompany = () => {
  const { deleteLexZapCompany } = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteLexZapCompany,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: lexZapCompaniesQueryKey });
    },
  });
};
