import LogoGoogleSvg from "@/assets/svgs/logo-google.svg?react";
import { Button, ButtonProps } from "@/components/Button";
import React from "react";
import { LoginConfig, SignUpSource } from "@/hooks/useApi/types";
import { useAuthContext } from "@/contexts/AuthContext";
import * as logger from "../../core/logger";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { outsideOfficeClient } from "@/utils/outsideOfficeClient";
import { getSignUpUtm } from "@/utils/getSignUpUtm";
import { getGclid } from "@/utils/getGclid";
import { User } from "firebase/auth";
import { useLinkedinAds } from "@/hooks/useLinkedinAds";
import { LexterCopilotAuthService } from "@/services/lexterCopilotAuth";
import { getParam } from "@/utils/getParams";
import { ROUTE_PATHS } from "@/routes/routePaths";
import { useLocation, useNavigate } from "react-router-dom";
import { useFeatureFlags } from "@/hooks/useFeatureFlags";
import { usePlatform } from "@/hooks/usePlatform";
import { isWordDesktop, isWordWeb } from "@/utils/wordHelpers";

interface GoogleButtonProps extends ButtonProps {
  setError: (value: string) => void;
  isSomethingLoading?: boolean;
}

export const GoogleButton = ({ children, setError, isSomethingLoading, ...rest }: GoogleButtonProps) => {
  const { loginWithGoogle, setupFirebaseUser } = useAuthContext();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [isLoadingGoogle, setIsLoadingGoogle] = React.useState<boolean>(false);
  const { loadLinkedinTag } = useLinkedinAds();
  const { phoneInSignUpWithGoogleEnabled, showOnboarding } = useFeatureFlags();
  const navigate = useNavigate();
  const { state } = useLocation();
  const plataform = usePlatform();

  const onSubmitGoogle = React.useCallback(async () => {
    try {
      if (isSomethingLoading) {
        return;
      }

      logger.info("Login with Google");
      setIsLoadingGoogle(true);

      const response = await loginWithGoogle();

      if (!response.success && !response.needSignUp) {
        return setError("Erro ao tentar fazer login com o Google. Tente novamente.");
      }

      if (response.needSignUp && response.needSignUp.user) {
        return onSignUpWithGoogle(response.needSignUp.user);
      }
    } catch (error) {
      logger.error(`SubmitGoogle: ${error}`);
      setError("Algo deu errado. Tente Novamente ou entre em contato através do e-mail meajuda@lexter.ai");
      setIsLoadingGoogle(false);
    }
  }, [executeRecaptcha]);

  const onSignUpWithGoogle = React.useCallback(
    async (user: User) => {
      if (user.displayName && user.email) {
        let source: SignUpSource;
        if (outsideOfficeClient()) {
          source = SignUpSource.COPILOT_WEB;
        } else if (isWordWeb()) {
          source = SignUpSource.COPILOT_WORD_WEB;
        } else {
          source = SignUpSource.COPILOT_WORD;
        }

        const recaptchaToken = await executeRecaptcha!();

        await LexterCopilotAuthService.signup({
          name: user.displayName,
          email: user.email,
          loginConfig: [LoginConfig.GOOGLE, LoginConfig.EMAIL_AND_PASSWORD],
          externalId: user.uid,
          signUpUtm: getSignUpUtm(),
          referralCode: getParam("referralCode"),
          signUpProvider: "GOOGLE",
          gclid: getGclid(),
          source,
          recaptchaToken,
          firstDevice: plataform == "OFFICE" ? "DESKTOP" : plataform,
        });

        loadLinkedinTag();

        await setupFirebaseUser(user, "GOOGLE");

        if (outsideOfficeClient()) {
          if (phoneInSignUpWithGoogleEnabled) {
            return navigate(ROUTE_PATHS.ADD_PHONE, {
              replace: true,
            });
          }

          if (state?.redirectTo) {
            return navigate(state?.redirectTo);
          }

          if (showOnboarding) {
            return navigate(ROUTE_PATHS.ONBOARDING_QUESTIONS, {
              replace: true,
            });
          }

          //return navigate(ROUTE_PATHS.HOME);
        }

        return navigate(ROUTE_PATHS.CHAT);
      } else {
        setError("Algo deu errado. Tente Novamente ou entre em contato através do e-mail meajuda@lexter.ai");
      }
    },
    [executeRecaptcha]
  );

  const googleButtonCommonStyle = {
    width: "220px",
    borderRadius: "100px",
    border: "solid 1px #747775",
    padding: "6px 12px 6px 12px",
    alignSelf: "center",
  };

  if (isWordDesktop()) {
    return null;
  }

  return (
    <Button
      variant="contained"
      fullWidth
      isLoading={isLoadingGoogle}
      {...rest}
      onClick={onSubmitGoogle}
      bgcolor="#FFFFFF"
      disabled={rest.isLoading}
      loadingColor="#d7d7d7"
      sx={{
        color: "#16222C",
        textTransform: "none",
        fontWeight: "500",
        gap: "10px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "white",
        "&:focus": {
          outline: "2px solid #4285f454",
        },
        "&:hover": {
          border: "solid 1px #C1D5F6",
          backgroundColor: "white",
        },
        ...googleButtonCommonStyle,
        ...rest.sx,
      }}
      loaderStyle={googleButtonCommonStyle}
    >
      <LogoGoogleSvg fontSize="medium" height={24} />
      {children}
    </Button>
  );
};
