import { SkillForm } from "@/core/skillForms/types";
import { createInitialPetitionSkillStep2 } from "../step2";
import { createInitialPetitionSkillStep1 } from "../step1";
import { createInitialPetitionSkillStep3 } from "./step3";

export const createInitialPetitionV2SkillForm: SkillForm = {
  skillId: "create_initial_petition_v2",
  title: "Escrever petição inicial",
  steps: [createInitialPetitionSkillStep1, createInitialPetitionSkillStep2, createInitialPetitionSkillStep3],
  lastStepSubmitButtonText: "Criar Petição Inicial",
};
