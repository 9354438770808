import { useQuery } from "@tanstack/react-query";
import { CaseService } from "@/services/case";

export const useCaseNextSteps = ({ caseId }: { caseId?: string }) => {
  return useQuery({
    queryKey: ["case", caseId, "nextSteps"],
    queryFn: async () => CaseService.getCaseNextSteps(caseId!),
    enabled: !!caseId,
  });
};
