import React from "react";

import { MessageProps } from "../../types";
import { MessageCard } from "../MessageCard";

import { Box, Typography } from "@mui/material";

export const ContactsMessage = ({ message }: MessageProps) => {
  return (
    <MessageCard message={message}>
      <Typography
        variant="body"
        sx={{
          whiteSpace: "normal",
        }}
      >
        Contatos:
        <Box
          sx={{
            marginY: 2,
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          {message.contacts?.map((contact, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
              }}
            >
              <strong>{contact.name}</strong>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 0.5,
                }}
              >
                {contact.phones.map((phone, index) => (
                  <Box key={index}>{phone.phone}</Box>
                ))}
              </Box>
            </Box>
          ))}
        </Box>
      </Typography>
    </MessageCard>
  );
};
