import { SkillId } from "@/core/skills/types";
import * as skillFormImplementations from "./implementations";
import { SkillForm } from "./types";

const skillFormsMap = new Map<SkillId, SkillForm>(
  Object.values(skillFormImplementations).map((skillForm) => [skillForm.skillId, skillForm])
);

export const SkillForms = {
  async getSkillForm(id: SkillId) {
    return skillFormsMap.get(id);
  },
};
