import { Box } from "@mui/material";
import React, { useCallback } from "react";

import { CaseSummary } from "./components/CaseSummary";
import { CaseFiles } from "./components/CaseFiles";
import { CaseNextSteps } from "./components/CaseNextSteps";
import { CaseHeader } from "./components/CaseHeader";
import { CaseFooter } from "./components/CaseFooter";

import { CaseDetailsChat } from "./components/CaseDetailsChat";
import { Case, CaseNextStep } from "@/hooks/lexZap/types";
import { useNavigate } from "react-router-dom";
import { SkillId } from "@/core/skills/types";
import { removeUndefinedValues } from "@/utils/removeUndefinedValues";
import { getCaseDocumentPath, getSkillPath } from "@/routes/routePaths";
import { logger } from "@/core/logger";

type CaseDetailsProps = {
  caseId: string;
  caseData?: Case;
  showNextSteps: boolean;
  loadingCase: boolean;
  onDeleteCase: () => void;
  onArchiveCase: () => void;
  onUnarchiveCase: () => void;
};

const mapCaseToSkillFields = ({ caseData, skillId }: { caseData: Case; skillId: SkillId }) => {
  switch (skillId) {
    case "fee_agreement":
    case "create_initial_petition":
      return {
        client: caseData.applicantName,
        case_breakdown: caseData.facts,
      };
    default:
      return {};
  }
};

export const CaseDetails = ({
  caseId,
  caseData,
  showNextSteps,
  loadingCase,
  onDeleteCase,
  onArchiveCase,
  onUnarchiveCase,
}: CaseDetailsProps) => {
  const navigate = useNavigate();

  const handleStepClick = useCallback(
    (step: CaseNextStep) => {
      const threadId = caseData?.threadId;
      const { skillId, fileId } = step;

      if (!threadId) {
        logger.warn("handleStepClick - step without threadId");
        return;
      }

      if (fileId) {
        return navigate(getCaseDocumentPath({ caseId: caseData.id, documentId: fileId.toString() }));
      } else if (skillId) {
        const url = getSkillPath({ skillId, threadId });
        const skillFields = mapCaseToSkillFields({ caseData, skillId });

        navigate(url, {
          state: removeUndefinedValues(skillFields),
        });
      } else {
        logger.warn("handleStepClick - step without skillId or fileId");
      }
    },
    [caseData, navigate]
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        padding: 4,
      }}
    >
      <CaseHeader caseData={caseData} />

      <Box
        sx={{
          width: "100%",
          display: "grid",
          gridTemplateColumns: "minmax(0, 6fr) minmax(0, 5fr)",
          gap: 3,
        }}
      >
        <CaseSummary caseData={caseData} />

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 3,
          }}
        >
          {showNextSteps && <CaseNextSteps caseId={caseId} onStepClick={handleStepClick} />}
          <CaseDetailsChat caseData={caseData} loadingCase={loadingCase} archived={caseData?.status === "ARCHIVED"} />

          <CaseFiles caseData={caseData} />
        </Box>
      </Box>

      <CaseFooter
        onDelete={onDeleteCase}
        onArchive={onArchiveCase}
        onUnarchive={onUnarchiveCase}
        archived={caseData?.status === "ARCHIVED"}
      />
    </Box>
  );
};
