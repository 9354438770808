import { authenticatedCopilotServer } from "@/core/api";
import { logger } from "@/core/logger";
import { getPaginationHeaders } from "@/core/api/utils/getPaginationHeaders";
import {
  GetLegalProceedingsPayload,
  ImportLegalProceedingFromOABPayload,
  LegalProceedingRequest,
  LegalProceedingToCasePayload,
} from "./types";

export const LegalProceedingService = {
  getLegalProceedings: async ({ pagination }: GetLegalProceedingsPayload) => {
    try {
      const response = await authenticatedCopilotServer.get<LegalProceedingRequest>(`/legalProceedings`, {
        headers: getPaginationHeaders(pagination),
      });
      return response.data;
    } catch (e) {
      logger.error("getLegalProceedings", e);
      throw e;
    }
  },

  importLegalProceedingFromOAB: async (payload: ImportLegalProceedingFromOABPayload) => {
    try {
      const response = await authenticatedCopilotServer.post<LegalProceedingRequest>(
        `/users/${payload.userId}/oab`,
        payload.oabs
      );
      return response.data;
    } catch (e) {
      logger.error("importLegalProceedingFromOAB", e);
      throw e;
    }
  },

  transformLegalProceedingsToCase: async (payload: LegalProceedingToCasePayload) => {
    try {
      const response = await authenticatedCopilotServer.post(`/legalProceedings/toCase`, payload);
      return response.data;
    } catch (e) {
      logger.error("transformLegalProceedingsToCase", e);
      throw e;
    }
  },

  getLegalProceedingsToCase: async () => {
    try {
      const response = await authenticatedCopilotServer.get(`/legalProceedings/toCase`);
      return response.data;
    } catch (e) {
      logger.error("getLegalProceedingsToCase", e);
      throw e;
    }
  },
};
