import { useMutation, useQueryClient } from "@tanstack/react-query";
import { getLexZapUserQueryKey } from "../useLexZapUser";
import { LexZapUser } from "../types";
import { LexZapService } from "@/services/lexZap";

export const useUpdateLexZapUser = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["updateLexZapUser"],
    mutationFn: async (data: { notificationNumber: string; department: string; activityAreas?: string[] }) =>
      await LexZapService.updateMe(data),
    onMutate: async (data) => {
      await queryClient.cancelQueries({ queryKey: getLexZapUserQueryKey() });
      const previousCases = queryClient.getQueryData<string[]>(getLexZapUserQueryKey());
      queryClient.setQueryData(getLexZapUserQueryKey(), (old: LexZapUser) => {
        return { ...old, ...data };
      });
      return { previousCases };
    },
    onError: (_error, _newCase, context) => {
      queryClient.setQueryData(getLexZapUserQueryKey(), context?.previousCases);
    },
  });
};
