export const activityAreas = [
  "Cível",
  "Trabalhista",
  "Tributário",
  "Criminal",
  "Mercado de capitais",
  "Imobiliário",
  "Direito digital e Proteção de dados",
  "Societário & M&A",
  "Propriedade intelectual",
  "Infraestrutura",
  "Direito público e regulatório",
  "Ambiental",
  "Concorrencial",
  "Outros",
];
