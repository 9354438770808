import { useAuthContext } from "@/contexts/AuthContext";
import { ROUTE_PATHS } from "@/routes/routePaths";
import { CompanyRole } from "@/contexts/AuthContext/types";
import { useLexZapCompany } from "@/hooks/lexZap/useLexZapCompany";

export interface MenuOption {
  label: string;
  options: {
    label: string;
    path?: string;
    withPlanChip?: boolean;
    openBilling?: boolean;
    replaceRoute?: boolean;
    tooltip?: string;
    hidden?: boolean;
  }[];
}

export const useMenuOptions = (): { menuOptions: MenuOption[] } => {
  const { user } = useAuthContext();
  const { data: connectionData } = useLexZapCompany();
  const hasWhatsAppAssistant = connectionData?.isActive;
  const adminAndWhatsAppAssistantEnabled = user?.companyRole === CompanyRole.ADMIN && hasWhatsAppAssistant;

  const menuOptions: (MenuOption & { hidden?: boolean })[] = [
    {
      label: "Meu Perfil",
      options: [
        {
          label: "Meus Dados",
          path: hasWhatsAppAssistant ? ROUTE_PATHS.CLIENT_CONFIG_MY_DATA_WHATSAPP : ROUTE_PATHS.CLIENT_CONFIG_MY_DATA,
          replaceRoute: true,
          tooltip: "Altere seu e-mail de acesso à plataforma",
        },
        {
          label: "Meu time",
          path: ROUTE_PATHS.CLIENT_CONFIG_MY_TEAM,
          hidden: !adminAndWhatsAppAssistantEnabled,
        },
        { label: "Alterar Senha", path: ROUTE_PATHS.CLIENT_CONFIG_CHANGE_PASSWORD, replaceRoute: true },
      ],
    },
    {
      label: "Gerenciar assinatura",
      options: [
        { label: "Seu plano", withPlanChip: true, openBilling: true },
        { label: "Histórico de uso", path: ROUTE_PATHS.CREDITS_HISTORY },
      ],
      hidden: user?.companyRole !== CompanyRole.ADMIN,
    },
    {
      label: "Admin",
      options: [
        { label: "Usuários do Assistente WhatsApp", path: ROUTE_PATHS.WHATSAPP_USERS },
        {
          label: "Eventos Não Processados do WhatsApp",
          path: ROUTE_PATHS.WHATSAPP_DLQ_EVENTS,
        },
      ],
      hidden: !user?.isAdmin,
    },
  ];

  return {
    menuOptions: menuOptions.filter((option) => !option.hidden),
  };
};
