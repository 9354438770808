import { PageLayout } from "@/components/PageLayout";

import { Add, Chat, DeleteForever, Edit } from "@mui/icons-material";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { ptBR } from "@mui/x-data-grid/locales";
import React, { useState } from "react";

import { WebToast } from "@//components/core/Toast";
import { useDeleteLexZapCompany } from "@/hooks/lexZap/admin/useDeleteLexZapCompany";
import { useLexZapCompanies } from "@/hooks/lexZap/admin/useLexZapCompanies";
import { LexZapCompany } from "@/hooks/lexZap/types";
import { useAlertModal } from "@/hooks/useAlertModal";
import { Box, Button, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { CreateCompanyModal } from "./components/CreateCompanyModal";
import { EditCompanyModal } from "./components/EditCompanyModal";
import { useImpersonation } from "@/hooks/useImpersonation";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "@/routes/routePaths";

export const ManageWhatsappUsers = () => {
  const { data: companies, isLoading, isFetching } = useLexZapCompanies();

  const [companyToEdit, setCompanyToEdit] = useState<LexZapCompany>();
  const [createModalIsOpen, setCreateModalIsOpen] = useState(false);
  const { openAlertModal } = useAlertModal();
  const { mutateAsync: deleteCompany } = useDeleteLexZapCompany();
  const { setImpersonatedUser } = useImpersonation();
  const navigate = useNavigate();

  const handleDeleteCompany = (companyId: string) => {
    openAlertModal({
      title: "Tem certeza que deseja deletar esta company?",
      description: "Ao deletar esta company, todas as conversas e mensagens associadas a ela serão perdidas.",
      primaryButton: {
        label: "Continuar",
        onClick: async () => {
          try {
            await deleteCompany({ companyId });
            WebToast.success("Company deletada com sucesso");
          } catch {
            WebToast.error("Erro ao deletar company");
          }
        },
      },
      secondaryButton: {
        label: "Cancelar",
        onClick: () => {},
      },
    });
  };

  const handleAccessCompany = (company: LexZapCompany) => {
    setImpersonatedUser({
      companyId: company.companyId,
      companyName: company.companyName,
      whatsappNumber: company.phoneNumber,
      companyEmail: company.email,
    });
    navigate(ROUTE_PATHS.WHATSAPP_ASSISTANT_CHAT);
  };

  return (
    <PageLayout
      contentProps={{
        disablePadding: true,
        fullWidth: true,
      }}
      contentSx={{
        height: "100%",
      }}
    >
      <Box
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          padding: 4,
          gap: 4,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="pageTitle">Usuários do Assistente WhatsApp</Typography>

          <Button variant="contained" startIcon={<Add />} onClick={() => setCreateModalIsOpen(true)}>
            Adicionar Usuário
          </Button>
        </Box>

        <DataGrid
          rows={companies || []}
          getRowId={(row) => row.companyId}
          loading={isLoading || isFetching}
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          columns={[
            {
              field: "companyId",
              headerName: "Company Id",
              flex: 1,
            },
            {
              field: "companyName",
              headerName: "Nome da empresa",
              flex: 1,
            },
            {
              field: "email",
              headerName: "Email",
              flex: 1,
            },
            {
              field: "phoneNumber",
              headerName: "Número do whatsapp",
              flex: 1,
            },
            {
              field: "isActive",
              headerName: "Status",
              flex: 1,
              renderCell: ({ row: lexZapCompany }) => (lexZapCompany.isActive ? "Ativo" : "Pendente"),
            },
            {
              field: "createdAt",
              headerName: "Data de criação",
              flex: 1,
              renderCell: ({ row: lexZapCompany }) =>
                DateTime.fromMillis(lexZapCompany.createdAt).toFormat("dd/MM/yyyy HH:mm"),
            },
            {
              field: "actions",
              type: "actions",
              headerName: "Ações",
              flex: 1,
              getActions: ({ row: lexZapCompany }) => [
                <GridActionsCellItem
                  key={`${lexZapCompany.companyId}-edit`}
                  icon={<Edit />}
                  label="Editar"
                  onClick={() => setCompanyToEdit(lexZapCompany)}
                  showInMenu
                />,
                <GridActionsCellItem
                  key={`${lexZapCompany.companyId}-access-chat`}
                  icon={<Chat />}
                  label="Acessar conversas"
                  onClick={() => handleAccessCompany(lexZapCompany)}
                  showInMenu
                />,
                <GridActionsCellItem
                  key={`${lexZapCompany.companyId}-delete`}
                  icon={<DeleteForever />}
                  label="Deletar"
                  onClick={() => handleDeleteCompany(lexZapCompany.companyId)}
                  showInMenu
                />,
              ],
            },
          ]}
          rowSelection={false}
        />

        <CreateCompanyModal isOpen={createModalIsOpen} onClose={() => setCreateModalIsOpen(false)} />
        <EditCompanyModal
          isOpen={!!companyToEdit}
          onClose={() => setCompanyToEdit(undefined)}
          company={companyToEdit}
        />
      </Box>
    </PageLayout>
  );
};
