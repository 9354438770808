import React from "react";
import { Typography, Chip } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { LegalProceedingType } from "@/services/legalProceeding/types";
import { LexterDate } from "@/core/LexterDate";

export const legalProceedingColumns: GridColDef<LegalProceedingType>[] = [
  { field: "code", headerName: "Código CNJ", width: 150, sortable: false },
  { field: "name", headerName: "Processo", width: 200, sortable: false },
  {
    field: "parties",
    headerName: "Cliente(s) e envolvidos",
    sortable: false,
    width: 221,
    renderCell: (params) => {
      const parties = params.value as LegalProceedingType["parties"];
      return (
        <Typography
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            gap: 1,
          }}
          component="div"
          variant="body3"
          overflow="ellipsis"
        >
          {parties[0].name}
          <Chip size="small" label={`+${parties.length}`} />
        </Typography>
      );
    },
  },
  {
    field: "tribunal_acronym",
    headerName: "Origem",
    sortable: false,
    width: 150,
  },
  {
    field: "status",
    type: "custom",
    headerName: "Monitoramento",
    sortable: false,
    width: 130,
    align: "center",
    headerAlign: "center",
    renderCell: (params) => (
      <Chip
        label={params.value === "ACTIVE" ? "Ativo" : "Inativo"}
        size="small"
        sx={{
          bgcolor: params.value === "ACTIVE" ? "success.main" : null,
          color: params.value === "ACTIVE" ? "white" : "inherit",
        }}
      />
    ),
  },
  {
    field: "lastStepDate",
    type: "custom",
    headerName: "Última movimentação",
    width: 160,
    align: "left",
    renderCell: (params) => {
      return LexterDate.fromDate(params.value).getValue().toLongStringWithFullYear();
    },
    valueGetter: (value) => {
      return new Date(value);
    },
  },
];
