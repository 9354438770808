import React from "react";

import { Box } from "@mui/material";
import { MessageProps } from "../../types";
import { MessageCard } from "../MessageCard";

export const StickerMessage = ({ message }: MessageProps) => {
  return (
    <MessageCard
      message={message}
      sx={{
        paddingY: 0,
        paddingX: 2,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        backgroundColor: "transparent",
      }}
      statusSx={{
        position: "static",
        backgroundColor: message.fromLawyer ? "#E3FBE3" : "#FFFFFF",
        paddingX: "4px",
        paddingY: "2px",
        borderRadius: "8px",
      }}
    >
      <Box
        sx={{
          width: "190px",
          height: "190px",
        }}
      >
        <img
          src={message.media?.url}
          alt={message.media?.filename}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "contain",
          }}
        />
      </Box>
    </MessageCard>
  );
};
