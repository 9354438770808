import { Box, Button, TextField, Typography } from "@mui/material";
import { Loading } from "@/components/Loading";
import { ModalRight } from "@/components/Modal/ModalRight";
import React from "react";
import { WebToast } from "@//components/core/Toast";
import { useApplicant } from "@/hooks/applicants/useApplicant";
import { useEditApplicant } from "@/hooks/applicants/useEditApplicant";
import { useCreateApplicant } from "@/hooks/applicants/useCreateApplicant";
import { ApplicantPayload, applicantPayloadSchema } from "@/services/applicant/types";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { nullsToUndefined } from "@/utils/nullsToUndefined";
import { removeUndefinedValues } from "@/utils/removeUndefinedValues";

const defaultFormValue: ApplicantPayload = {
  name: "",
  city: undefined,
  cpf: undefined,
  email: undefined,
  phoneNumber: undefined,
  maritalStatus: undefined,
  occupation: undefined,
  state: undefined,
  streetName: undefined,
  houseNumber: undefined,
  zipCode: undefined,
};

export function ApplicantModal({
  isOpen,
  onClose,
  applicantId,
  defaultApplicant,
}: {
  isOpen: boolean;
  onClose: (applicantId?: string) => void;
  applicantId?: string;
  defaultApplicant?: Partial<ApplicantPayload>;
}) {
  const { data, isLoading: isLoadingCaseData } = useApplicant({ applicantId });
  const { mutateAsync: updateApplicant, isPending: isUpdatingCase } = useEditApplicant();
  const { mutateAsync: createApplicant, isPending: isCreatingCase } = useCreateApplicant();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ApplicantPayload>({
    defaultValues: {
      ...defaultFormValue,
      ...defaultApplicant,
      ...nullsToUndefined(data),
    },
    mode: "onChange",
    resolver: zodResolver(applicantPayloadSchema),
  });

  const onSubmit = handleSubmit(async (submittedData) => {
    try {
      const data = removeUndefinedValues(submittedData);

      if (applicantId) {
        await updateApplicant({ applicantId, data });
        onClose(applicantId);
      } else {
        const { id: createdApplicantId } = await createApplicant({ data });
        onClose(createdApplicantId);
      }

      return WebToast.success(applicantId ? "Alteração feita com sucesso" : "Cadastro realizado com sucesso");
    } catch (err) {
      return WebToast.error(applicantId ? "Falha ao alterar dados do cliente" : "Falha ao cadastrar cliente");
    }
  });

  const isLoading = isLoadingCaseData || isUpdatingCase || isCreatingCase;

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "min-content min-content",
        gridTemplateRows: "minmax(0, 1fr)",
        position: "fixed",
        top: 0,
        bottom: 0,
        right: 0,
        bgcolor: "background.paper",
        boxShadow: 24,
        zIndex: "modal",
      }}
    >
      <ModalRight
        title={applicantId ? "Editar cliente" : "Criar cliente"}
        size={"SMALL"}
        onClose={() => onClose()}
        footer={
          !isLoading && (
            <ModalFooter
              confirmLabel={applicantId ? "Atualizar" : "Criar"}
              onConfirm={onSubmit}
              cancelLabel="Cancelar"
              onCancel={() => onClose()}
              saveButtonDisabled={false}
            />
          )
        }
        open={isOpen}
      >
        <Loading isLoading={isLoading || isLoadingCaseData || isUpdatingCase || isCreatingCase}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "24px",
              }}
            >
              <Typography variant="body1" fontWeight={500}>
                Perfil do cliente
              </Typography>

              <TextField
                placeholder="Cliente"
                label="Cliente"
                {...register("name")}
                error={!!errors.name}
                helperText={errors.name?.message}
              />

              <TextField
                placeholder="CPF"
                {...register("cpf")}
                error={!!errors.cpf}
                helperText={errors.cpf?.message}
                type="text"
                inputProps={{
                  maxLength: 11,
                }}
                label="CPF"
              />

              <TextField
                placeholder="Estado Civil"
                {...register("maritalStatus")}
                error={!!errors.maritalStatus}
                helperText={errors.maritalStatus?.message}
                label="Estado Civil"
              />

              <TextField
                placeholder="Profissão"
                {...register("occupation")}
                error={!!errors.occupation}
                helperText={errors.occupation?.message}
                label="Profissão"
              />

              <Typography variant="body1" fontSize={"12px"}>
                Contato
              </Typography>

              <TextField
                placeholder="Telefone"
                {...register("phoneNumber")}
                error={!!errors.phoneNumber}
                helperText={errors.phoneNumber?.message}
                label="Telefone"
                type="tel"
                inputProps={{
                  maxLength: 11,
                }}
                disabled={!!data?.phoneNumber}
              />

              <TextField
                placeholder="Email"
                {...register("email")}
                error={!!errors.email}
                helperText={errors.email?.message}
                label="Email"
                type="email"
              />

              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr 0.45fr",
                  gap: "16px",
                }}
              >
                <TextField
                  placeholder="Rua"
                  {...register("streetName")}
                  error={!!errors.streetName}
                  helperText={errors.streetName?.message}
                  label="Rua"
                />
                <TextField
                  placeholder="Número"
                  {...register("houseNumber")}
                  error={!!errors.houseNumber}
                  helperText={errors.houseNumber?.message}
                  label="Número"
                />
              </Box>

              <TextField
                placeholder="CEP"
                {...register("zipCode")}
                error={!!errors.zipCode}
                helperText={errors.zipCode?.message}
                label="CEP"
                inputProps={{
                  maxLength: 8,
                }}
              />

              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr 0.45fr",
                  gap: "16px",
                }}
              >
                <TextField
                  placeholder="Cidade"
                  {...register("city")}
                  error={!!errors.city}
                  helperText={errors.city?.message}
                  label="Cidade"
                />
                <TextField
                  placeholder="Estado"
                  {...register("state")}
                  error={!!errors.state}
                  helperText={errors.state?.message}
                  label="Estado"
                  inputProps={{
                    maxLength: 2,
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Loading>
      </ModalRight>
    </Box>
  );
}

const ModalFooter = ({
  confirmLabel,
  cancelLabel,
  onConfirm,
  onCancel,
  saveButtonDisabled,
}: {
  confirmLabel: string;
  cancelLabel: string;
  onConfirm: () => void;
  onCancel: () => void;
  saveButtonDisabled: boolean;
}) => {
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "auto auto",
        gap: "8px",
        margin: "0 auto",
      }}
    >
      <Button variant="contained" disabled={saveButtonDisabled} onClick={onConfirm} size="small">
        {confirmLabel}
      </Button>
      <Button size="small" onClick={onCancel}>
        {cancelLabel}
      </Button>
    </Box>
  );
};
