import { buildSkillStep, createUploadFileField } from "@/core/skillForms/builder";

export const hearingsScriptSkillStep2 = buildSkillStep({
  title: "Arquivos do caso",
  description: "Envie os arquivos referentes às peças iniciais e de resposta do caso.",
  fields: [
    createUploadFileField({
      name: "reference_piece_complaint",
      ui: {
        label: "Envie o arquivo da petição inicial referente ao caso:",
      },
    }),
    createUploadFileField({
      name: "reference_piece_answer",
      ui: {
        label: "Envie o arquivo da contestação referente ao caso:",
      },
    }),
  ],
});
