import { buildSkillStep } from "@/core/skillForms/builder";

export const feeAgreementSkillStep2 = buildSkillStep({
  title: "Nome do cliente",
  description: "Para finalizar, insira agora os dados do cliente como nome, documentos, endereço,etc.",
  fields: [
    {
      name: "client",
      type: "string",
      ui: {
        label: "Qual é o nome do cliente?",
        placeholder: "Exemplo: João da Silva. Se tiver mais de um nome, separe com vírgulas.",
      },
    },
  ],
  submitButtonText: "Elaborar contrato",
});
