import { useContext, useEffect } from "react";
import { WebSocketContext } from "./context";
import {
  OnMessageReceivedParam,
  OnThreadConnectParams,
  OnNotificationReceivedParams,
  OnThreadErrorParams,
} from "./types";
import { WhatsappUpdate } from "./types/whatsapp.types";

interface UseSocketProps {
  onMessageReceived?: (params: OnMessageReceivedParam) => void;
  onThreadConnected?: (params: OnThreadConnectParams) => void;
  onThreadError?: (params: OnThreadErrorParams) => void;
  onNotificationReceived?: (params: OnNotificationReceivedParams) => void;
  onWhatsAppUpdate?: (params: WhatsappUpdate) => void;
}

export const useSocket = (params?: UseSocketProps) => {
  const { onMessageReceived, onThreadConnected, onNotificationReceived, onWhatsAppUpdate, onThreadError } =
    params || {};
  const {
    messageReceivedEvent,
    threadConnectedEvent,
    notificationReceivedEvent,
    whatsAppUpdateEvent,
    threadErrorEvent,
    ...rest
  } = useContext(WebSocketContext);

  useEffect(() => {
    if (!onMessageReceived) return;

    messageReceivedEvent.addEventListener(onMessageReceived);

    return () => {
      messageReceivedEvent.removeEventListener(onMessageReceived);
    };
  }, [messageReceivedEvent, onMessageReceived]);

  useEffect(() => {
    if (!onThreadConnected) return;

    threadConnectedEvent.addEventListener(onThreadConnected);

    return () => {
      threadConnectedEvent.removeEventListener(onThreadConnected);
    };
  }, [onThreadConnected, threadConnectedEvent]);

  useEffect(() => {
    if (!onThreadError) return;

    threadErrorEvent.addEventListener(onThreadError);

    return () => {
      threadErrorEvent.removeEventListener(onThreadError);
    };
  }, [onThreadError, threadErrorEvent]);

  useEffect(() => {
    if (!onNotificationReceived) return;

    notificationReceivedEvent.addEventListener(onNotificationReceived);

    return () => {
      notificationReceivedEvent.removeEventListener(onNotificationReceived);
    };
  }, [notificationReceivedEvent, onNotificationReceived]);

  useEffect(() => {
    if (!onWhatsAppUpdate) return;

    whatsAppUpdateEvent.addEventListener(onWhatsAppUpdate);

    return () => {
      whatsAppUpdateEvent.removeEventListener(onWhatsAppUpdate);
    };
  }, [onWhatsAppUpdate, whatsAppUpdateEvent]);

  return rest;
};
