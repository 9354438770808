import { Box, Button, Checkbox, Typography } from "@mui/material";
import { AutoFixHigh as AutoFixHighIcon } from "@mui/icons-material";
import React from "react";

interface CaseStepProps {
  checked?: boolean;
  onClick?: () => void;
  description?: string;
}

export const CaseStep = ({ checked, description, onClick }: CaseStepProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        borderBottomColor: "common.lightShade",
        borderBottomWidth: 1,
        borderBottomStyle: "solid",
        paddingY: 1.5,
        justifyContent: "space-between",
        gap: 0.5,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2,
        }}
      >
        <Checkbox
          checked={checked}
          sx={{
            p: 0,
          }}
        />
        <Typography
          variant="body1"
          sx={{
            textDecoration: checked ? "line-through" : "none",
          }}
        >
          {description}
        </Typography>
      </Box>
      {onClick && <CaseStepAction checked={checked} onClick={onClick} />}
    </Box>
  );
};

const CaseStepAction = ({ checked, onClick }: CaseStepProps) => {
  return (
    <Box>
      {checked ? (
        <Button variant="text" onClick={onClick}>
          Acessar
        </Button>
      ) : (
        <Button
          variant="text"
          sx={{
            whiteSpace: "nowrap",
          }}
          startIcon={<AutoFixHighIcon />}
          onClick={onClick}
        >
          Gerar com IA
        </Button>
      )}
    </Box>
  );
};
