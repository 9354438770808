import { THREADS_QUERY_KEY } from "@/hooks/threads/usePaginatedThreads";
import { ThreadService } from "@/services/thread";
import { useQuery } from "@tanstack/react-query";

export const getThreadQueryKey = ({ threadId }: { threadId?: string }) => [...THREADS_QUERY_KEY, threadId] as const;

export const useThread = ({ threadId }: { threadId?: string }) => {
  return useQuery({
    queryKey: getThreadQueryKey({ threadId }),
    queryFn: async () => {
      if (!threadId) {
        return undefined;
      }
      return ThreadService.getThread({ threadId });
    },
    enabled: !!threadId,
  });
};
