import { ApplicantModal } from "@/components/Applicants";
import { EditCaseModal } from "@/components/Cases";
import { Case } from "@/hooks/lexZap/types";
import { Box, Button, Typography } from "@mui/material";
import React from "react";

type CaseHeaderProps = {
  caseData?: Case;
};

export const CaseHeader = ({ caseData }: CaseHeaderProps) => {
  const [isEditCaseModalOpen, setIsEditCaseModalOpen] = React.useState(false);
  const [isEditClientModalOpen, setIsEditClientModalOpen] = React.useState(false);

  const openEditCaseModal = () => {
    setIsEditCaseModalOpen(true);
  };

  const closeEditCaseModal = () => {
    setIsEditCaseModalOpen(false);
  };

  const openEditClientModal = () => {
    setIsEditClientModalOpen(true);
  };

  const closeEditClientModal = () => {
    setIsEditClientModalOpen(false);
  };

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "1fr min-content",
        gap: 2,
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        <Typography variant="pageTitle" color="text.primary">
          {caseData?.name}
        </Typography>
        <Typography variant="subtitle1" color="text.primary">
          {caseData?.description}
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: 0.5,
          }}
        >
          <Typography variant="subtitle1" color="common.mediumShade">
            {caseData?.id} -
          </Typography>
          <Typography
            variant="subtitle1"
            color="common.shade"
            sx={{
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={openEditClientModal}
          >
            {caseData?.applicantName}
          </Typography>
        </Box>
      </Box>

      <Button
        variant="contained"
        color="primary"
        sx={{
          whiteSpace: "nowrap",
        }}
        onClick={openEditCaseModal}
      >
        Editar caso
      </Button>

      {caseData?.id && isEditCaseModalOpen && (
        <EditCaseModal caseId={caseData.id} isOpen={isEditCaseModalOpen} onClose={closeEditCaseModal} />
      )}

      {caseData?.applicantId && (
        <ApplicantModal
          applicantId={caseData.applicantId}
          isOpen={isEditClientModalOpen}
          onClose={closeEditClientModal}
        />
      )}
    </Box>
  );
};
