export interface CompanyMember {
  userId: string;
  companyId: string;
  name: string;
  email: string;
  isActive: boolean;
  isAdmin: boolean;
  notificationNumber?: string;
  department?: Department;
  activityAreas?: string[];
}

export enum Department {
  "ADMINISTRATIVE" = "ADMINISTRATIVE",
  "FINANCIAL" = "FINANCIAL",
  "LEGAL" = "LEGAL",
}
