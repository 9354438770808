import { Box } from "@mui/material";
import React from "react";

import { CaseMessageType } from "@/hooks/lexZap/types";
import { MessageProps } from "./types";
import { AudioMessage } from "./components/AudioMessage";
import { TextMessage } from "./components/TextMessage";
import { GenericMediaMessage } from "./components/GenericMediaMessage";
import { MediaErrorMessage } from "./components/MediaErrorMessage";
import { ImageMessage } from "./components/ImageMessage";
import { VideoMessage } from "./components/VideoMessage";
import { StickerMessage } from "./components/StickerMessage";
import { LocationMessage } from "./components/LocationMessage";
import { ContactsMessage } from "./components/ContactsMessage";

export const Message = ({ message }: MessageProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: message.fromLawyer ? "flex-end" : "flex-start",
      }}
    >
      <MessageContent message={message} />
    </Box>
  );
};

const MessageContent = ({ message }: MessageProps) => {
  if (message.media?.error) {
    return <MediaErrorMessage message={message} />;
  }

  if (message.type === CaseMessageType.TEXT) {
    return <TextMessage message={message} />;
  }

  if (message.type === CaseMessageType.AUDIO) {
    return <AudioMessage message={message} />;
  }

  if (message.type === CaseMessageType.IMAGE) {
    return <ImageMessage message={message} />;
  }

  if (message.type === CaseMessageType.VIDEO) {
    return <VideoMessage message={message} />;
  }

  if (message.type === CaseMessageType.STICKER) {
    return <StickerMessage message={message} />;
  }

  if (message.type === CaseMessageType.LOCATION) {
    return <LocationMessage message={message} />;
  }

  if (message.type === CaseMessageType.CONTACTS) {
    return <ContactsMessage message={message} />;
  }

  return <GenericMediaMessage message={message} />;
};
