import { WebToast } from "@//components/core/Toast";
import { Loading } from "@/components/Loading";
import { ModalRight } from "@/components/Modal/ModalRight";
import { Email } from "@/core/Email";
import { Phone } from "@/core/Phone";
import { useSetupLexZapCompany } from "@/hooks/lexZap/admin/useSetupLexZapCompany";
import { Box, Button } from "@mui/material";
import React, { ChangeEvent, useEffect, useMemo } from "react";
import { InputField } from "../InputField";
import { PhoneNumberField } from "../PhoneNumberField";

const INITIAL_USER_FORM = {
  email: "",
  phoneNumber: "",
  notificationNumber: "",
  assistantName: "",
  companyName: "",
  apiKey: "",
};

export const CreateCompanyModal = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => {
  const [form, setForm] = React.useState<{
    email: string;
    phoneNumber: string;
    notificationNumber: string;
    assistantName: string;
    companyName: string;
    apiKey: string;
  }>(INITIAL_USER_FORM);
  const { mutateAsync: setupUser, isPending: isLoading } = useSetupLexZapCompany();
  const [isValidPhoneNumber, setIsValidPhoneNumber] = React.useState<boolean>(true);
  const [isValidNotificationNumber, setIsValidNotificationNumber] = React.useState<boolean>(true);
  const [isValidEmail, setIsValidEmail] = React.useState<boolean>(true);

  useEffect(() => {
    if (!isOpen) {
      setForm(INITIAL_USER_FORM);
    }
  }, [isOpen]);

  const handleCreateUser = async () => {
    try {
      const phoneNumber = Phone.new(form.phoneNumber);

      if (!phoneNumber.isSuccess) {
        WebToast.error("Número de whatsapp inválido");
        return;
      }

      let notificationNumber: string | undefined = undefined;
      if (form.notificationNumber) {
        const notificationPhone = Phone.new(form.notificationNumber);

        if (!notificationPhone.isSuccess) {
          WebToast.error("Número de notificação inválido");
          return;
        }
        notificationNumber = notificationPhone.getValue().toPersistence();
      }

      await setupUser({
        ...form,
        phoneNumber: phoneNumber.getValue().toPersistence(),
        notificationNumber,
      });
      onClose();
      WebToast.success("Usuário criado com sucesso");
    } catch {
      WebToast.error("Falha ao criar usuário");
    }
  };

  const handleChangeForm = <K extends keyof typeof form>(prop: K) => {
    return (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (prop === "phoneNumber" || prop === "notificationNumber") {
        setForm((prevState) => ({
          ...prevState,
          [prop]: e.target.value.replace(/\D/g, ""),
        }));
      } else {
        setForm((prevState) => ({
          ...prevState,
          [prop]: e.target.value,
        }));
      }
    };
  };

  const canCreate = useMemo(
    () =>
      Email.isValidEmail(form.email) &&
      Phone.new(form.phoneNumber).isSuccess &&
      Phone.new(form.notificationNumber ?? "").isSuccess &&
      !!form.apiKey.trim(),
    [form]
  );

  return (
    <ModalRight
      title={"Nova Company"}
      size={"SMALL"}
      onClose={onClose}
      footer={
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "auto auto",
            gap: "8px",
          }}
        >
          <Button variant="contained" disabled={isLoading || !canCreate} onClick={handleCreateUser} size="small">
            Criar
          </Button>
          <Button size="small" disabled={isLoading} onClick={onClose}>
            Cancelar
          </Button>
        </Box>
      }
      open={isOpen}
    >
      <Loading isLoading={isLoading}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "32px",
          }}
        >
          <InputField
            placeholder="Email"
            onChange={(e) => {
              handleChangeForm("email")(e);
              setIsValidEmail(e.target.value === "" ? true : Email.fromString(e.target.value).isSuccess);
            }}
            type="email"
            error={!isValidEmail}
            value={form.email}
            label="Email"
          />

          <PhoneNumberField
            label="Número do whatsapp"
            placeholder="Número do whatsapp"
            value={
              Phone.new(form.phoneNumber).isSuccess
                ? Phone.new(form.phoneNumber).getValue().toString()
                : form.phoneNumber
            }
            onChange={(e) => {
              handleChangeForm("phoneNumber")(e);
              setIsValidPhoneNumber(
                e.target.value === "" ? true : Phone.new(e.target.value.replace(/[^\d]+/g, "")).isSuccess
              );
            }}
            error={!isValidPhoneNumber}
          />

          <PhoneNumberField
            placeholder="Número para notificações"
            onChange={(e) => {
              handleChangeForm("notificationNumber")(e);
              setIsValidNotificationNumber(
                e.target.value === "" ? true : Phone.new(e.target.value.replace(/[^\d]+/g, "")).isSuccess
              );
            }}
            value={
              Phone.new(form.notificationNumber).isSuccess
                ? Phone.new(form.notificationNumber).getValue().toString()
                : form.notificationNumber
            }
            label="Número para notificações"
            error={!isValidNotificationNumber}
          />

          <InputField
            placeholder="Nome do assistente"
            onChange={handleChangeForm("assistantName")}
            type="text"
            value={form.assistantName}
            label="Nome do assistente"
          />

          <InputField
            placeholder="Nome do escritório"
            onChange={handleChangeForm("companyName")}
            type="text"
            value={form.companyName}
            label="Nome do escritório"
          />

          <InputField placeholder="Api Key" onChange={handleChangeForm("apiKey")} value={form.apiKey} label="Api Key" />
        </Box>
      </Loading>
    </ModalRight>
  );
};
