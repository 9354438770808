import { Applicant } from "@/services/applicant/types";
import { useApplicants } from "../useApplicants";

interface UseApplicantProps {
  applicantId?: string;
}

interface UseLexZapCaseReturn {
  data?: Applicant;
  isLoading: boolean;
  isError: boolean;
}

export const useApplicant = ({ applicantId }: UseApplicantProps): UseLexZapCaseReturn => {
  const { data: applicants, isLoading, isError } = useApplicants();

  return {
    data: applicants?.find((item) => item.id === applicantId),
    isLoading,
    isError,
  };
};
