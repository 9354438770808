import React from "react";
import { createBrowserRouter } from "@datadog/browser-rum-react/react-router-v6";
import { App } from "@/App";

export const router = createBrowserRouter([
  {
    path: "*",
    element: <App />,
  },
]);
