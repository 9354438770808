import React from "react";
import Modal from "@mui/material/Modal";
import { WarningMobileScreen } from "../WarningMobileScreen";
import { useFeatureFlags } from "@/hooks/useFeatureFlags";
import { usePlatform } from "@/hooks/usePlatform";

export const ScreenSizeChecker = ({ children }: React.PropsWithChildren) => {
  const plataform = usePlatform();
  const { customerServiceWhatsApp } = useFeatureFlags();

  return (
    <>
      {children}
      <Modal open={plataform === "MOBILE"} onClose={() => {}}>
        <WarningMobileScreen contactLink={customerServiceWhatsApp} />
      </Modal>
    </>
  );
};
