import { Result } from "./Result";
import { ValueObject } from "./ValueObject";

/**
 * Validate a brazilian phone, does not include country code
 * ^s*(d{2}|d{0})[-. ]?(d{5}|d{4})[-. ]?(d{4})[-. ]?s*$
 */
const brazilianPhoneRegex = new RegExp(
  "^" + // string start
    "\\s*" + // white spaces to be trimmed
    "\\(?(\\d{2})\\)?" + // local code with or without parentheses, save it as group 1
    "[-. ]?" + //local code may be separated by -, . or space from the phone number
    "(\\d{5}|\\d{4})" + //match first part of the phone, save it as group 2
    "[-. ]?" + //mid number separation, optional
    "(\\d{4})" + //second part of the phone, save it as group 3
    "\\s*" + //white spaces to be trimmed
    "$"
);

interface PhoneProps {
  countyCode: string;
  localCode: string;
  phoneNumber: [string, string];
}

/**
 * Represents a Phone number
 * Currently only Brazilian phone numbers are supported
 */
export class Phone extends ValueObject<PhoneProps> {
  private constructor(props: PhoneProps) {
    super(props);
  }

  /** Guarantees a phone is valid */
  private static isValid(phone: string): boolean {
    if (typeof phone !== "string" || !phone) {
      return false;
    }
    return brazilianPhoneRegex.test(phone);
  }

  /** Transform a valid phone string to the internal phone representation */
  private static format(phone: string): PhoneProps {
    const found = phone.match(brazilianPhoneRegex);

    /*
     * We are going to ignore the following if from the test coverage because
     * it is not possible to test it, since it is a impossible condition to be
     * met with a valid phone.
     * We will always have a valid phone here because format is only called
     * after the isValid function.
     * In the end this if exists to please the compiler in a more elegant way
     * than using a `found as RegExpMatchArray`
     */
    /* istanbul ignore if */
    if (found === null) {
      throw new Error("Could not format phone, this should never happen");
    }

    return {
      countyCode: "55",
      localCode: found[1],
      phoneNumber: [found[2], found[3]],
    };
  }

  /**
   * Create a new Phone from a string
   *
   * Only create Brazilian phones at the moment
   *
   * Does not accept country code as part of the phone string
   *
   * @param phone phone string
   */
  static new(phone: string): Result<Phone> {
    if (!this.isValid(phone)) {
      return Result.fail<Phone>("Invalid phone");
    }

    return Result.ok<Phone>(new Phone(this.format(phone)));
  }

  /**
   * Restore a phone from the database
   *
   * We store the country code of the phone for an eventual future compatibility
   * the only downside of this compatibility is that the restore action needs
   * to be different from the creation action since this string would not be
   * considered valid because of the country code.
   *
   * @param phone stored phone string
   */
  static restore(phone: string): Result<Phone> {
    if (typeof phone !== "string" || !phone) {
      return Result.fail<Phone>("Invalid phone");
    }

    const countryCode = phone.slice(0, 2);

    if (countryCode !== "55") {
      return Result.fail<Phone>("Invalid phone, only brazilian phones are allowed");
    }

    const phoneWithoutCountryCode = phone.slice(2);

    return this.new(phoneWithoutCountryCode);
  }

  /**
   * Transform a phone to a readable user string
   */
  public toString(): string {
    return `(${this.props.localCode}) ${this.props.phoneNumber[0]}-${this.props.phoneNumber[1]}`;
  }

  /**
   * Transform a phone to a string to be stored
   */
  public toPersistence(): string {
    return `${this.props.countyCode}${this.props.localCode}${this.props.phoneNumber[0]}${this.props.phoneNumber[1]}`;
  }

  /**
   * format a phone to a string with spaces
   * +xx xx x xxxx xxxx
   */
  public toFormatSpaced(): string {
    return `+${this.props.countyCode} ${this.props.localCode} ${this.props.phoneNumber[0]} ${this.props.phoneNumber[1]}`;
  }
}
