import { Box, Skeleton, SxProps, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { CreditsError } from "@/components/CreditsError";
import { PlanChip } from "@/components/PlanChip";
import { PlanChipSkeleton } from "@/components/PlanChip/PlanChipSkeleton";
import { useAuthContext } from "@/contexts/AuthContext";
import React, { useEffect } from "react";
import { useCredits } from "@/hooks/credits/useCredits";
import { useOpenBilling } from "@/hooks/useOpenBilling";
import { PlanType } from "@/hooks/credits/types";
import { calculateDaysToEndPlan, notShowCredits } from "@/utils/plans";
import { CompanyRole } from "@/contexts/AuthContext/types";

export const Credits = ({ onClose }: { onClose: () => void }) => {
  const { data: credits, isLoading: isLoadingCredits, error } = useCredits();
  const openBilling = useOpenBilling();
  const { user } = useAuthContext();

  const isTrial = credits?.companyPlan?.plan === PlanType.TRIAL;
  const planType = credits?.companyPlan?.plan;
  const availableCredit = credits?.availableCredit;
  const showCredits = !notShowCredits(credits);
  const isCompanyAdmin = user?.companyRole === CompanyRole.ADMIN;

  const handleOpenBilling = () => {
    void openBilling();
    onClose();
  };

  return (
    <Box
      sx={{
        backgroundColor: "common.white",
        color: "common.black",
        p: "16px",
        cursor: "default",
      }}
    >
      {error ? (
        <CreditsError />
      ) : (
        <>
          <Typography
            variant="subtitle1"
            mb="6px"
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {user?.userName}
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "4px",
            }}
          >
            {showCredits && (
              <Typography variant="body">
                {isLoadingCredits ? (
                  <Skeleton
                    variant="text"
                    width="100px"
                    sx={{
                      display: "inline-block",
                      bgcolor: "common.lightShade",
                    }}
                  />
                ) : (
                  <>
                    <strong>Créditos:</strong> {availableCredit?.toLocaleString("pt-BR") || "--"}
                  </>
                )}
              </Typography>
            )}
            {isTrial && <DaysToEndTrial />}
            {showCredits && <NextRecharge />}
          </Box>

          {isLoadingCredits ? (
            <PlanChipSkeleton />
          ) : (
            !!planType && (
              <>
                <PlanChip planType={planType} nextBillingDate={credits.companyPlan?.nextBillingDate} />
                {isCompanyAdmin && <GetMoreCredits onOpenBilling={handleOpenBilling} sx={{ mt: "16px" }} />}
              </>
            )
          )}
        </>
      )}
    </Box>
  );
};

export const GetMoreCredits = ({ onOpenBilling, sx }: { onOpenBilling: () => void; sx?: SxProps }) => {
  const { data: credits } = useCredits();

  const isFreemium = credits?.companyPlan?.plan === PlanType.FREEMIUM;
  const isTrial = credits?.companyPlan?.plan === PlanType.TRIAL;

  return (
    <Typography
      sx={{
        cursor: "pointer",
        letterSpacing: "0.5px",
        fontSize: "14px",
        alignSelf: "flex-end",
        border: "1px solid",
        padding: "4px 20px",
        borderRadius: "16px",
        textAlign: "center",
        ...sx,
      }}
      onClick={onOpenBilling}
    >
      {isFreemium || isTrial ? "Contratar plano" : "Gerenciar plano"}
    </Typography>
  );
};

export const NextRecharge = ({ text }: { text?: string }) => {
  const { data: credits, isLoading: isLoadingCredits } = useCredits();

  const nextBillingDate = credits?.companyPlan?.nextBillingDate;

  const nextBillingDateFormatted = nextBillingDate
    ? DateTime.fromISO(nextBillingDate).setLocale("pt-BR").toFormat("dd 'de' MMMM 'de' yyyy")
    : nextBillingDate;

  return (
    <Typography variant="body3" mb={2}>
      {isLoadingCredits ? (
        <Skeleton
          variant="text"
          width="140px"
          sx={{
            display: "inline-block",
            bgcolor: "common.lightShade",
          }}
        />
      ) : (
        <>
          <strong>{text ? text : "Próxima recarga: "}</strong>
          {nextBillingDateFormatted}
        </>
      )}
    </Typography>
  );
};

export const DaysToEndTrial = ({ completeSentence }: { completeSentence?: boolean }) => {
  const { data: credits, isLoading: isLoadingCredits } = useCredits();

  const [daysToEnd, setDaysToEnd] = React.useState<number>(0);

  const nextBillingDate = credits?.companyPlan?.nextBillingDate;

  useEffect(() => {
    calculateDaysToEnd();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextBillingDate]);

  const calculateDaysToEnd = () => {
    setDaysToEnd(calculateDaysToEndPlan(nextBillingDate));
  };

  return (
    <Typography variant="body3" mb={2}>
      {isLoadingCredits ? (
        <Skeleton
          variant="text"
          width="60px"
          sx={{
            display: "inline-block",
            bgcolor: "common.lightShade",
          }}
        />
      ) : (
        <>
          {daysToEnd > 0 ? (
            <span>
              Você ainda tem{" "}
              <strong>
                {daysToEnd} {daysToEnd === 1 ? <>dia</> : <>dias</>}
              </strong>{" "}
              para utilizar o assistente de forma gratuita.
            </span>
          ) : (
            <span>
              <strong>Seu período de testes chegou ao fim. </strong>
              {completeSentence && <span>Contrate agora para continuar usando.</span>}
            </span>
          )}
        </>
      )}
    </Typography>
  );
};
