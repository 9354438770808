export type OrderRecordsBy = "CREATED_AT" | "ALPHABET_ASC" | "ALPHABET_DESC" | "TYPE";

export type OrderRecordsOptions = Record<OrderRecordsBy, string>;

export enum RecordType {
  PRECEDENT = "precedent",
  DOCUMENT = "document",
}

export interface ThreadRecord {
  id: number;
  type: RecordType;
  name: string;
  createdAt: string;
  threadId: string | null;
}

export interface ThreadRecordWithThreadId extends ThreadRecord {
  threadId: string;
}
