import { Box, Button, TablePagination, Typography } from "@mui/material";
import React, { useMemo, useState } from "react";

import { CaseFile } from "./components/CaseFile";
import { CaseFilesSearch } from "./components/CaseFilesSearch";
import { Card } from "../Card";
import { generatePath, useNavigate } from "react-router-dom";
import { Loading } from "@/components/Loading";
import { ROUTE_PATHS } from "@/routes/routePaths";
import { useCaseFiles } from "@/hooks/lexZap/useCaseFiles";
import { Case } from "@/hooks/lexZap/types";

type CaseFilesProps = {
  caseData?: Case;
};

export const CaseFiles = ({ caseData }: CaseFilesProps) => {
  const [pagination, setPagination] = useState({
    pageSize: 5,
    page: 0,
  });
  const [query, setQuery] = React.useState<string>("");

  const navigate = useNavigate();

  const { data: caseFiles, isLoading: isLoadingNewCaseFiles } = useCaseFiles({
    applicantPhoneNumber: caseData?.applicantPhoneNumber,
    threadId: caseData?.threadId,
    search: query,
  });

  const files = useMemo(() => {
    const startIndex = pagination.page * pagination.pageSize;
    const endIndex = startIndex + pagination.pageSize;
    return caseFiles.slice(startIndex, endIndex);
  }, [caseFiles, pagination]);

  const handleChangePage = (_: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPagination((prev) => ({ ...prev, page: newPage }));
  };

  const goToNewDocument = () => {
    if (!caseData?.threadId) {
      return;
    }
    navigate(`${generatePath(ROUTE_PATHS.THREAD, { threadId: caseData.threadId })}?skillGroup=create_new_document`);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 3,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: 1,
          alignItems: "center",
        }}
      >
        <Typography
          variant="subtitle2"
          fontWeight={700}
          fontSize="22px"
          color="text.primary"
          sx={{
            flex: 1,
          }}
        >
          Arquivos do caso
        </Typography>

        <CaseFilesSearch
          sx={{
            flex: 1,
          }}
          onChange={(value) => {
            setQuery(value);
          }}
        />
      </Box>

      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: 1,
            alignItems: "center",
          }}
        >
          <Typography variant="subtitle1" fontWeight={700} color="text.primary">
            Documentos e peças geradas
          </Typography>

          <Button
            variant="text"
            sx={{
              fontWeight: 700,
            }}
            onClick={goToNewDocument}
          >
            Escrever peça
          </Button>
        </Box>

        <Loading isLoading={isLoadingNewCaseFiles}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1.5,
            }}
          >
            {files?.length ? (
              files.map((item, index) => (
                <CaseFile data={item} key={index} caseId={caseData?.id} threadId={caseData?.threadId} />
              ))
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <Typography variant="body1" color="text.primary">
                  Nenhum documento gerado para este caso
                </Typography>
              </Box>
            )}
          </Box>
          <TablePagination
            onPageChange={handleChangePage}
            component="div"
            count={caseFiles.length}
            page={pagination.page}
            rowsPerPage={pagination.pageSize}
            rowsPerPageOptions={[]}
            labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
          />
        </Loading>
      </Card>
    </Box>
  );
};
