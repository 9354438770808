import {
  ActivateAccountPayload,
  EmailAvailabilityPayload,
  EmailAvailabilityResponse,
  GetSignupSourcePayload,
  GetSignupSourceResponse,
  SendEmailVerificationCodePayload,
  SignupPayload,
  AcceptInvitationPayload,
} from "@/hooks/useApi/types";
import { getOfficeToken } from "@/contexts/AuthContext/hooks/useLogin/getOfficeToken";
import { authenticationCopilotServer, copilotServer } from "@/core/api";
import { GetUserDataPayload } from "@/services/lexterCopilotAuth/types";
import { outsideOfficeClient } from "@/utils/outsideOfficeClient";
import { LoggedInUserData } from "@/contexts/AuthContext";
import { isWordDesktop } from "@/utils/wordHelpers";

export const LexterCopilotAuthService = {
  activateAccount: async ({ email, code, recaptchaToken }: ActivateAccountPayload) => {
    const isDesktop = isWordDesktop();
    return copilotServer
      .post("/signup/activateAccount", { recaptchaToken, email, code, isDesktop })
      .then((res) => res.data);
  },
  getEmailAvailability: async ({ email, recaptchaToken }: EmailAvailabilityPayload) => {
    const isDesktop = isWordDesktop();
    return copilotServer
      .post<EmailAvailabilityResponse>("/signup/emailAvailability", {
        recaptchaToken,
        email,
        isDesktop,
      })
      .then((res) => res.data);
  },
  sendEmailVerificationCode: async ({ email, recaptchaToken }: SendEmailVerificationCodePayload) => {
    const isDesktop = isWordDesktop();
    return copilotServer
      .post("/signup/sendEmailVerificationCode", { recaptchaToken, email, isDesktop })
      .then((res) => res.data);
  },
  getSignupSource: async ({ email, recaptchaToken }: GetSignupSourcePayload) => {
    const isDesktop = isWordDesktop();
    return copilotServer
      .get<GetSignupSourceResponse>("/signup/source", {
        params: { recaptchaToken, email, isDesktop },
      })
      .then((res) => res.data);
  },
  signup: async (payload: SignupPayload) => {
    const isDesktop = isWordDesktop();
    return copilotServer.post("/signup", { ...payload, isDesktop }).then((res) => res.data);
  },
  acceptInvitation: async (payload: AcceptInvitationPayload) => {
    return copilotServer.post("/signup/acceptInvitation", payload).then((res) => res.data);
  },
  signupWithOffice: async () => {
    const token = await getOfficeToken();
    return copilotServer.post(`/officeSignUp`, undefined, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  },
  getUserData: async ({ loginConfig, options }: GetUserDataPayload = {}) => {
    const config = { headers: { "x-authorize-product": !outsideOfficeClient() } };
    const loginTypeQuery = loginConfig ? `?loginType=${loginConfig}` : "";
    return authenticationCopilotServer
      .post<LoggedInUserData>(
        `/lexterLogin${loginTypeQuery}`,
        {
          googleToken: options?.googleToken,
        },
        config
      )
      .then((res) => res.data);
  },
};
