import { buildSkillStep } from "@/core/skillForms/builder";

export const legalAdviceSkillStep1 = buildSkillStep({
  title: "Dados do cliente",
  description:
    "O Assistente Lexter pode te ajudar a elaborar pareceres jurídicos com base no detalhamento do caso em que você está trabalhando. Para começar, digite o nome de seu cliente.",
  fields: [
    {
      name: "client",
      type: "string",
      ui: {
        label: "Qual é o nome do cliente?",
        placeholder: "Exemplo: João da Silva. Se tiver mais de um nome, separe com vírgulas.",
      },
    },
  ],
});
