import React, { useMemo } from "react";
import { actionNameVariants, getSkillIdByActionNameVariant } from "@/hooks/skills/useActionsSearch/actionNameVariants";
import { SearchOutlined } from "@mui/icons-material";
import {
  Autocomplete,
  TextField,
  InputAdornment,
  createFilterOptions,
  MenuItem,
  FilterOptionsState,
} from "@mui/material";
import { SkillId } from "@/core/skills/types";

const filter = createFilterOptions<string>();

type SearchSkillsBarProps = {
  onSelectSkill: (props: { skillId: SkillId; customTitle?: string; defaultData?: Record<string, string> }) => void;
};

export const SearchSkillsBar = ({ onSelectSkill }: SearchSkillsBarProps) => {
  const options = useMemo(() => {
    return Object.values(actionNameVariants).flat().sort();
  }, []);

  const filteredOptions = useMemo(() => {
    return (options: string[], params: FilterOptionsState<string>) => {
      const filtered = filter(options, params);
      filtered.push("Não encontrei a peça que eu queria");
      return filtered;
    };
  }, []);

  const handleChange = (value: string | null) => {
    if (value) {
      const skillData = getSkillIdByActionNameVariant(value);
      onSelectSkill({
        skillId: "create_other_type_of_piece",
        customTitle: value,
        defaultData: {
          document_name: value,
          ...skillData,
        },
      });
    }
  };

  return (
    <Autocomplete
      sx={{
        mb: 2.5,
      }}
      options={options}
      size="medium"
      freeSolo
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlined fontSize="small" />
              </InputAdornment>
            ),
          }}
          sx={{
            "& .MuiInputBase-input": {
              pl: "0!important",
            },
          }}
          placeholder="Digite o nome da peça"
          hiddenLabel
          fullWidth
        />
      )}
      renderOption={(props, option, state) => {
        return (
          <MenuItem
            sx={{
              borderTop: option === "Não encontrei a peça que eu queria" ? "1px solid #E0E0E0" : undefined,
              my: 0.5,
            }}
            {...props}
            key={option}
            selected={state.selected}
          >
            {option}
          </MenuItem>
        );
      }}
      filterOptions={filteredOptions}
      onChange={(_, value) => {
        handleChange(value);
      }}
    />
  );
};
