import { CompanyData } from "@/contexts/AuthContext";
import { ActionId } from "@/contexts/MessagesContext";
import { PrecedentScore, ThreadMessage } from "@/contexts/WebSocketContext";
import { PlanType } from "@/hooks/credits/types";
import { CaseMessageToSend } from "../lexZap/types";
import { PaginationPayload } from "@/core/api/types";

export interface CreditsApiResponse {
  periodSpend: number;
  availableCredit: number;
  reservedCredit: number;
  nonExpirableCredit: number;
  companyPlan?: CompanyPlan;
}

export interface CheckCreditsParams {
  action: { id: ActionId };
}

export interface CheckCreditsResponse {
  hasCredits: boolean;
}

interface CompanyPlan {
  id: string;
  plan: PlanType;
  nextBillingDate: string;
  status: "ACTIVE" | "INACTIVE" | "EXPIRED";
  cancellingAt?: string;
}

export interface CreditsHistoryApiResponseItem {
  creditTransactionId: string;
  value: number;
  authorName: string;
  creationDate: string;
  type: CreditTransactionType;
  action?: CreditAction;
  status?: CreditTransactionStatus;
  description?: string;
  byReferral?: boolean;
}

export enum CreditTransactionStatus {
  COMMITTED = "COMMITTED",
  PENDING = "PENDING",
  ROLLBACK = "ROLLBACK",
}

export enum CreditTransactionType {
  EXPENSE = "EXPENSE",
  RECHARGE = "RECHARGE",
  EXPIRE = "EXPIRE",
  REFUND = "REFUND",
}

export type GetDocumentResponseType = {
  type: "EDITOR" | "PREDICTION";
  content: string;
  name: string;
};

export enum CreditAction {
  WRITE_INITIAL_PETITION = "ESCREVER_PETICAO_INICIAL",
  WRITE_CONTESTATION = "ESCREVER_CONTESTACAO",
  SUMMARY_OF_THE_PETITION = "RESUMO_DA_PETICAO",
  SEARCH_PRECEDENT = "BUSCAR_JURISPRUDENCIA",
  INTERACTION_WITH_ASSISTANT = "INTERAÇÃO_COM_ASSISTENTE",
  WRITE_APPEAL = "ESCREVER_APELACAO",
  WRITE_REPLY = "ESCREVER_REPLICA",
  WRITE_LABOR_COMPLAINT = "ESCREVER_RECLAMACAO_TRABALHISTA",
  WRITE_LABOR_CONTESTATION = "ESCREVER_CONTESTACAO_TRABALHISTA",
  WRITE_LABOR_ORDINARY_APPEAL = "ESCREVER_RECURSO_ORDINARIO_TRABALHISTA",
  WRITE_ONE_PIECE = "ESCREVER_PECA",
  CREATE_EVIDENCE_AND_REQUIRED_DOCUMENT = "SUGESTOR_DE_PROVAS_E_DOCUMENTOS",
  WRITE_CONTRACT = "ESCREVER_CONTRATO",
  CREATE_INTERVIEW_SCRIPT_ACTION = "CRIAR_ROTEIRO_DE_ENTREVISTA",
  WRITE_NOTICE_ACTION = "ESCREVER_NOTIFICACAO_EXTRAJUDICIAL",
  EDIT_DOCUMENT = "EDITAR_DOCUMENTO",
  LEGAL_QUESTION = "DUVIDA_JURIDICA",
  CREATE_STRATEGY = "CRIAR_ESTRATEGIA",
  LEGAL_ADVICE = "ELABORACAO_DE_PARECER_JURIDICO",
  INTERCURRENT_MOTION = "PETICAO_INTERCORRENTE",
  SETTLEMENT_OFFER = "PROPOSTA_DE_ACORDO",
  FEE_AGREEMENT = "CONTRATO_DE_HONORARIOS",
  HEARINGS_SCRIPT = "ROTEIRO_PARA_AUDIENCIA",
  OTHER_TYPE = "OUTRO_TIPO_DE_PEÇA",
}

export interface AcceptTermsParams {
  version: string;
}

export interface EmailAvailabilityPayload {
  email: string;
  recaptchaToken: string;
}

export interface EmailAvailabilityResponse {
  available: boolean;
  requiresActivationCode: boolean;
}

export interface SignupPayload {
  name: string;
  email: string;
  source: SignUpSource;
  externalId?: string;
  phone?: string;
  password?: string;
  signUpUtm?: Record<string, string>;
  signUpProvider?: string;
  loginConfig?: LoginConfig[];
  gclid?: string;
  referralCode?: string | null;
  recaptchaToken: string;
  firstDevice: "MOBILE" | "DESKTOP" | "OFFICE";
}

export interface AcceptInvitationPayload {
  name: string;
  password: string;
  phone: string;
  invitationToken: string;
  recaptchaToken: string;
}

export interface CompleteProfilePayload {
  token: string | null;
  userId: string;
  companyId: string;
  activityAreas?: string[];
  activitiesDaily?: string[];
  numberOfMotionsPerWeek?: string;
  office?: {
    name: string;
    numberOfEmployees: string;
    state: string;
  };
  annualRevenue?: string;
  sourceOfReferral?: string;
  numberOfMotionsPerMonth?: string;
  timeSpentProducingMotions?: string;
  usesToolForCreatingMotions?: string;
  numberOfProcessesActiveAtOffice?: string;
  currentPositionAtOffice?: string;
  phoneNumber?: string;
}

export interface AddActionOfInterestPayload {
  token?: string | null;
  userId: string;
  companyId: string;
  actionOfInterest: string;
}

export interface SendEmailVerificationCodePayload {
  email: string;
  recaptchaToken: string;
}

export interface ActivateAccountPayload {
  email: string;
  code: string;
  recaptchaToken: string;
}

export interface GetSignupSourcePayload {
  email: string;
  recaptchaToken: string;
}

export interface GetSignupSourceResponse {
  signUpSource: SignUpSource;
}

export type GetCompanyDetailsResponse = CompanyData;

export enum SignUpSource {
  /** @deprecated This source is deprecated and should not be used anymore */
  WORD_ADD_IN = "WORD_ADD_IN",
  COPILOT_WORD = "COPILOT_WORD",
  COPILOT_WEB = "COPILOT_WEB",
  COPILOT_WORD_WEB = "COPILOT_WORD_WEB",
  SIGNUP_PLATFORM = "SIGNUP_PLATFORM",
  CONSOLE = "CONSOLE",
  ADMIN = "ADMIN",
}

export enum LoginConfig {
  EMAIL_AND_PASSWORD = "EMAIL_AND_PASSWORD",
  MAGIC_LINK = "MAGIC_LINK",
  SAML = "SAML",
  OFFICE = "OFFICE",
  GOOGLE = "GOOGLE",
}

export interface CancelExecutionPayload {
  executionId: string;
}

export interface UserProfile {
  userId: string;
  name: string;
  email: string;
  avatar?: string;
  companies: ProfileCompany[];
  reviewConfig: ReviewConfig;
}

interface ProfileCompany {
  id: string;
  name: string;
  avatar?: string;
  ticketEmail?: string;
  role: "USER" | "ADMIN";
  products: string[];
  hybridDocumentTypes: string[];
  automatedDocumentTypes: string[];
  accessGroups: ProfileCompanyAccessGroup[];
}

interface ProfileCompanyAccessGroup {
  id: string;
  name: string;
  role: "USER" | "MANAGER";
}

interface ReviewConfig {
  includeDefaultText: boolean;
}

export interface SavePrecedentPayload {
  threadId: string;
  title: string;
  courtCode: string;
  cnjUniqueNumber: string;
  headnote: string;
  headnoteGen?: string;
  fullTextLocation?: string;
  judgmentDate?: string;
  publicationDate?: string;
  releaseDate?: string;
  signatureDate?: string;
  score: PrecedentScore;
  scoreReason: string;
  url: string;
}

export interface PrecedentPresignedUrlPayload {
  id: number;
}

export interface PrecedentPresignedUrlResponse {
  presignedUrl: string;
}

export interface RenamePrecedentPayload {
  id: number;
  name: string;
}

export interface DeletePrecedentPayload {
  id: number;
}

export interface GetRecordsPayload {
  threadId: string;
  pagination?: PaginationPayload;
  sortDirection?: string;
  sortBy?: string;
  search?: string;
}

export interface GetMessagesByThreadPayload {
  threadId: string;
  pagination: PaginationPayload;
}

export interface GetMessagesByThreadResponse {
  data: ThreadMessage[];
  totalResults: number;
}

export interface HandlePaymentPayload {
  email: string;
  planName: PlanType;
  companyId: string;
  phoneNumber: string;
  cpfOrCnpj: string;
  couponCode?: string | null;
}

export interface CheckCouponPayload {
  couponCode: string;
  planName: string;
  companyId?: string;
}

export interface RestorePlanPayload {
  companyId?: string;
  couponCode?: string;
}

export interface GetPaymentUserPortalPayload {
  companyId: string;
}

export interface SendReferralsPayload {
  companyId: string;
  emails: string[];
  resendReferral?: boolean;
}

export interface ListReferralsPayload {
  companyId: string;
  pagination: PaginationPayload;
}

export interface ReferralApiResponseItem {
  referrerUserId: string;
  refereeEmail: string;
  refereeStatus: string;
  createdAt: string;
  resentAt?: string;
}

export interface GetLexZapMessagesParams {
  applicantPhoneNumber: string;
}

export interface SendLexZapMessageParams {
  message: CaseMessageToSend;
  applicantPhoneNumber: string;
}

export interface UpdateLexZapCompanyParams {
  companyId: string;
  email: string;
  updates: {
    phoneNumber?: string;
    notificationNumber?: string;
    assistantName?: string;
    companyName?: string;
    apiKey?: string;
  };
}

export interface SetupLexZapCompanyParams {
  email: string;
  phoneNumber: string;
  notificationNumber?: string;
  apiKey: string;
  companyName?: string;
  assistantName?: string;
}
