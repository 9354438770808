import { authenticatedCopilotServer } from "@/core/api";
import { LexZapDlqEvent } from "./types";
import { Case, CaseMessage } from "@/hooks/lexZap/types";
import { LexZapUser } from "@/hooks/lexZap/types";

export const LexZapService = {
  getDlqEvents: async () => {
    const response = await authenticatedCopilotServer.get<LexZapDlqEvent[]>(`/dlqEvents`);
    return response.data;
  },
  reprocessDlqEvents: async ({ ids }: { ids: string[] }) => {
    await authenticatedCopilotServer.post(`/dlqEvents/reprocess`, { ids });
  },
  deleteDlqEvent: async ({ id }: { id: string }) => {
    await authenticatedCopilotServer.delete(`/dlqEvents/${id}`);
  },
  getMe: async () => {
    const response = await authenticatedCopilotServer.get<LexZapUser>(`whatsapp-assistant/users/me`);
    return response.data;
  },
  updateMe: async (data: { notificationNumber: string; department: string; activityAreas?: string[] }) => {
    await authenticatedCopilotServer.post<LexZapUser>(`whatsapp-assistant/users/me`, data);
  },
};

export const AdminLexZapService = {
  getLexZapCases: async ({ companyId }: { companyId: string }) => {
    const response = await authenticatedCopilotServer.get<Case[]>(`/admin/cases`, {
      params: {
        companyId,
      },
    });
    return response.data;
  },
  getLexZapMessages: async ({
    companyId,
    applicantPhoneNumber,
  }: {
    companyId: string;
    applicantPhoneNumber: string;
  }) => {
    const response = await authenticatedCopilotServer.get<{ messages: CaseMessage[] }>(`/admin/cases/chats/messages`, {
      params: { companyId, applicantPhoneNumber },
    });
    return response.data;
  },
};
