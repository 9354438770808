import React from "react";
import { Box } from "@mui/material";
import { PageLayout } from "@/components/PageLayout";
import LoadingRing from "@/assets/svgs/loading-ring.svg?react";

import { WhatsappAssistantCases } from "../WhatsappAssistantCases";
import { WhatsAppAssistantForm } from "../WhatsAppAssistantForm";
import { PlanType } from "@/hooks/credits/types";
import { useCredits } from "@/hooks/credits/useCredits";

export const WhatsAppAssistant = () => {
  const { data: credits, isLoading: isLoadingCredits } = useCredits();
  const planType = credits?.companyPlan?.plan;

  const hasCasesAccess = [PlanType.PROFESSIONAL_WITH_WPP, PlanType.PERFORMANCE].includes(planType!);
  const isLoading = isLoadingCredits;

  if (isLoading) {
    return <LoadingWhatsAppAssistant />;
  }

  if (hasCasesAccess) {
    return <WhatsappAssistantCases />;
  } else {
    return <WhatsAppAssistantForm />;
  }
};

const LoadingWhatsAppAssistant = () => {
  return (
    <PageLayout
      contentSx={{
        display: "flex",
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          alignSelf: "center",
        }}
      >
        <LoadingRing width="200px" />
      </Box>
    </PageLayout>
  );
};
