import React, { useEffect, useMemo } from "react";
import { HubspotFormData, useHubspot } from "@/hooks/useHubspot";
import { useForm } from "react-hook-form";
import {
  TextField,
  Button,
  Box,
  MenuItem,
  CircularProgress,
  Select,
  FormControl,
  InputLabel,
  FormHelperText,
} from "@mui/material";
import { casesPerMonthOptions, facebookOptions, numberOfEmployeesOptions } from "./defaultValues";
import toast from "react-hot-toast";
import { hubspotFormSchema } from "./types";
import { zodResolver } from "@hookform/resolvers/zod";
import { useAuthContext } from "@/contexts/AuthContext";
import { applyPhoneMask } from "@/utils/applyInputMask";
import { activityAreasDefaultValues } from "@/components/Onboarding/defaultValues";
import { applyMaskFunctionFactory } from "@/utils/applyInputMask/applyMaskFunctionFactory";

interface HubspotFormProps {
  onSubmit: () => void;
}

export const HubspotForm: React.FC<HubspotFormProps> = ({ onSubmit }) => {
  const { submitHubspotForm, loading, error, success } = useHubspot();

  const { user } = useAuthContext();

  const casesPerMonthInitialValue = useMemo(() => {
    if (user?.numberOfMotionsPerMonth && casesPerMonthOptions.includes(user?.numberOfMotionsPerMonth)) {
      return user.numberOfMotionsPerMonth;
    }
    return "";
  }, [user]);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      email: "",
      hs_whatsapp_phone_number: "",
      quantos_casos_novos_voce_recebe_por_mes_: casesPerMonthInitialValue,
      numemployees: "",
      areas_de_atuacao:
        user?.activityAreas
          ?.map((area) => activityAreasDefaultValues.find((item) => item.value === area)?.label ?? area)
          .join(", ") ?? "",
      tem_facebook: "",
      ddd_de_preferencia: "",
    },
    mode: "onChange",
    resolver: zodResolver(hubspotFormSchema),
  });

  const casesPerMonthOption = watch("quantos_casos_novos_voce_recebe_por_mes_");
  const employeesOption = watch("numemployees");
  const facebookOption = watch("tem_facebook");

  const onSubmitForm = (formData: HubspotFormData) => {
    submitHubspotForm(formData);
    onSubmit();
  };

  useEffect(() => {
    if (success) {
      toast.success("Formulário enviado com sucesso!");
    }
    if (error) {
      toast.error(error);
    }
  }, [success, error]);

  const onlyNumbersMask = (input: string) => {
    return input.replace(/\D/g, "");
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmitForm)}
      sx={{ width: "100%", display: "flex", flexDirection: "column", gap: 2 }}
    >
      <TextField
        label="Qual o seu e-mail?"
        placeholder="Digite seu e-mail"
        fullWidth
        variant="outlined"
        error={!!errors.email}
        helperText={errors.email ? errors.email.message : ""}
        {...register("email", { required: "Email é obrigatório" })}
      />

      <TextField
        label="Qual seu número de WhatsApp?"
        placeholder="Digite seu número de WhatsApp com DDD"
        fullWidth
        variant="outlined"
        error={!!errors.hs_whatsapp_phone_number}
        helperText={errors.hs_whatsapp_phone_number?.message ?? ""}
        {...register("hs_whatsapp_phone_number", {
          required: "Número de WhatsApp com DDD é obrigatório",
        })}
        InputProps={{
          onKeyUp: applyPhoneMask,
        }}
        inputProps={{
          maxLength: 15,
        }}
      />

      <TextField
        label="Qual o DDD para seu Assistente?"
        placeholder="Digite o DDD de preferência"
        fullWidth
        variant="outlined"
        error={!!errors.ddd_de_preferencia}
        helperText={errors.ddd_de_preferencia?.message ?? ""}
        {...register("ddd_de_preferencia", {
          required: "DDD obrigatório",
        })}
        InputProps={{
          onKeyUp: applyMaskFunctionFactory(onlyNumbersMask),
        }}
        inputProps={{
          maxLength: 3,
        }}
      />

      <FormControl fullWidth error={!!errors.quantos_casos_novos_voce_recebe_por_mes_}>
        <InputLabel shrink id="quantos_casos_novos_voce_recebe_por_mes_">
          Quantos casos novos você recebe por mês?
        </InputLabel>
        <Select
          labelId="quantos_casos_novos_voce_recebe_por_mes_"
          label="Quantos casos novos você recebe por mês?"
          displayEmpty
          defaultValue={casesPerMonthInitialValue}
          renderValue={
            casesPerMonthOption !== "" ? undefined : () => "Selecione quantos casos novos você recebe por mês"
          }
          {...register("quantos_casos_novos_voce_recebe_por_mes_", {
            required: "Esse campo é obrigatório",
          })}
          sx={{
            textAlign: "left",
            fontSize: casesPerMonthOption == "" ? undefined : "16px",
            color: casesPerMonthOption == "" ? "common.mediumShade" : undefined,
          }}
        >
          {casesPerMonthOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
        {errors.quantos_casos_novos_voce_recebe_por_mes_ && (
          <FormHelperText>{errors.quantos_casos_novos_voce_recebe_por_mes_.message}</FormHelperText>
        )}
      </FormControl>

      <FormControl fullWidth error={!!errors.numemployees}>
        <InputLabel shrink id="numemployees">
          Quantos advogados trabalham em seu escritório?
        </InputLabel>
        <Select
          labelId="numemployees"
          label="Quantos advogados trabalham em seu escritório?"
          displayEmpty
          renderValue={
            employeesOption !== "" ? undefined : () => "Selecione quantos advogados trabalham em seu escritório"
          }
          {...register("numemployees")}
          sx={{
            textAlign: "left",
            fontSize: employeesOption == "" ? undefined : "16px",
            color: employeesOption == "" ? "common.mediumShade" : undefined,
          }}
        >
          {numberOfEmployeesOptions.map((item, i) => (
            <MenuItem key={i} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
        {errors.numemployees && <FormHelperText>{errors.numemployees.message}</FormHelperText>}
      </FormControl>

      <TextField
        label="Áreas de atuação"
        placeholder="Digite as áreas de atuação do seu escritório"
        fullWidth
        variant="outlined"
        error={!!errors.areas_de_atuacao}
        helperText={errors.areas_de_atuacao?.message}
        {...register("areas_de_atuacao")}
      />

      <FormControl fullWidth error={!!errors.tem_facebook}>
        <InputLabel shrink id="tem_facebook">
          Tem conta no Facebook?
        </InputLabel>
        <Select
          labelId="tem_facebook"
          label="Selecione uma opção"
          displayEmpty
          renderValue={facebookOption !== "" ? undefined : () => "Selecione uma opção"}
          {...register("tem_facebook")}
          sx={{
            textAlign: "left",
            fontSize: facebookOption == "" ? undefined : "16px",
            color: facebookOption == "" ? "common.mediumShade" : undefined,
          }}
        >
          {facebookOptions.map((item, i) => (
            <MenuItem key={i} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
        {errors.tem_facebook && <FormHelperText>{errors.tem_facebook.message}</FormHelperText>}
      </FormControl>

      <Button type="submit" variant="contained" size="large" disabled={loading || !isValid} sx={{ mt: 2 }}>
        {loading ? <CircularProgress size={24} sx={{ color: "common.white" }} /> : "Enviar"}
      </Button>
    </Box>
  );
};
