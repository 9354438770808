import * as React from "react";
import { animate, PanInfo, useMotionValue, ValueAnimationTransition } from "framer-motion";
import { Slider } from "./slider";
import Pagination from "./pagination";
import { Box } from "@mui/material";

type CarouselProps = {
  children: React.ReactNode;
  autoPlay: boolean;
  interval: number;
  loop: boolean;
};

type CarouselRef = {
  handleNext: () => void;
  handlePrev: () => void;
  setIndex: (index: number) => void;
};

const containerStyle: React.CSSProperties = {
  position: "relative",
  width: "100%",
  height: "100%",
  paddingBottom: "10px",
  overflowX: "hidden",
  display: "flex",
};

const transition: ValueAnimationTransition = {
  type: "spring",
  bounce: 0,
};

const CarouselContainer = React.forwardRef<HTMLDivElement, { children: React.ReactNode }>((props, ref) => (
  <div ref={ref} style={containerStyle}>
    {props.children}
  </div>
));

CarouselContainer.displayName = "CarouselContainer";

export const Carousel = React.forwardRef(
  ({ children, autoPlay = true, interval = 2000, loop = true }: CarouselProps, ref: React.Ref<CarouselRef>) => {
    const x = useMotionValue(0);
    const containerRef = React.useRef<HTMLDivElement>(null);
    const [index, setIndex] = React.useState(0);

    const calculateNewX = () => -index * (containerRef.current?.clientWidth || 0);

    const handleEndDrag = (_e: Event, dragProps: PanInfo) => {
      const clientWidth = containerRef.current?.clientWidth || 0;

      const { offset } = dragProps;

      // fix:https://github.com/jiangbo2015/framer-motion-carousel/issues/11
      // stop start slide and end slide move
      if ((index + 1 === childrens.length && offset.x < 0) || (index === 0 && offset.x > 0)) {
        void animate(x, calculateNewX(), transition);
        return;
      }

      if (offset.x > clientWidth / 4) {
        handlePrev();
      } else if (offset.x < -clientWidth / 4) {
        handleNext();
      } else {
        void animate(x, calculateNewX(), transition);
      }
    };

    const childrens = React.Children.toArray(children);

    const handleNext = () => {
      const idx = loop ? 0 : index;
      setIndex(index + 1 === childrens.length ? idx : index + 1);
    };

    const handlePrev = () => {
      const idx = loop ? childrens.length - 1 : 0;
      setIndex(index - 1 < 0 ? idx : index - 1);
    };

    React.useEffect(() => {
      const controls = animate(x, calculateNewX(), transition);
      return controls.stop;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [index]);

    React.useEffect(() => {
      if (!autoPlay) {
        return;
      }
      const timer = setInterval(() => handleNext(), interval);
      return () => clearInterval(timer);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleNext, interval]);

    React.useImperativeHandle(ref, () => {
      return {
        handleNext,
        handlePrev,
        setIndex,
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [index]);

    return (
      <Box>
        <CarouselContainer ref={containerRef}>
          {childrens.map((child, i) => (
            <Slider onDragEnd={handleEndDrag} totalSliders={childrens.length} x={x} i={i} key={i}>
              {child}
            </Slider>
          ))}
          <Pagination dots={childrens.length} index={index} onChangeIndex={setIndex} />
        </CarouselContainer>
      </Box>
    );
  }
);

Carousel.displayName = "Carousel";
