import { Box, TextField, Typography } from "@mui/material";
import { Button } from "@/components/Button";
import { Layout } from "@/pages/Signup/Layout";
import { ReCaptchaProvider } from "@/providers/ReCaptchaProvider";
import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ROUTE_PATHS } from "@/routes/routePaths";
import LexterLogoIcon from "@/assets/svgs/lexter-logo.svg?react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { PhoneFormData, phoneSchema } from "./types";
import { applyPhoneMask } from "@/utils/applyInputMask";
import { LexterApiService } from "@/services/lexterApi";
import { logger } from "@/core/logger";
import { useAuthContext } from "@/contexts/AuthContext";
import { AuthService } from "@/services/auth";
import { PublicLayout } from "@/routes/components/PublicLayout";

export const GoogleSignUpWithPhone = () => (
  <ReCaptchaProvider>
    <PublicLayout>
      <Content />
    </PublicLayout>
  </ReCaptchaProvider>
);

const Content = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<PhoneFormData>({
    resolver: zodResolver(phoneSchema),
  });
  const { state } = useLocation();
  const { user } = useAuthContext();

  const sendPhone = async (data: PhoneFormData) => {
    if (!user) return logger.error("User not found in google sign up with phone");
    const { token } = await AuthService.getValidToken();
    if (!token) return logger.error("Token not found in google sign up with phone");

    await LexterApiService.completeProfile({
      userId: user?.userId,
      companyId: user?.companyId,
      token: token,
      phoneNumber: data.phone,
    });
  };

  const handleData = async (data: PhoneFormData) => {
    void sendPhone(data);

    if (state?.redirectTo) {
      return navigate(state?.redirectTo, {
        replace: true,
      });
    }

    return navigate(ROUTE_PATHS.ONBOARDING_QUESTIONS, {
      replace: true,
    });
  };

  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: "320px",
            display: "flex",
            flexDirection: "column",
            gap: 4,
          }}
          component="form"
          onSubmit={handleSubmit(handleData)}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
              gap: 3,
              mb: 2,
            }}
          >
            <Box
              component={LexterLogoIcon}
              height={54}
              sx={{
                color: "primary.main",
              }}
            />
          </Box>

          <Typography variant="h3" fontWeight={600} mb={1} align="center">
            Informe o seu número de telefone
          </Typography>
          <TextField
            label="Telefone"
            placeholder="Digite seu telefone com DDD"
            {...register("phone")}
            InputProps={{
              onKeyUp: applyPhoneMask,
            }}
            error={!!errors.phone}
            helperText={errors.phone?.message}
          />

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <Button type="submit" variant="contained" fullWidth>
              Finalizar cadastro
            </Button>
          </Box>
        </Box>
      </Box>
    </Layout>
  );
};
