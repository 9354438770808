import { Skill, SkillId } from "@/core/skills/types";
import { FavoriteSkill } from "@/hooks/skills/types";
import { SkillGroup, SkillGroupId } from "@/core/skillGroups/types";
import { PlanType } from "@/hooks/credits/types";
import { Flags } from "@/hooks/useFeatureFlags/types";

export type Tag = "litigation" | "consultative" | "recommend" | "new" | "mostUsed" | "comingSoon" | "dependsOnDocument";

interface ActionBase {
  id: SkillId | SkillGroupId;
  type: "SKILL" | "SKILL_GROUP";
  name: string;
  description?: string;
  credits: CreditPerSkill;
  tags: Array<Tag>;
  hidden?: boolean;
  favorite: boolean;
}

type SkillAction =
  | (ActionBase & { id: SkillId; type: "SKILL" })
  | (ActionBase & { id: SkillGroupId; type: "SKILL_GROUP" });

type ActiveActionProps = SkillAction;

type BlockedActionProps = SkillAction & {
  blocked: true;
  availabilityPlans: PlanType[];
};

export type ActionProps = ActiveActionProps | BlockedActionProps;

enum CreditPerSkill {
  ESSENTIAL_SKILL = 20,
  BASIC_SKILL = 50,
  INTERMEDIARY_SKILL = 100,
  ADVANCED_SKILL = 200,
}

export const canAccessSkill = ({ skill, userPlan }: { skill: Skill; userPlan: PlanType }) => {
  return skill.plans ? skill.plans.includes(userPlan) : true;
};

export const isSkillAvailable = ({ skill, userFlags }: { skill: Skill; userFlags: Flags }) => {
  return skill.flag ? userFlags[skill.flag] : true;
};

function skillToActionButton({
  userPlan,
  skill,
  favoriteSkills,
}: {
  userPlan: PlanType;
  skill: Skill;
  favoriteSkills: FavoriteSkill[];
}): ActionProps {
  return canAccessSkill({ skill, userPlan })
    ? ({
        id: skill.id,
        type: "SKILL",
        name: skill.name,
        description: skill.description,
        credits: skill.credits,
        tags: (skill.tags ?? []) as Tag[],
        favorite: favoriteSkills.some((favoriteSkill) => favoriteSkill.skillId === skill.id),
      } satisfies ActiveActionProps)
    : ({
        id: skill.id,
        type: "SKILL",
        name: skill.name,
        description: skill.description,
        credits: skill.credits,
        tags: (skill.tags ?? []) as Tag[],
        favorite: favoriteSkills.some((favoriteSkill) => favoriteSkill.skillId === skill.id),
        blocked: true,
        availabilityPlans: skill.plans ?? [],
      } satisfies BlockedActionProps);
}

const skillGroupToActionButton = ({
  skillGroup,
  favoriteSkills,
}: {
  skillGroup: SkillGroup;
  favoriteSkills: FavoriteSkill[];
}) => {
  return {
    id: skillGroup.id,
    type: "SKILL_GROUP",
    name: skillGroup.name,
    credits: skillGroup.credits,
    tags: (skillGroup.tags ?? []) as Tag[],
    favorite: favoriteSkills.some((favoriteSkill) => favoriteSkill.skillId === skillGroup.id),
  } satisfies ActionProps;
};

export function buildActionsButtons({
  userPlan,
  favoriteSkills,
  skills,
  skillGroups,
  userFlags,
}: {
  userPlan: PlanType;
  favoriteSkills: FavoriteSkill[];
  skills?: Skill[];
  skillGroups?: SkillGroup[];
  userFlags: Flags;
}): ActionProps[] {
  const skillButtons =
    skills
      ?.filter((skill) => isSkillAvailable({ skill, userFlags }))
      ?.map((skill) => skillToActionButton({ skill, favoriteSkills, userPlan })) || [];
  const skillGroupButtons =
    skillGroups?.map((skillGroup) => skillGroupToActionButton({ skillGroup, favoriteSkills })) || [];

  const allActionButtons = [...skillButtons, ...skillGroupButtons];

  return allActionButtons.sort((a, b) => {
    if (a.favorite && !b.favorite) return -1;
    else if (!a.favorite && b.favorite) return 1;
    else if (a.favorite && b.favorite) {
      const aCreatedAt = favoriteSkills.find((action) => action.skillId === a.id)?.createdAt ?? "";
      const bCreatedAt = favoriteSkills.find((action) => action.skillId === b.id)?.createdAt ?? "";
      return aCreatedAt > bCreatedAt ? -1 : 1;
    }
    return a.name.localeCompare(b.name);
  }) as ActionProps[];
}
