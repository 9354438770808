import { CloseOutlined as CloseOutlinedIcon } from "@mui/icons-material";
import { Box, Skeleton, Typography, alpha } from "@mui/material";
import { DaysToEndTrial, NextRecharge } from "@/components/ConfigMenu/Credits";
import { Button } from "@/components/Button";
import { PlanChip } from "@/components/PlanChip";
import { PlanChipSkeleton } from "@/components/PlanChip/PlanChipSkeleton";
import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "@/routes/routePaths";
import { useCredits } from "@/hooks/credits/useCredits";
import { DateTime } from "luxon";
import { useOpenBilling } from "@/hooks/useOpenBilling";
import { PlanType } from "@/hooks/credits/types";
import { RestorePlanBanner } from "@/pages/Home/components/RestorePlanBanner";
import { useAuthContext } from "@/contexts/AuthContext";
import { CompanyRole } from "@/contexts/AuthContext/types";

interface UsageCardProps {
  onClose: () => void;
}

export const UsageCard = ({ onClose }: UsageCardProps) => {
  const openBilling = useOpenBilling();
  const { user } = useAuthContext();
  const navigate = useNavigate();

  const { data: credits, isLoading: isLoadingCredits, error } = useCredits();

  const isFreemium = credits?.companyPlan?.plan === PlanType.FREEMIUM;
  const isTrial = credits?.companyPlan?.plan === PlanType.TRIAL;
  const isActivePlan = credits?.companyPlan?.status === "ACTIVE";
  const isCompanyAdmin = user?.companyRole === CompanyRole.ADMIN;

  const planType = credits?.companyPlan?.plan;
  const availableCredit = credits?.availableCredit;
  const nonExpirableCredit = credits?.nonExpirableCredit;

  const cancellationAtFormatted = useMemo(() => {
    if (!credits?.companyPlan?.cancellingAt) return undefined;

    return DateTime.fromISO(credits.companyPlan.cancellingAt).toFormat("dd 'de' MMMM 'de' yyyy");
  }, [credits?.companyPlan?.cancellingAt]);

  const totalCredits = useMemo(() => {
    if (!credits) return 0;
    return credits?.availableCredit + credits?.reservedCredit + credits?.periodSpend + credits?.nonExpirableCredit;
  }, [credits]);

  const handleClick = async () => {
    await openBilling();
  };

  if ((!isLoadingCredits && typeof availableCredit !== "number") || error) return null;

  if (isLoadingCredits) return null;

  return (
    <>
      <Box
        sx={{
          backgroundColor: "common.white",
          borderBottom: `1px solid`,
          borderColor: "common.lightShade",
        }}
      >
        <Box
          sx={{
            gap: 2,
            paddingX: 3,
            pt: 2,
            pb: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="bold"
              sx={{
                textTransform: "uppercase",
              }}
            >
              Gerencie seu uso
            </Typography>

            <CloseOutlinedIcon
              onClick={onClose}
              fontSize="medium"
              sx={{
                cursor: "pointer",
                color: "common.black",
              }}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              pr: 3,
              paddingTop: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: 4,
              }}
            >
              <Box
                sx={{
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: 2,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: isLoadingCredits ? "end" : "baseline",
                      gap: 2,
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        color: "common.black",
                        fontWeight: 700,
                        textTransform: "uppercase",
                      }}
                    >
                      Créditos
                    </Typography>
                  </Box>

                  <Typography variant="body3">
                    {isLoadingCredits ? (
                      <Skeleton width="80px" />
                    ) : (
                      <>
                        {(availableCredit || 0) + (nonExpirableCredit || 0)}/<strong>{totalCredits}</strong>
                      </>
                    )}
                  </Typography>
                </Box>

                <Box
                  sx={(theme) => ({
                    width: "100%",
                    backgroundColor: isLoadingCredits
                      ? undefined
                      : alpha(
                          isActivePlan
                            ? theme.palette.primary.main
                            : (theme.palette.common.shade ?? theme.palette.common.black),
                          0.3
                        ),
                    height: "8px",
                    borderRadius: "4px",
                    overflow: "hidden",
                    marginY: "10px",
                  })}
                >
                  {isLoadingCredits ? (
                    <Skeleton height="8px" variant="rectangular" />
                  ) : (
                    <Box
                      sx={{
                        backgroundColor: isActivePlan ? "primary.main" : "common.shade",
                        height: "100%",
                        width: `${((availableCredit || 0) / (totalCredits || 1)) * 100}%`,
                      }}
                    />
                  )}
                </Box>

                {isCompanyAdmin ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: 2,
                    }}
                  >
                    <Typography
                      variant="link"
                      sx={{
                        whiteSpace: "nowrap",
                      }}
                      onClick={() => navigate(ROUTE_PATHS.CREDITS_HISTORY)}
                    >
                      Visualizar extrato de uso
                    </Typography>

                    {isLoadingCredits ? (
                      <Skeleton
                        variant="text"
                        width="100px"
                        sx={{
                          display: "inline-block",
                          bgcolor: "common.lightShade",
                        }}
                      />
                    ) : (
                      <Button
                        variant="contained"
                        sx={{
                          width: "max-content",
                          borderRadius: "20px",
                          lineHeight: "22px",
                          paddingY: 0.5,
                        }}
                        onClick={handleClick}
                      >
                        {isFreemium || isTrial ? "Contratar plano" : "Gerenciar Plano"}
                      </Button>
                    )}
                  </Box>
                ) : null}
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "start",
                  flexDirection: "column",
                  gap: "8px",
                  maxWidth: "280px",
                  minWidth: "280px",
                }}
              >
                {!isLoadingCredits ? (
                  <PlanChip planType={planType!} nextBillingDate={credits?.companyPlan?.nextBillingDate} />
                ) : (
                  <PlanChipSkeleton />
                )}
                {!isLoadingCredits && (
                  <>
                    {!isTrial && !cancellationAtFormatted && <NextRecharge text="Sua próxima recarga será feita em:" />}
                    {isTrial && !cancellationAtFormatted && <DaysToEndTrial />}
                    {!!cancellationAtFormatted && (
                      <Typography variant="body3" color="error.main">
                        Sua assinatura será cancelada em{" "}
                        <strong>
                          <br />
                          {cancellationAtFormatted}
                        </strong>
                      </Typography>
                    )}
                  </>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
        {!isLoadingCredits && <RestorePlanBanner />}
      </Box>
    </>
  );
};
