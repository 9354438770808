import { PageLayout } from "@/components/PageLayout";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { ptBR } from "@mui/x-data-grid/locales";
import React, { useState } from "react";
import { Replay, DeleteForever, Close, InfoOutlined } from "@mui/icons-material";
import { DateTime } from "luxon";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { useAlertModal } from "@/hooks/useAlertModal";
import { WebToast } from "@//components/core/Toast";
import { useLexZapDlqEvents } from "@/hooks/lexZap/admin/useLexZapDlqEvents";
import { useReprocessLexZapDlqEvents } from "@/hooks/lexZap/admin/useReprocessLexZapDlqEvents";
import { useDeleteLexZapDlqEvent } from "@/hooks/lexZap/admin/useDeleteLexZapDlqEvent";
import { LexZapDlqEvent } from "@/services/lexZap/types";

export const WhatsappDlqEvents = () => {
  const { data: dlqEvents, isLoading, isFetching } = useLexZapDlqEvents();
  const { mutateAsync: reprocessEvents } = useReprocessLexZapDlqEvents();
  const { mutateAsync: deleteEvent } = useDeleteLexZapDlqEvent();
  const { openAlertModal } = useAlertModal();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [selectedEvent, setSelectedEvent] = useState<LexZapDlqEvent | null>(null);

  const handleReprocessSelected = () => {
    if (selectedIds.length === 0) {
      WebToast.error("Nenhum evento selecionado para reprocessar");
      return;
    }

    openAlertModal({
      title: "Reprocessar eventos selecionados?",
      description: `Você está prestes a reprocessar ${selectedIds.length} evento(s). Deseja continuar?`,
      primaryButton: {
        label: "Reprocessar",
        onClick: async () => {
          try {
            await reprocessEvents({ ids: selectedIds });
            WebToast.success("Eventos reprocessados com sucesso");
            setSelectedIds([]);
            setSelectedEvent(null);
          } catch {
            WebToast.error("Erro ao reprocessar os eventos selecionados");
          }
        },
      },
      secondaryButton: {
        label: "Cancelar",
        onClick: () => {},
      },
    });
  };

  const handleReprocessEvent = (event: LexZapDlqEvent) => {
    openAlertModal({
      title: "Tem certeza que deseja reprocessar este evento?",
      description: "O evento será reenviado para processamento.",
      primaryButton: {
        label: "Reprocessar",
        onClick: async () => {
          try {
            await reprocessEvents({ ids: [event.id] });
            WebToast.success("Evento reprocessado com sucesso");
            setSelectedEvent(null);
          } catch {
            WebToast.error("Erro ao reprocessar o evento");
          }
        },
      },
      secondaryButton: {
        label: "Cancelar",
        onClick: () => {},
      },
    });
  };

  const handleDeleteEvent = (event: LexZapDlqEvent) => {
    openAlertModal({
      title: "Tem certeza que deseja deletar este evento?",
      description: "O evento será permanentemente excluído.",
      primaryButton: {
        label: "Deletar",
        onClick: async () => {
          try {
            await deleteEvent({ id: event.id });
            WebToast.success("Evento deletado com sucesso");
            setSelectedEvent(null);
          } catch {
            WebToast.error("Erro ao deletar o evento");
          }
        },
      },
      secondaryButton: {
        label: "Cancelar",
        onClick: () => {},
      },
    });
  };

  const handleDeleteSelected = () => {
    if (selectedIds.length === 0) {
      WebToast.error("Nenhum evento selecionado para deletar");
      return;
    }

    openAlertModal({
      title: "Deletar eventos selecionados?",
      description: `Você está prestes a excluir permanentemente ${selectedIds.length} evento(s). Deseja continuar?`,
      primaryButton: {
        label: "Deletar",
        onClick: async () => {
          try {
            for (const id of selectedIds) {
              await deleteEvent({ id });
            }
            WebToast.success("Eventos deletados com sucesso");
            setSelectedIds([]);
          } catch {
            WebToast.error("Erro ao deletar os eventos selecionados");
          }
        },
      },
      secondaryButton: {
        label: "Cancelar",
        onClick: () => {},
      },
    });
  };

  return (
    <PageLayout
      contentProps={{
        disablePadding: true,
        fullWidth: true,
      }}
      contentSx={{
        height: "100%",
      }}
    >
      <Box
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          padding: 4,
          gap: 4,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="pageTitle">Eventos Não Processados do WhatsApp</Typography>

          <Box
            sx={{
              display: "flex",
              gap: 2,
            }}
          >
            <Button
              variant="contained"
              startIcon={<Replay />}
              onClick={handleReprocessSelected}
              disabled={!selectedIds.length}
            >
              Reprocessar Selecionados
            </Button>

            <Button
              variant="outlined"
              color="error"
              startIcon={<DeleteForever />}
              onClick={handleDeleteSelected}
              disabled={!selectedIds.length}
            >
              Deletar Selecionados
            </Button>
          </Box>
        </Box>

        <DataGrid
          getRowId={(row) => row.id}
          loading={isLoading || isFetching}
          checkboxSelection
          disableRowSelectionOnClick
          rowSelectionModel={selectedIds}
          onRowSelectionModelChange={(ids) => setSelectedIds(ids as string[])}
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          columns={[
            {
              field: "id",
              headerName: "Id",
              flex: 1,
            },
            {
              field: "sourceQueue",
              headerName: "Fila de origem",
              flex: 1,
            },
            {
              field: "body",
              headerName: "Mensagem",
              flex: 4,
              renderCell: ({ row: dlqEvent }) => (
                <Box
                  onClick={() => {
                    setSelectedEvent(dlqEvent);
                  }}
                  sx={{ cursor: "pointer" }}
                >
                  {dlqEvent.body}
                </Box>
              ),
            },
            {
              field: "timestamp",
              headerName: "Data de criação",
              flex: 1,
              renderCell: ({ row: dlqEvent }) =>
                DateTime.fromMillis(dlqEvent.timestamp).toFormat("dd/MM/yyyy HH:mm:ss.SSS"),
            },
            {
              field: "actions",
              type: "actions",
              headerName: "Ações",
              flex: 1,
              getActions: ({ row: dlqEvent }) => [
                <GridActionsCellItem
                  key={`details-${dlqEvent.id}`}
                  icon={<InfoOutlined />}
                  label="Detalhes"
                  onClick={() => setSelectedEvent(dlqEvent)}
                  showInMenu
                />,
                <GridActionsCellItem
                  key={`reprocess-${dlqEvent.id}`}
                  icon={<Replay />}
                  label="Reprocessar"
                  onClick={() => handleReprocessEvent(dlqEvent)}
                  showInMenu
                />,
                <GridActionsCellItem
                  key={`delete-${dlqEvent.id}`}
                  icon={<DeleteForever />}
                  label="Deletar"
                  onClick={() => handleDeleteEvent(dlqEvent)}
                  showInMenu
                />,
              ],
            },
          ]}
          rows={dlqEvents || []}
        />

        <Dialog open={!!selectedEvent} onClose={() => setSelectedEvent(null)} maxWidth="md" fullWidth>
          {selectedEvent && (
            <>
              <DialogTitle>
                Fila: {selectedEvent.sourceQueue}
                <br />
                {DateTime.fromMillis(selectedEvent.timestamp).toFormat("dd/MM/yyyy HH:mm:ss")}
                <br />
                {selectedEvent.id}
              </DialogTitle>
              <DialogContent>
                <pre>{JSON.stringify(JSON.parse(selectedEvent?.body || "{}"), null, 2)}</pre>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<Replay />}
                  onClick={() => handleReprocessEvent(selectedEvent)}
                >
                  Reprocessar
                </Button>

                <Button
                  variant="outlined"
                  color="error"
                  startIcon={<DeleteForever />}
                  onClick={() => handleDeleteEvent(selectedEvent)}
                >
                  Deletar
                </Button>

                <Button variant="text" startIcon={<Close />} onClick={() => setSelectedEvent(null)}>
                  Fechar
                </Button>
              </DialogActions>
            </>
          )}
        </Dialog>
      </Box>
    </PageLayout>
  );
};
