import { useMutation, useQueryClient } from "@tanstack/react-query";
import { getApplicantsQueryKey } from "../useApplicants";
import { ApplicantService } from "@/services/applicant";
import { Applicant, ApplicantPayload } from "@/services/applicant/types";

export const useEditApplicant = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["editApplicant"],
    mutationFn: async ({ applicantId, data }: { applicantId: string; data: ApplicantPayload }) =>
      await ApplicantService.updateApplicant({ applicantId, data }),
    onMutate: async ({ applicantId, data }) => {
      await queryClient.cancelQueries({ queryKey: getApplicantsQueryKey() });
      const previousCases = queryClient.getQueryData<string[]>(getApplicantsQueryKey());
      queryClient.setQueryData(getApplicantsQueryKey(), (old: Applicant[]) => [
        ...old.map((a) => (a.id === applicantId ? { ...a, ...data } : a)),
      ]);
      return { previousCases };
    },
    onError: (_error, _newCase, context) => {
      queryClient.setQueryData(getApplicantsQueryKey(), context?.previousCases);
    },
  });
};
