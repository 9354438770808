import { useAuthContext } from "@/contexts/AuthContext";
import { useApi } from "@/hooks/useApi";
import { useQuery } from "@tanstack/react-query";
import { isAxiosError } from "axios";

export const useLexZapCompany = () => {
  const api = useApi();
  const { user } = useAuthContext();

  return useQuery({
    queryKey: ["lexZapUser"],
    queryFn: async () => {
      try {
        return await api.getMyLexZapCompany();
      } catch (error) {
        if (isAxiosError(error) && error.response?.status === 404) {
          return undefined;
        }
        throw error;
      }
    },
    enabled: !!user?.userId,
  });
};
