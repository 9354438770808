import { buildSkillStep } from "@/core/skillForms/builder";

export const interviewScriptSkillStep1 = buildSkillStep({
  description:
    "O Assistente Lexter pode te ajudar a elaborar questões para a entrevista com o seu cliente, baseado no contexto prévio que você já possui sobre o caso.",
  fields: [
    {
      name: "client",
      type: "string",
      ui: {
        label: "Qual o nome do cliente?",
        placeholder: "Exemplo: João da Silva. Se tiver mais de um nome, separe com vírgulas.",
      },
    },
    {
      name: "case_breakdown",
      type: "string",
      ui: {
        label: "Insira as informações que já possui do caso:",
        widget: "textarea",
        placeholder:
          "Exemplo: Transcreva a conversa inicial que teve com o cliente, cite documentos e outras provas mencionadas na conversa inicial, insira uma conversa de WhatsApp, etc.",
      },
    },
  ],
  submitButtonText: "Criar roteiro",
});
