import { useAuthContext } from "@/contexts/AuthContext";
import { CompanyService } from "@/services/company";
import { useQuery } from "@tanstack/react-query";

export const getCompanyTeamQueryKey = () => ["companyTeam"];

export const useCompanyTeam = () => {
  const { user } = useAuthContext();

  return useQuery({
    queryKey: getCompanyTeamQueryKey(),
    queryFn: async () => {
      return await CompanyService.getTeam();
    },
    enabled: !!user,
    staleTime: Infinity,
  });
};
