import { LoggedInUserData } from "@/contexts/AuthContext";

export function getTermsOfUseConfigs(): { currentVersion: string } {
  return {
    currentVersion: "20240327",
  };
}

export const userAcceptedTermsOfUse = (loginUserData: LoggedInUserData): boolean => {
  const currentVersion = getTermsOfUseConfigs().currentVersion;
  const acceptedTermsOfUse = loginUserData.userAcceptedTermsOfUse;
  return Boolean(acceptedTermsOfUse.find((v) => v.version === currentVersion));
};
