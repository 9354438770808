import { Box } from "@mui/material";
import React, { useMemo, useState } from "react";
import { Search } from "./components/Search";
import { ChatItem } from "./components/ChatItem";
import { EmptyList } from "./components/EmptyList";
import { ChatItemSkeleton } from "./components/ChatItem/skeleton";
import { CaseWithChat } from "@/hooks/lexZap/types";

interface CaseListProps {
  cases?: CaseWithChat[];
  activeChatNumber?: string;
  isLoading: boolean;
  onItemSelect: (applicantPhoneNumber?: string) => void;
}

export const CaseList = ({ cases, activeChatNumber, isLoading, onItemSelect }: CaseListProps) => {
  const [searchTerm, setSearchTerm] = useState("");

  const filteredCases = useMemo(
    () => cases?.filter((item) => item.applicantName.toLowerCase().includes(searchTerm.toLowerCase())),
    [cases, searchTerm]
  );

  return (
    <Box
      sx={{
        backgroundColor: "orange.light.10",
        paddingTop: 2.5,
        display: "flex",
        flexDirection: "column",
        gap: 3,
      }}
    >
      <Box
        sx={{
          paddingX: 2,
        }}
      >
        <Search onChange={setSearchTerm} />
      </Box>

      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            paddingX: 2,
            paddingBottom: 2,
            gap: 1,
            overflow: "auto",
          }}
        >
          {Array.from({ length: 10 }, (_, index) => (
            <ChatItemSkeleton key={index} isActive={index === 0} />
          ))}
        </Box>
      ) : (
        <>
          {!filteredCases?.length ? (
            <EmptyList />
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                paddingX: 2,
                paddingBottom: 2,
                gap: 1,
                overflow: "auto",
              }}
            >
              {filteredCases.map((item) => (
                <ChatItem
                  key={item.id}
                  onClick={() => onItemSelect(item.applicantPhoneNumber)}
                  caseData={item}
                  isActive={item.applicantPhoneNumber === activeChatNumber}
                />
              ))}
            </Box>
          )}
        </>
      )}
    </Box>
  );
};
