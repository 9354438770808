import { OrderThreadsBy, OrderThreadsOptions } from "@/hooks/threads/types";
import { ThreadService } from "@/services/thread";
import { useInfiniteQuery } from "@tanstack/react-query";

export const THREADS_QUERY_KEY = ["threads"] as const;
const DEFAULT_THREADS_PAGE_SIZE = 10;

export const orderThreadsOptions: OrderThreadsOptions = {
  UPDATED_AT: "Data de Atualização",
  ALPHABET_ASC: "Alfabético - AZ",
  ALPHABET_DESC: "Alfabético - ZA",
  FAVORITES: "Favoritos",
};

const getOrderOptionsParams = (order: OrderThreadsBy) => {
  switch (order) {
    case "UPDATED_AT":
      return { sortBy: "lastEventDate", sortDirection: "desc" };
    case "ALPHABET_ASC":
      return { sortBy: "name", sortDirection: "asc" };
    case "ALPHABET_DESC":
      return { sortBy: "name", sortDirection: "desc" };
    case "FAVORITES":
      return { sortBy: "favorite", sortDirection: "desc" };
    default:
      return { sortBy: "lastEventDate", sortDirection: "desc" };
  }
};

export const usePaginatedThreads = ({
  order,
  search,
  pageSize = DEFAULT_THREADS_PAGE_SIZE,
}: {
  order: OrderThreadsBy;
  search?: string;
  pageSize?: number;
}) => {
  const params = getOrderOptionsParams(order);

  return useInfiniteQuery({
    queryKey: [...THREADS_QUERY_KEY, { order, search, pageSize }],
    queryFn: async ({ pageParam }) => {
      return await ThreadService.getThreads({
        search,
        pagination: {
          page: pageParam,
          pageSize: pageSize,
        },
        ...params,
      });
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (allPages.flatMap((page) => page.data).length >= lastPage.totalResults) {
        return undefined;
      }
      return lastPageParam + 1;
    },
  });
};
