import React from "react";

import { MessageProps } from "../../types";
import { MessageCard } from "../MessageCard";
import { alpha, Box } from "@mui/material";
import { red } from "@mui/material/colors";
import { LocationOn as LocationOnIcon } from "@mui/icons-material";

export const LocationMessage = ({ message }: MessageProps) => {
  const isStatusAboveImage = !message.body;

  return (
    <MessageCard
      message={message}
      sx={{
        padding: 0.5,
      }}
      statusSx={
        isStatusAboveImage
          ? {
              color: "common.white",
              position: "absolute",
              bottom: "8px",
              right: "14px",
            }
          : undefined
      }
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          maxWidth: "240px",
        }}
      >
        <Box
          component="a"
          href={`https://maps.google.com/maps?q=${message.location?.latitude},${message.location?.longitude}&z=17&hl=pt-BR`}
          target="_blank"
          sx={{
            display: "flex",
            position: "relative",
            borderRadius: "12px",
            overflow: "hidden",
            cursor: "pointer",
          }}
        >
          <Box
            sx={{
              width: "270px",
              height: "150px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LocationOnIcon
              sx={{
                color: red[400],
                height: "90px",
                width: "90px",
              }}
            />
          </Box>

          {isStatusAboveImage && (
            <Box
              sx={{
                position: "absolute",
                bottom: 0,
                right: 0,
                left: 0,
                height: "32px",
                background: ({ palette }) =>
                  `linear-gradient(to top, ${alpha(palette.common.black, 0.5)}, transparent)`,
              }}
            />
          )}
        </Box>
      </Box>
    </MessageCard>
  );
};
