import { Box } from "@mui/material";
import React, { useMemo } from "react";
import { Case } from "@/hooks/lexZap/types";
import { CardItem } from "./components/CardItem";
import { EmptyCases } from "./components/EmptyCases";
import { CardItemSkeleton } from "./components/skeleton";
import { EditCaseModal } from "@/components/Cases";
import { DeleteCasesModal } from "./components/DeleteCasesModal";
import { useDeleteCases } from "@/hooks/lexZap/useDeleteCases";
import { ArchiveCasesModal } from "./components/ArchiveCasesModal";
import { UnarchiveCasesModal } from "./components/UnarchiveCasesModal";
import { useArchiveCases } from "@/hooks/lexZap/useArchiveCases";
import { useUnarchiveCases } from "@/hooks/lexZap/useUnarchiveCases";
import { DateTime } from "luxon";
import { formatDateToISO } from "@/utils/formatDateToISO";

export const CardsView = ({
  cases,
  isLoadingCases,
  selectedCases,
  onSelect,
  status,
}: {
  cases: Case[] | undefined;
  isLoadingCases: boolean;
  selectedCases: string[];
  onSelect: (caseId: string) => void;
  status: "ARCHIVED" | "ACTIVE";
}) => {
  const { mutateAsync: archiveCases, isPending: isArchivingCases } = useArchiveCases();
  const { mutateAsync: deleteCases, isPending: isDeletingCases } = useDeleteCases();
  const { mutateAsync: unarchiveCases, isPending: isUnarchivingCases } = useUnarchiveCases();

  const [currentEditingCase, setCurrentEditingCase] = React.useState<string | null>(null);
  const [isEditModalOpen, setIsEditModalOpen] = React.useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
  const [isArchiveModalOpen, setIsArchiveModalOpen] = React.useState(false);
  const [isUnarchiveModalOpen, setIsUnarchiveModalOpen] = React.useState(false);

  const cardCases = useMemo(() => {
    return cases?.filter((c) => c.status === status);
  }, [cases, status]);

  const handleOpenEditCase = () => {
    if (!currentEditingCase) {
      return;
    }
    setIsEditModalOpen(true);
  };

  const handleCloseEditCase = () => {
    setIsEditModalOpen(false);
    setCurrentEditingCase(null);
  };

  const handleOpenDeleteCase = () => {
    if (!currentEditingCase) {
      return;
    }
    setIsDeleteModalOpen(true);
  };

  const handleCloseDeleteCase = () => {
    setIsDeleteModalOpen(false);
    setCurrentEditingCase(null);
  };

  const handleDelete = async () => {
    if (!currentEditingCase) {
      return;
    }
    await deleteCases({ casesIds: [currentEditingCase] });
    handleCloseDeleteCase();
  };

  const handleOpenArchiveCase = () => {
    if (!currentEditingCase) {
      return;
    }
    setIsArchiveModalOpen(true);
  };

  const handleCloseArchiveCase = () => {
    setIsArchiveModalOpen(false);
    setCurrentEditingCase(null);
  };

  const handleArchive = async () => {
    if (!currentEditingCase) {
      return;
    }
    await archiveCases({ casesIds: [currentEditingCase] });
    handleCloseArchiveCase();
  };

  const handleOpenUnarchiveCase = () => {
    if (!currentEditingCase) {
      return;
    }
    setIsUnarchiveModalOpen(true);
  };

  const handleCloseUnarchiveCase = () => {
    setIsUnarchiveModalOpen(false);
    setCurrentEditingCase(null);
  };

  const handleUnarchive = async () => {
    if (!currentEditingCase) {
      return;
    }
    await unarchiveCases({ casesIds: [currentEditingCase] });
    handleCloseUnarchiveCase();
  };

  return (
    <>
      {!cardCases?.length && !isLoadingCases ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <EmptyCases archived={status === "ARCHIVED"} />
        </Box>
      ) : (
        <Box
          sx={{
            width: "100%",
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(368px, max-content))",
            gap: 3,
            paddingX: 4,
            alignContent: "center",
            justifyContent: "left",
            paddingY: 2,
          }}
        >
          {isLoadingCases
            ? Array.from({ length: 6 }).map((_, index) => <CardItemSkeleton key={index} />)
            : cardCases
                ?.sort((a, b) => {
                  return (
                    DateTime.fromISO(formatDateToISO(b.lastModifiedAt ?? b.createdAt)).toMillis() -
                    DateTime.fromISO(formatDateToISO(a.lastModifiedAt ?? a.createdAt)).toMillis()
                  );
                })
                .map((item) => (
                  <CardItem
                    selectedCases={selectedCases}
                    onSelect={onSelect}
                    key={item.id}
                    id={item.id}
                    name={item.name}
                    applicantName={item.applicantName}
                    step={item.step}
                    summary={item.facts}
                    timeline={item.timeline}
                    archived={status === "ARCHIVED"}
                    setCurrentEditingCase={setCurrentEditingCase}
                    handleOpenEditCase={handleOpenEditCase}
                    handleOpenDeleteCase={handleOpenDeleteCase}
                    handleOpenArchiveCase={handleOpenArchiveCase}
                    handleOpenUnarchiveCase={handleOpenUnarchiveCase}
                  />
                ))}
        </Box>
      )}
      <EditCaseModal isOpen={isEditModalOpen} caseId={currentEditingCase!} onClose={handleCloseEditCase} />

      <DeleteCasesModal
        isOpen={isDeleteModalOpen}
        onConfirm={handleDelete}
        onCancel={handleCloseDeleteCase}
        isLoading={isDeletingCases}
        amount={1}
      />

      <ArchiveCasesModal
        isOpen={isArchiveModalOpen}
        onConfirm={handleArchive}
        onCancel={handleCloseArchiveCase}
        isLoading={isArchivingCases}
        amount={1}
      />

      <UnarchiveCasesModal
        isOpen={isUnarchiveModalOpen}
        onConfirm={handleUnarchive}
        onCancel={handleCloseUnarchiveCase}
        isLoading={isUnarchivingCases}
        amount={1}
      />
    </>
  );
};
