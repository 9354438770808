import { useApi } from "@/hooks/useApi";
import React from "react";
import { NotificationsContext } from "./context";
import { NotificationsList } from "./types";
import { useSocket } from "../WebSocketContext";
import { DateTime } from "luxon";
import { logger } from "@/core/logger";
import { useThreadContext } from "@/contexts/ThreadContext";

interface NotificationsContextProviderProps {
  children: React.ReactNode;
}
export const NotificationsContextProvider = ({ children }: NotificationsContextProviderProps) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [notificationsList, setNotificationsList] = React.useState<NotificationsList>([]);
  const { getNotifications, setNotificationOpen, getNotificationById } = useApi();
  const [openThreadId, setOpenThreadId] = React.useState<string | undefined>(undefined);

  const { currentThreadId } = useThreadContext();

  useSocket({
    onNotificationReceived: async ({ id }) => {
      try {
        if (!id) {
          return;
        }
        await handleGetNotification(id);
      } catch (error) {
        logger.error("handleGetNotification", error);
      }
    },
  });

  React.useEffect(() => {
    setOpenThreadId(currentThreadId);
  }, [currentThreadId]);

  const handleGetNotification = async (id: number) => {
    try {
      const notification = await getNotificationById(id);
      const isThreadOpen = openThreadId === notification.threadId;
      if (isThreadOpen) {
        setNotificationOpen(id);
        setNotificationsList((prev) => [{ ...notification, visualizedAt: DateTime.now().toString() }, ...prev]);
        return;
      }
      setNotificationsList((prev) => [notification, ...prev]);
    } catch (error) {
      logger.error("handleGetNotification", error);
    }
  };

  const loadNotificationsList = React.useCallback(async () => {
    setIsLoading(true);
    try {
      const notifications = await getNotifications();
      setNotificationsList(notifications);
      setIsLoading(false);
    } catch (error) {
      logger.error("loadNotificationsList", error);
      setIsLoading(false);
    }
  }, []);

  React.useEffect(() => {
    loadNotificationsList();
  }, []);

  const openNotification = React.useCallback((id: number) => {
    setNotificationOpen(id);
    setNotificationsList((prev) =>
      prev.map((notification) =>
        notification.id === id ? { ...notification, visualizedAt: DateTime.now().toString() } : notification
      )
    );
  }, []);

  return (
    <NotificationsContext.Provider
      value={{
        notifications: notificationsList,
        isLoading,
        openNotification,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};
