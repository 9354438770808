import { Box, Chip, Tooltip, Typography } from "@mui/material";
import React from "react";

import { ExpandableCard } from "@/components/ExpandableCard";
import { Card } from "../Card";
import { dateTimeFromString } from "@/utils/dates/dateTimeFromString";
import { Case } from "@/hooks/lexZap/types";

type CaseSummaryProps = {
  caseData?: Case;
};

export const CaseSummary = ({ caseData }: CaseSummaryProps) => {
  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "min-content",
        gap: 2,
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "min-content minmax(0, 1fr)",
            gridAutoFlow: "column",
            justifyContent: "start",
            alignItems: "center",
            gap: 1,
            width: "100%",
          }}
        >
          <Typography
            variant="subtitle2"
            fontWeight={700}
            fontSize="22px"
            color="text.primary"
            sx={{
              display: "inline-block",
              whiteSpace: "nowrap",
              overflow: "visible",
            }}
          >
            Resumo jurídico
          </Typography>
          <Box>
            {caseData?.legalArea && (
              <Tooltip placement="top" title={caseData?.legalArea}>
                <Chip
                  sx={{ borderColor: "error.main", borderRadius: "8px", textOverflow: "ellipsis" }}
                  label={caseData?.legalArea}
                  variant="outlined"
                />
              </Tooltip>
            )}
          </Box>
        </Box>
        {caseData && (
          <Typography variant="multiLineBody" fontWeight={700} fontSize="10px" color="text.primary">
            Última atualização em{" "}
            {dateTimeFromString(caseData.lastModifiedAt || caseData.createdAt).toFormat("dd/MM/yyyy 'às' HH:mm")}
          </Typography>
        )}
      </Box>

      <Typography variant="body1" color="text.primary">
        {caseData?.facts?.split("\n").map((paragraph, index) => (
          <React.Fragment key={index}>
            {paragraph}
            <br />
          </React.Fragment>
        ))}
      </Typography>

      {caseData?.documentationAndEvidence && (
        <SummaryItemCard title="Documentações e provas" content={caseData.documentationAndEvidence} />
      )}

      {caseData?.legalQuestions && <SummaryItemCard title="Questões jurídicas" content={caseData.legalQuestions} />}

      {/* {caseData?.recommendations && <SummaryItemCard title="Recomendações" content={caseData.recommendations} />} */}

      {caseData?.conclusion && <SummaryItemCard title="Conclusão Lexter" content={caseData.conclusion} />}
    </Card>
  );
};

const SummaryItemCard = ({ title, content }: { title: string; content: string }) => (
  <ExpandableCard
    header={
      <Typography variant="subtitle1" fontWeight={600} color="text.primary">
        {title}
      </Typography>
    }
  >
    <Typography variant="multiLineBody" fontSize="16px" color="text.primary">
      {content.split("\n").map((paragraph, index) => (
        <React.Fragment key={index}>
          {paragraph}
          <br />
        </React.Fragment>
      ))}
    </Typography>
  </ExpandableCard>
);
